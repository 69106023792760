/* eslint-disable array-callback-return */
import "moment/locale/fr";

import React, { Component } from "react";

import API from "../utils/API";
import Layout from "../components/Layout";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import moment from "moment";

moment.locale("fr");

const from = moment("2019-07-15");
const to = moment().add(1, "day");

const colors = {
  app1: "#d36259",
  app1a: "#d36259",
  app2: "#ef7e14",
  app2a: "#ef7e14",
  app3: "#ffc105",
  app3a: "#ffc105",
  app4: "#bfd047",
  app4a: "#bfd047",
  app5: "#0e9d58",
  app5a: "#0e9d58",
  diag1: "#d36259",
  diag1a: "#d36259",
  diag2: "#ef7e14",
  diag2a: "#ef7e14",
  diag3: "#ffc105",
  diag3a: "#ffc105",
  diag4: "#bfd047",
  diag4a: "#bfd047",
  diag5: "#0e9d58",
  diag5a: "#0e9d58",
};

class Stats extends Component {
  state = {
    welcomeAllChartData: [],
    welcomeLicensedChartData: [],
    diagnosisDiagRateSurveyChartData: [],
    diagnosisAppRateSurveyChartData: [],
    diagnosisDiagRateSurveyAbsolute: true,
    diagnosisAppRateSurveyAbsolute: true,
    diagnosisScore: {
      silent: true,
      lower: true,
      same: true,
      louder: false,
      doneTest: true,
    },
    diagnosisRate: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: true,
    },
    diagnosisLicenses: {
      present: true,
      absent: true,
    },
  };

  constructor() {
    super();
    this.api = new API();
  }

  componentDidMount() {
    this.refreshStats();
  }

  refreshDiagnosisRates = async () => {
    const diagnosisDiagRateSurveyChartData = [];
    const diagnosisAppRateSurveyChartData = [];

    const scoreValues = Object.keys(this.state.diagnosisScore)
      .map((score) => {
        return this.state.diagnosisScore[score] ? score : null;
      })
      .filter((v) => v !== null);

    const diagRateValues = Object.keys(this.state.diagnosisRate)
      .map((rate) => {
        return this.state.diagnosisRate[rate] ? rate : null;
      })
      .filter((v) => v !== null);

    const criteria = {
      "data.context.residualInhibitionDiagnosis.rate": { $in: scoreValues },
      "data.rate.diag": { $in: diagRateValues },
    };

    if (this.state.diagnosisScore.doneTest) {
      criteria["data.context.residualInhibitionDiagnosis.doneTest"] = true;
    }

    if (
      this.state.diagnosisLicenses.present !==
      this.state.diagnosisLicenses.absent
    ) {
      if (this.state.diagnosisLicenses.present) {
        criteria["data.context.license"] = { $exists: true, $ne: null };
      } else {
        criteria["data.context.license"] = { $exists: true, $eq: null };
      }
    }

    const diagnosisRateSurvey = await this.api.getStatsDiagnosisSurveyRates(
      from.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      "all",
      false,
      criteria
    );
    diagnosisRateSurvey.map((week) => {
      const diagTotal = Math.max(1, week.diagTotal);
      diagnosisDiagRateSurveyChartData.push({
        _id: week._id,
        diag1: Math.round((week.diag1 * 100) / diagTotal),
        diag2: Math.round((week.diag2 * 100) / diagTotal),
        diag3: Math.round((week.diag3 * 100) / diagTotal),
        diag4: Math.round((week.diag4 * 100) / diagTotal),
        diag5: Math.round((week.diag5 * 100) / diagTotal),
        diag1a: week.diag1,
        diag2a: week.diag2,
        diag3a: week.diag3,
        diag4a: week.diag4,
        diag5a: week.diag5,
      });
      const appTotal = Math.max(1, week.appTotal);
      diagnosisAppRateSurveyChartData.push({
        _id: week._id,
        app1: Math.round((week.app1 * 100) / appTotal),
        app2: Math.round((week.app2 * 100) / appTotal),
        app3: Math.round((week.app3 * 100) / appTotal),
        app5: Math.round((week.app5 * 100) / appTotal),
        app4: Math.round((week.app4 * 100) / appTotal),
        app1a: week.app1,
        app2a: week.app2,
        app3a: week.app3,
        app4a: week.app4,
        app5a: week.app5,
      });
    });
    this.setState({
      diagnosisDiagRateSurveyChartData,
      diagnosisAppRateSurveyChartData,
    });
  };

  refreshStats = async () => {
    // SURVEY DIAG RATE
    this.refreshDiagnosisRates();

    // WELCOME ALL
    const welcomeAllChartData = [
      { id: "volume", data: [] },
      { id: "stress", data: [] },
      { id: "focus", data: [] },
      { id: "sleep", data: [] },
    ];
    const welcomeAll = await this.api.getStatsWelcomeEvents(
      from.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      "week",
      false,
      {
        type: "ACTIVITY_COMPLETE",
        "data.activity": { $in: ["welcome", "WELCOME"] },
        "data.goal": { $exists: true },
      }
    );
    welcomeAll.map((week) => {
      const weekTotal = Math.max(1, week.total);
      welcomeAllChartData[0].data.push({
        x: week._id,
        y: Math.round((week.volume * 100) / weekTotal),
      });
      welcomeAllChartData[1].data.push({
        x: week._id,
        y: Math.round((week.focus * 100) / weekTotal),
      });
      welcomeAllChartData[2].data.push({
        x: week._id,
        y: Math.round((week.stress * 100) / weekTotal),
      });
      welcomeAllChartData[3].data.push({
        x: week._id,
        y: Math.round((week.sleep * 100) / weekTotal),
      });
    });
    this.setState({ welcomeAllChartData });

    // WELCOME LICENSED
    const welcomeLicensedChartData = [
      { id: "volume", data: [] },
      { id: "stress", data: [] },
      { id: "focus", data: [] },
      { id: "sleep", data: [] },
    ];
    const welcomeLicensed = await this.api.getStatsWelcomeEvents(
      from.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      "week",
      true,
      {
        type: "ACTIVITY_COMPLETE",
        "data.activity": { $in: ["welcome", "WELCOME"] },
        "data.goal": { $exists: true },
      }
    );
    welcomeLicensed.map((week) => {
      const weekTotal = Math.max(1, week.total);
      welcomeLicensedChartData[0].data.push({
        x: week._id,
        y: Math.round((week.volume * 100) / weekTotal),
      });
      welcomeLicensedChartData[1].data.push({
        x: week._id,
        y: Math.round((week.focus * 100) / weekTotal),
      });
      welcomeLicensedChartData[2].data.push({
        x: week._id,
        y: Math.round((week.stress * 100) / weekTotal),
      });
      welcomeLicensedChartData[3].data.push({
        x: week._id,
        y: Math.round((week.sleep * 100) / weekTotal),
      });
    });
    this.setState({ welcomeLicensedChartData });
  };

  renderWelcomeAllSurvey = () => {
    return (
      <div className="card mg-b-20 pd-t-20 pd-l-20 pd-r-20">
        <h6 className="card-body-title">
          OBJECTIF PRINCIPAL DES INSCRITS EN DÉCOUVRANT DIAPASON PAR SEMAINE (%)
        </h6>
        <div className="table-wrapper bg-gray" style={{ height: "200px" }}>
          <ResponsiveLine
            data={this.state.welcomeAllChartData}
            indexBy="_id"
            margin={{ top: 10, right: 30, bottom: 60, left: 30 }}
            padding={0.3}
            colors={{ scheme: "category10" }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            axisRight={{
              enable: true,
            }}
            yScale={{
              type: "linear",
              min: "auto",
            }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 55,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 8,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
            useMesh={true}
            enableGridY={false}
          />
        </div>
      </div>
    );
  };

  renderWelcomeLicensedSurvey = () => {
    return (
      <div className="card mg-b-20 pd-t-20 pd-l-20 pd-r-20">
        <h6 className="card-body-title">
          OBJECTIF PRINCIPAL DES CLIENTS EN DÉCOUVRANT DIAPASON PAR SEMAINE (%)
        </h6>
        <div className="table-wrapper bg-gray" style={{ height: "200px" }}>
          <ResponsiveLine
            data={this.state.welcomeLicensedChartData}
            indexBy="_id"
            margin={{ top: 10, right: 30, bottom: 60, left: 30 }}
            padding={0.3}
            colors={{ scheme: "category10" }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            axisRight={{
              enable: true,
            }}
            yScale={{
              type: "linear",
              min: "auto",
            }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 55,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 8,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
            useMesh={true}
            enableGridY={false}
          />
        </div>
      </div>
    );
  };

  renderDiagnosisDiagRateSurvey = () => {
    return (
      <div className="card mg-b-20 pd-t-20 pd-l-20 pd-r-20">
        <h6 className="card-body-title">
          NOTES SUR LE TEST IR{" "}
          <button
            onClick={() =>
              this.setState({
                diagnosisDiagRateSurveyAbsolute: !this.state
                  .diagnosisDiagRateSurveyAbsolute,
              })
            }
          >
            {this.state.diagnosisDiagRateSurveyAbsolute
              ? "en unité"
              : "en pourcentage"}
          </button>
        </h6>
        <div className="table-wrapper bg-gray" style={{ height: "200px" }}>
          <ResponsiveBar
            data={this.state.diagnosisDiagRateSurveyChartData}
            keys={
              this.state.diagnosisDiagRateSurveyAbsolute
                ? ["diag1a", "diag2a", "diag3a", "diag4a", "diag5a"]
                : ["diag1", "diag2", "diag3", "diag4", "diag5"]
            }
            indexBy="_id"
            margin={{ top: 10, right: 30, bottom: 60, left: 30 }}
            padding={0.3}
            colors={(line) => colors[line.id]}
            labelSkipWidth={12}
            labelSkipHeight={12}
            axisRight={{
              enable: true,
            }}
            yScale={{
              type: "linear",
              min: "auto",
            }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 55,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 8,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
            useMesh={true}
            enableGridY={false}
          />
        </div>
      </div>
    );
  };

  renderDiagnosisAppRateSurvey = () => {
    return (
      <div className="card mg-b-20 pd-t-20 pd-l-20 pd-r-20">
        <h6 className="card-body-title">
          NOTES SUR DIAPASON APRÈS LE TEST IR{" "}
          <button
            onClick={() =>
              this.setState({
                diagnosisAppRateSurveyAbsolute: !this.state
                  .diagnosisAppRateSurveyAbsolute,
              })
            }
          >
            {this.state.diagnosisAppRateSurveyAbsolute
              ? "en unité"
              : "en pourcentage"}
          </button>
        </h6>
        <div className="table-wrapper bg-gray" style={{ height: "200px" }}>
          <ResponsiveBar
            data={this.state.diagnosisAppRateSurveyChartData}
            keys={
              this.state.diagnosisAppRateSurveyAbsolute
                ? ["app1a", "app2a", "app3a", "app4a", "app5a"]
                : ["app1", "app2", "app3", "app4", "app5"]
            }
            indexBy="_id"
            margin={{ top: 10, right: 30, bottom: 60, left: 30 }}
            padding={0.3}
            colors={(line) => colors[line.id]}
            labelSkipWidth={12}
            labelSkipHeight={12}
            axisRight={{
              enable: true,
            }}
            yScale={{
              type: "linear",
              min: "auto",
            }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 55,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 8,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
            useMesh={true}
            enableGridY={false}
          />
        </div>
      </div>
    );
  };

  onChangeDiagnosisScore = (value) => {
    this.setState(
      {
        diagnosisScore: {
          ...this.state.diagnosisScore,
          [value]: !this.state.diagnosisScore[value],
        },
      },
      this.refreshDiagnosisRates
    );
  };

  onChangeDiagnosisRate = (value) => {
    this.setState(
      {
        diagnosisRate: {
          ...this.state.diagnosisRate,
          [value]: !this.state.diagnosisRate[value],
        },
      },
      this.refreshDiagnosisRates
    );
  };

  onChangeDiagnosisLicense = (value) => {
    this.setState(
      {
        diagnosisLicenses: {
          ...this.state.diagnosisLicenses,
          [value]: !this.state.diagnosisLicenses[value],
        },
      },
      this.refreshDiagnosisRates
    );
  };

  renderDiagnosisParameters = () => {
    return (
      <div className="card mg-b-20 pd-t-20 pd-l-20 pd-r-20">
        <h6 className="card-body-title">
          FILTRES POUR LES NOTES DU TEST IR ET DE DIAPASON
        </h6>
        <div className="row">
          <div className="col-md-3 mg-b-10">
            <label className="mg-b-5">Score du test IR :</label>
            <label className="ckbox">
              <input
                type="checkbox"
                checked={this.state.diagnosisScore.silent}
                onChange={() => this.onChangeDiagnosisScore("silent")}
              />
              <span>Silence</span>
            </label>
            <label className="ckbox">
              <input
                type="checkbox"
                checked={this.state.diagnosisScore.lower}
                onChange={() => this.onChangeDiagnosisScore("lower")}
              />
              <span>Moins fort</span>
            </label>
            <label className="ckbox">
              <input
                type="checkbox"
                checked={this.state.diagnosisScore.same}
                onChange={() => this.onChangeDiagnosisScore("same")}
              />
              <span>Pareil</span>
            </label>
            <label className="ckbox">
              <input
                type="checkbox"
                checked={this.state.diagnosisScore.louder}
                onChange={() => this.onChangeDiagnosisScore("louder")}
              />
              <span>Plus fort</span>
            </label>
            <label className="ckbox">
              <input
                type="checkbox"
                checked={this.state.diagnosisScore.doneTest}
                onChange={() => this.onChangeDiagnosisScore("doneTest")}
              />
              <span>A fait les 60" de test</span>
            </label>
          </div>
          <div className="col-md-3 mg-b-10">
            <label className="mg-b-5">Note du test IR :</label>
            <label className="ckbox">
              <input
                type="checkbox"
                checked={this.state.diagnosisRate["5"]}
                onChange={() => this.onChangeDiagnosisRate("5")}
              />
              <span>5 - Excellent</span>
            </label>
            <label className="ckbox">
              <input
                type="checkbox"
                checked={this.state.diagnosisRate["4"]}
                onChange={() => this.onChangeDiagnosisRate("4")}
              />
              <span>4 - Très bon</span>
            </label>
            <label className="ckbox">
              <input
                type="checkbox"
                checked={this.state.diagnosisRate["3"]}
                onChange={() => this.onChangeDiagnosisRate("3")}
              />
              <span>3 - Bon</span>
            </label>
            <label className="ckbox">
              <input
                type="checkbox"
                checked={this.state.diagnosisRate["2"]}
                onChange={() => this.onChangeDiagnosisRate("2")}
              />
              <span>2 - Mauvais</span>
            </label>
            <label className="ckbox">
              <input
                type="checkbox"
                checked={this.state.diagnosisRate["1"]}
                onChange={() => this.onChangeDiagnosisRate("1")}
              />
              <span>1 - Très Mauvais</span>
            </label>
          </div>
          <div className="col-md-3 mg-b-10">
            <label className="mg-b-5">Abonnement :</label>
            <label className="ckbox">
              <input
                type="checkbox"
                checked={this.state.diagnosisLicenses.present}
                onChange={() => this.onChangeDiagnosisLicense("present")}
              />
              <span>Présent</span>
            </label>
            <label className="ckbox">
              <input
                type="checkbox"
                checked={this.state.diagnosisLicenses.absent}
                onChange={() => this.onChangeDiagnosisLicense("absent")}
              />
              <span>Absent</span>
            </label>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Layout>
        <nav className="breadcrumb sl-breadcrumb">
          <a className="breadcrumb-item" href="/dashboard">
            Dashboard
          </a>
          <span className="breadcrumb-item active">
            Statistiques des enquêtes
          </span>
        </nav>
        <div className="sl-pagebody">
          <div className="row row-sm">
            <div className="col-xl-12">{this.renderDiagnosisParameters()}</div>
            <div className="col-xl-6">
              {this.renderDiagnosisAppRateSurvey()}
            </div>
            <div className="col-xl-6">
              {this.renderDiagnosisDiagRateSurvey()}
            </div>
            <div className="col-xl-12">
              {this.renderWelcomeAllSurvey()}
              {this.renderWelcomeLicensedSurvey()}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Stats;
