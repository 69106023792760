/* eslint-disable array-callback-return */
import "moment/locale/fr";

import React, { Component } from "react";

import API from "../utils/API";
import Layout from "../components/Layout";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import moment from "moment";

moment.locale("fr");
const funnelData = [];
let configurationAbsoluteData = [];
let configurationRatioData = [];

class Stats extends Component {
  state = {
    selectedWeek1: "2022 01",
    selectedWeek2: "2022 02",
    subscriptionDates: [],
    subscriptionWeeks: [],
    subscriptionMonths: [],
    configurationAbsoluteData: [],
    configurationRatioData: [],
    funnelData: [],
    funnelChartData: [],
    funnelTrueChartData: [],
    markers: [],
  };

  constructor() {
    super();
    this.api = new API();
  }

  componentDidMount() {
    this.refreshStats();
  }

  refreshStats = async () => {
    const fromShort = moment().startOf("month").subtract(3, "months");
    const fromMedium = moment().startOf("month").subtract(6, "months");
    const fromLong = moment().startOf("month").subtract(14, "months");
    const to = moment().add(1, "day");

    const subscriptionDates = await this.api.getStatsSubscriptions(
      fromShort.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      "day"
    );
    this.setState({
      subscriptionDates: [
        {
          id: "total",
          label: "total",
          data: subscriptionDates.map((datum) => {
            return { x: datum._id, y: datum.total };
          }),
        },
        {
          id: "web",
          label: "web",
          data: subscriptionDates.map((datum) => {
            return { x: datum._id, y: datum.web };
          }),
        },
      ],
    });

    configurationAbsoluteData = [
      {
        id: "(f)Inscription",
        data: [],
      },
      {
        id: "(d)Acou",
        data: [],
      },
      {
        id: "(d)IR",
        data: [],
      },
      {
        id: "(d)WFU",
        data: [],
      },
      {
        id: "(d)Capsule",
        data: [],
      },
      {
        id: "(f)Config",
        data: [],
      },
      {
        id: "View offer",
        data: [],
      },
      {
        id: "Press buy",
        data: [],
      },
      {
        id: "Buy",
        data: [],
      },
    ];

    configurationRatioData = [
      {
        id: "(f)Inscription",
        data: [],
      },
      {
        id: "(d)Config",
        data: [],
      },
      {
        id: "(d)Acou",
        data: [],
      },
      {
        id: "(d)IR",
        data: [],
      },
      {
        id: "(d)WFU",
        data: [],
      },
      {
        id: "(d)Capsule",
        data: [],
      },
      {
        id: "(f)Config",
        data: [],
      },
      {
        id: "View offer",
        data: [],
      },
      {
        id: "Press buy",
        data: [],
      },
      {
        id: "Buy",
        data: [],
      },
    ];

    const subscriptionWeeks = await this.api.getStatsSubscriptions(
      fromMedium.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      "week"
    );
    subscriptionWeeks.map((week) => {
      funnelData[week._id] = {
        subscriptions: { total: week.total },
        total: { total: week.total },
      };
      configurationAbsoluteData[0].data = [
        ...configurationAbsoluteData[0].data,
        {
          x: week._id,
          y: week.total,
        },
      ];
      configurationRatioData[0].data = [
        ...configurationRatioData[0].data,
        {
          x: week._id,
          y: 100,
        },
      ];
    });

    const weeksNumber = Object.keys(funnelData).reverse().slice(0, 2);
    this.setState({
      subscriptionWeeks,
      selectedWeek1: weeksNumber[1],
      selectedWeek2: weeksNumber[0],
    });

    const subscriptionMonths = await this.api.getStatsSubscriptions(
      fromLong.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      "month"
    );
    this.setState({ subscriptionMonths });

    const acouphenometryStart = await this.api.getStatsEventGroups(
      fromMedium.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      "week",
      {
        type: "ACTIVITY_START",
        "data.activity": "acouphenometry",
        "data.referrer.program": "configuration",
      }
    );
    acouphenometryStart.map((week) => {
      if (funnelData[week._id]) {
        funnelData[week._id].acouphenometryStart = { ...week };
        funnelData[week._id].total.total += week.total;
        configurationRatioData[1].data = [
          ...configurationRatioData[1].data,
          {
            x: week._id,
            y:
              Math.round(
                (week.total / funnelData[week._id].subscriptions.total) * 10000
              ) / 100,
          },
        ];
      }
      configurationAbsoluteData[1].data = [
        ...configurationAbsoluteData[1].data,
        {
          x: week._id,
          y: week.total,
        },
      ];
    });
    const irStart = await this.api.getStatsEventGroups(
      fromMedium.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      "week",
      {
        type: "ACTIVITY_START",
        "data.activity": "residualInhibitionDiagnosis",
        "data.referrer.program": "configuration",
      }
    );
    irStart.map((week) => {
      if (funnelData[week._id]) {
        funnelData[week._id].irStart = { ...week };
        funnelData[week._id].total.total += week.total;
        configurationRatioData[2].data = [
          ...configurationRatioData[2].data,
          {
            x: week._id,
            y:
              Math.round(
                (week.total / funnelData[week._id].subscriptions.total) * 10000
              ) / 100,
          },
        ];
      }
      configurationAbsoluteData[2].data = [
        ...configurationAbsoluteData[2].data,
        {
          x: week._id,
          y: week.total,
        },
      ];
    });
    const wfuStart = await this.api.getStatsEventGroups(
      fromMedium.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      "week",
      {
        type: "ACTIVITY_START",
        "data.activity": "evaluation",
        "data.referrer.program": "configuration",
      }
    );
    wfuStart.map((week) => {
      if (funnelData[week._id]) {
        funnelData[week._id].wfuStart = { ...week };
        funnelData[week._id].total.total += week.total;
        configurationRatioData[3].data = [
          ...configurationRatioData[3].data,
          {
            x: week._id,
            y:
              Math.round(
                (week.total / funnelData[week._id].subscriptions.total) * 10000
              ) / 100,
          },
        ];
      }
      configurationAbsoluteData[3].data = [
        ...configurationAbsoluteData[3].data,
        {
          x: week._id,
          y: week.total,
        },
      ];
    });
    const capsuleStart = await this.api.getStatsEventGroups(
      fromMedium.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      "week",
      {
        type: "ACTIVITY_START",
        "data.activity": "capsules",
        "data.referrer.program": "configuration",
      }
    );
    capsuleStart.map((week) => {
      if (funnelData[week._id] && week.total > 100) {
        funnelData[week._id].capsuleStart = { ...week };
        funnelData[week._id].total.total += week.total;
        configurationRatioData[4].data = [
          ...configurationRatioData[4].data,
          {
            x: week._id,
            y:
              Math.round(
                (week.total / funnelData[week._id].subscriptions.total) * 10000
              ) / 100,
          },
        ];
      }
      configurationAbsoluteData[4].data = [
        ...configurationAbsoluteData[4].data,
        {
          x: week._id,
          y: week.total,
        },
      ];
    });
    const configurationDone = await this.api.getStatsEventGroups(
      fromMedium.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      "week",
      {
        type: { $in: ["PROGRAM_COMPLETE", "PROGRAM_CANCEL"] },
        "data.program": "configuration",
      }
    );
    configurationDone.map((week) => {
      if (funnelData[week._id]) {
        funnelData[week._id].configurationDone = { ...week };
        funnelData[week._id].total.total += week.total;
        configurationRatioData[5].data = [
          ...configurationRatioData[5].data,
          {
            x: week._id,
            y:
              Math.round(
                (week.total / funnelData[week._id].subscriptions.total) * 10000
              ) / 100,
          },
        ];
      }
      configurationAbsoluteData[5].data = [
        ...configurationAbsoluteData[5].data,
        {
          x: week._id,
          y: week.total,
        },
      ];
    });

    const capsuleDone = await this.api.getStatsEventGroups(
      fromMedium.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      "week",
      {
        type: { $in: ["ACTIVITY_COMPLETE", "ACTIVITY_CANCEL"] },
        "data.activity": "capsules",
        "data.duration": { $gte: 10 },
      }
    );
    capsuleDone.map((week) => {
      if (funnelData[week._id]) {
        funnelData[week._id].capsuleDone = { ...week };
        funnelData[week._id].total.total += week.total;
      }
    });

    const viewOffer = await this.api.getStatsEventGroups(
      fromMedium.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      "week",
      {
        type: "LOG_NAVIGATE",
        "data.name": "Offer",
      }
    );
    viewOffer.map((week) => {
      if (funnelData[week._id]) {
        funnelData[week._id].viewOffer = { ...week };
        funnelData[week._id].total.total += week.total;
        configurationRatioData[6].data = [
          ...configurationRatioData[6].data,
          {
            x: week._id,
            y:
              Math.round(
                (week.total / funnelData[week._id].subscriptions.total) * 10000
              ) / 100,
          },
        ];
      }
      configurationAbsoluteData[6].data = [
        ...configurationAbsoluteData[6].data,
        {
          x: week._id,
          y: week.total,
        },
      ];
    });

    const pressBuy = await this.api.getStatsEventGroups(
      fromMedium.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      "week",
      {
        type: "LOG_ACTION",
        "data.name": "BUY_BUTTON",
      }
    );
    pressBuy.map((week) => {
      if (funnelData[week._id]) {
        funnelData[week._id].pressBuy = { ...week };
        funnelData[week._id].total.total += week.total;
        configurationRatioData[7].data = [
          ...configurationRatioData[7].data,
          {
            x: week._id,
            y:
              Math.round(
                (week.total / funnelData[week._id].subscriptions.total) * 10000
              ) / 100,
          },
        ];
      }
      configurationAbsoluteData[7].data = [
        ...configurationAbsoluteData[7].data,
        {
          x: week._id,
          y: week.total,
        },
      ];
    });

    const buy = await this.api.getStatsEventGroups(
      fromMedium.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      "week",
      {
        type: "LICENSE_REQUEST",
        "data.platform": { $ne: "IMMERSIVE_THERAPY" },
      }
    );
    buy.map((week) => {
      if (funnelData[week._id]) {
        funnelData[week._id].buy = { ...week };
        funnelData[week._id].total.total += week.total;
        configurationRatioData[8].data = [
          ...configurationRatioData[8].data,
          {
            x: week._id,
            y:
              Math.round(
                (week.total / funnelData[week._id].subscriptions.total) * 10000
              ) / 100,
          },
        ];
      }
      configurationAbsoluteData[8].data = [
        ...configurationAbsoluteData[8].data,
        {
          x: week._id,
          y: week.total,
        },
      ];
    });

    this.refreshFunnelChartData();
  };

  refreshFunnelChartData = () => {
    const chartData = [];
    let trueFunnelData = {};
    const stepKeys = Object.keys(funnelData);
    stepKeys.map((periodNumber, i) => {
      const barFunnelData = {};
      trueFunnelData[periodNumber] = {};
      Object.keys(funnelData[periodNumber]).map((step) => {
        const stepData = funnelData[periodNumber][step];
        barFunnelData[step] = stepData.total;
        if (step === "subscriptions") {
          const half = Math.round(stepData.total / 2);
          trueFunnelData[periodNumber][step] = [half, half];
        } else if (step !== "total" && step !== "capsuleDone") {
          trueFunnelData[periodNumber][step] = [stepData.android, stepData.ios];
        }
      });
      chartData.push({
        _id: periodNumber,
        ...barFunnelData,
      });
    });

    this.trueFunnelData = trueFunnelData;
    console.warn(
      "configurationAbsoluteData",
      configurationAbsoluteData,
      this.state.subscriptionDates
    );
    this.setState(
      {
        funnelChartData: chartData,
        configurationAbsoluteData,
        configurationRatioData,
      },
      this.refreshTrueFunnels
    );
  };

  refreshTrueFunnels() {
    window.jQuery(".svg-funnel-js").html("");

    console.warn("SELECTED WEEK", this.state.selectedWeek1);
    if (this.trueFunnelData[this.state.selectedWeek1]) {
      new window.FunnelGraph({
        container: ".configfunnel1",
        gradientDirection: "horizontal",
        data: {
          labels: [
            "Inscription",
            "Acouphenometry",
            "IR Start",
            "WFU Start",
            "Config Done",
          ],
          subLabels: ["Android", "iOS"],
          colors: [
            ["rgb(18, 68, 102)", "rgb(31, 120, 180)"],
            ["rgb(161, 223, 138)", "rgb(178, 223, 138)"],
          ],
          values: [
            this.trueFunnelData[this.state.selectedWeek1].subscriptions,
            this.trueFunnelData[this.state.selectedWeek1].acouphenometryStart,
            this.trueFunnelData[this.state.selectedWeek1].irStart,
            this.trueFunnelData[this.state.selectedWeek1].wfuStart,
            this.trueFunnelData[this.state.selectedWeek1].configurationDone,
          ],
        },
        displayPercent: true,
        direction: "horizontal",
      }).draw();
    }

    if (this.trueFunnelData[this.state.selectedWeek2]) {
      new window.FunnelGraph({
        container: ".configfunnel2",
        gradientDirection: "horizontal",
        data: {
          labels: [
            "Inscription",
            "Acouphenometry",
            "IR Start",
            "WFU Start",
            "Config Done",
          ],
          subLabels: ["Android", "iOS"],
          colors: [
            ["rgb(18, 68, 102)", "rgb(31, 120, 180)"],
            ["rgb(161, 223, 138)", "rgb(178, 223, 138)"],
          ],
          values: [
            this.trueFunnelData[this.state.selectedWeek2].subscriptions,
            this.trueFunnelData[this.state.selectedWeek2].acouphenometryStart,
            this.trueFunnelData[this.state.selectedWeek2].irStart,
            this.trueFunnelData[this.state.selectedWeek2].wfuStart,
            this.trueFunnelData[this.state.selectedWeek2].configurationDone,
          ],
        },
        displayPercent: true,
        direction: "horizontal",
      }).draw();
    }

    if (this.trueFunnelData[this.state.selectedWeek1]) {
      new window.FunnelGraph({
        container: ".purchasefunnel1",
        gradientDirection: "horizontal",
        data: {
          labels: [
            "Inscription",
            "Config Done",
            "View Offer",
            "Press Buy",
            "Buy",
          ],
          subLabels: ["Android", "iOS"],
          colors: [
            ["rgb(18, 68, 102)", "rgb(31, 120, 180)"],
            ["rgb(161, 223, 138)", "rgb(178, 223, 138)"],
          ],
          values: [
            this.trueFunnelData[this.state.selectedWeek1].subscriptions,
            this.trueFunnelData[this.state.selectedWeek1].configurationDone,
            this.trueFunnelData[this.state.selectedWeek1].viewOffer || [0, 0],
            this.trueFunnelData[this.state.selectedWeek1].pressBuy || [0, 0],
            this.trueFunnelData[this.state.selectedWeek1].buy || [0, 0],
          ],
        },
        displayPercent: true,
        direction: "horizontal",
      }).draw();
    }

    if (this.trueFunnelData[this.state.selectedWeek2]) {
      new window.FunnelGraph({
        container: ".purchasefunnel2",
        gradientDirection: "horizontal",
        data: {
          labels: [
            "Inscription",
            "Config Done",
            "View Offer",
            "Press Buy",
            "Buy",
          ],
          subLabels: ["Android", "iOS"],
          colors: [
            ["rgb(18, 68, 102)", "rgb(31, 120, 180)"],
            ["rgb(161, 223, 138)", "rgb(178, 223, 138)"],
          ],
          values: [
            this.trueFunnelData[this.state.selectedWeek2].subscriptions,
            this.trueFunnelData[this.state.selectedWeek2].configurationDone,
            this.trueFunnelData[this.state.selectedWeek2].viewOffer || [0, 0],
            this.trueFunnelData[this.state.selectedWeek2].pressBuy || [0, 0],
            this.trueFunnelData[this.state.selectedWeek2].buy || [0, 0],
          ],
        },
        displayPercent: true,
        direction: "horizontal",
      }).draw();
    }
  }

  setSelectedWeeks = (direction) => {
    const selectedWeek1Parts = this.state.selectedWeek1.split(" ");
    const selectedWeek2Parts = this.state.selectedWeek2.split(" ");
    const week1 = moment()
      .year(selectedWeek1Parts[0])
      .isoWeek(selectedWeek1Parts[1])
      .day("Monday")
      .add(direction, "week")
      .format("YYYY W");
    const week2 = moment()
      .year(selectedWeek2Parts[0])
      .isoWeek(selectedWeek2Parts[1])
      .day("Monday")
      .add(direction, "week")
      .format("YYYY W");
    if (this.trueFunnelData[week1] && this.trueFunnelData[week2]) {
      this.setState(
        {
          selectedWeek1: week1,
          selectedWeek2: week2,
        },
        this.refreshTrueFunnels
      );
    }
  };

  renderConfigurationTrueFunnel() {
    const selectedWeek1Parts = this.state.selectedWeek1.split(" ");
    const selectedWeek2Parts = this.state.selectedWeek2.split(" ");
    const weekDate1 = moment()
      .year(selectedWeek1Parts[0])
      .isoWeek(selectedWeek1Parts[1])
      .day("Monday")
      .add(1, "day")
      .format("dddd D MMMM");
    const weekDate2 = moment()
      .year(selectedWeek2Parts[0])
      .isoWeek(selectedWeek2Parts[1])
      .day("Monday")
      .add(1, "day")
      .format("dddd D MMMM");
    return (
      <div className="row row-sm">
        <div className="col-xl-6">
          <button className="mg-b-10" onClick={() => this.setSelectedWeeks(-1)}>
            &lt;&lt; Semaines précédentes
          </button>
          <div className="card pd-10 pd-sm-20 mg-b-20">
            <h6 className="card-body-title">
              CONFIGURATION - SEMAINE {this.state.selectedWeek1}{" "}
              <small>{weekDate1}</small>
            </h6>
            <div
              className="table-wrapper bg-gray mg-b-20 configfunnel1"
              style={{ height: "300px" }}
            />
          </div>
        </div>
        <div className="col-xl-6">
          <div style={{ textAlign: "right" }} className="mg-b-10">
            <button onClick={() => this.setSelectedWeeks(1)}>
              Semaines suivantes &gt;&gt;
            </button>
          </div>
          <div className="card pd-10 pd-sm-20 mg-b-20">
            <h6 className="card-body-title">
              CONFIGURATION - SEMAINE {this.state.selectedWeek2}{" "}
              <small>{weekDate2}</small>
            </h6>
            <div
              className="table-wrapper bg-gray mg-b-20 configfunnel2"
              style={{ height: "300px" }}
            />
          </div>
        </div>
      </div>
    );
  }
  renderPurchaseTrueFunnel() {
    const selectedWeek1Parts = this.state.selectedWeek1.split(" ");
    const selectedWeek2Parts = this.state.selectedWeek2.split(" ");
    const weekDate1 = moment()
      .year(selectedWeek1Parts[0])
      .isoWeek(selectedWeek1Parts[1])
      .day("Monday")
      .add(1, "day")
      .format("dddd D MMMM");
    const weekDate2 = moment()
      .year(selectedWeek2Parts[0])
      .isoWeek(selectedWeek2Parts[1])
      .day("Monday")
      .add(1, "day")
      .format("dddd D MMMM");
    return (
      <div className="row row-sm">
        <div className="col-xl-6">
          <button className="mg-b-10" onClick={() => this.setSelectedWeeks(-1)}>
            &lt;&lt; Semaines précédentes
          </button>
          <div className="card pd-10 pd-sm-20 mg-b-20">
            <h6 className="card-body-title">
              PARCOURS D'ACHAT - SEMAINE {this.state.selectedWeek1}{" "}
              <small>{weekDate1}</small>
            </h6>
            <div
              className="table-wrapper bg-gray mg-b-20 purchasefunnel1"
              style={{ height: "300px" }}
            />
          </div>
        </div>
        <div className="col-xl-6">
          <div style={{ textAlign: "right" }} className="mg-b-10">
            <button onClick={() => this.setSelectedWeeks(1)}>
              Semaines suivantes &gt;&gt;
            </button>
          </div>
          <div className="card pd-10 pd-sm-20 mg-b-20">
            <h6 className="card-body-title">
              PARCOURS D'ACHAT - SEMAINE {this.state.selectedWeek2}{" "}
              <small>{weekDate2}</small>
            </h6>
            <div
              className="table-wrapper bg-gray mg-b-20 purchasefunnel2"
              style={{ height: "300px" }}
            />
          </div>
        </div>
      </div>
    );
  }

  renderConfigurationBarFunnel() {
    return (
      <div className="card pd-10 pd-sm-20 mg-b-20">
        <h6 className="card-body-title">
          ÉTAPES DE LA CONFIGURATION RÉALISÉES PAR SEMAINE
        </h6>
        <div className="table-wrapper bg-gray" style={{ height: "300px" }}>
          <ResponsiveBar
            data={this.state.funnelChartData}
            keys={[
              "subscriptions",
              "acouphenometryStart",
              "irStart",
              "wfuStart",
              "configurationDone",
              "capsuleDone",
              "viewOffer",
              "pressBuy",
              "buy",
            ]}
            indexBy="_id"
            margin={{ top: 10, right: 0, bottom: 20, left: 0 }}
            padding={0.3}
            colors={{ scheme: "nivo" }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            axisLeft={null}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
          />
        </div>
      </div>
    );
  }

  renderSubscriptionMonthChart() {
    return (
      <div className="card pd-10 pd-sm-20 mg-b-20">
        <h6 className="card-body-title">INSCRIPTIONS PAR MOIS (APP et WEB)</h6>
        <div className="table-wrapper bg-gray" style={{ height: "200px" }}>
          <ResponsiveBar
            data={this.state.subscriptionMonths}
            keys={["web", "application"]}
            indexBy="_id"
            margin={{ top: 10, right: 0, bottom: 20, left: 30 }}
            padding={0.3}
            colors={{ scheme: "accent" }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
          />
        </div>
      </div>
    );
  }

  renderSubscriptionWeekChart() {
    return (
      <div className="card pd-10 pd-sm-20 mg-b-20">
        <h6 className="card-body-title">
          INSCRIPTIONS PAR SEMAINE (APP et WEB)
        </h6>
        <div className="table-wrapper bg-gray" style={{ height: "200px" }}>
          <ResponsiveBar
            data={this.state.subscriptionWeeks}
            keys={["web", "application"]}
            indexBy="_id"
            margin={{ top: 10, right: 0, bottom: 20, left: 30 }}
            padding={0.3}
            colors={{ scheme: "accent" }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
          />
        </div>
      </div>
    );
  }

  renderConfigurationAbsoluteChart() {
    return (
      <div className="card mg-b-20 pd-t-20 pd-l-20 pd-r-20">
        <h6 className="card-body-title">
          ÉTAPES DE CONFIGURATION EN NOMBRE D'UTILISATEURS
        </h6>
        <div className="table-wrapper bg-gray" style={{ height: "200px" }}>
          <ResponsiveLine
            data={this.state.configurationAbsoluteData.filter((v, i) => i < 6)}
            indexBy="_id"
            margin={{ top: 10, right: 30, bottom: 60, left: 30 }}
            padding={0.3}
            colors={{ scheme: "category10" }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            axisRight={{
              enable: true,
            }}
            yScale={{
              type: "linear",
              min: "auto",
            }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 55,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 8,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
            useMesh={true}
            enableGridY={false}
            markers={this.state.markers}
          />
        </div>
      </div>
    );
  }

  renderPurchaseAbsoluteChart() {
    return (
      <div className="card mg-b-20 pd-t-20 pd-l-20 pd-r-20">
        <h6 className="card-body-title">
          PARCOURS D'ACHAT EN NOMBRE D'UTILISATEURS
        </h6>
        <div className="table-wrapper bg-gray" style={{ height: "200px" }}>
          <ResponsiveLine
            data={this.state.configurationAbsoluteData.filter(
              (v, i) => i === 0 || i >= 7
            )}
            indexBy="_id"
            margin={{ top: 10, right: 30, bottom: 60, left: 30 }}
            padding={0.3}
            colors={{ scheme: "category10" }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            axisRight={{
              enable: true,
            }}
            yScale={{
              type: "linear",
              min: "auto",
            }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 55,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 8,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
            useMesh={true}
            enableGridY={false}
            markers={this.state.markers}
          />
        </div>
      </div>
    );
  }

  renderConfigurationRatioChart() {
    return (
      <div className="card mg-b-20 pd-t-20 pd-l-20 pd-r-20">
        <h6 className="card-body-title">
          ÉTAPES DE CONFIGURATION PAR RAPPORT À L'INSCRIPTION (%)
        </h6>
        <div className="table-wrapper bg-gray" style={{ height: "200px" }}>
          <ResponsiveLine
            data={this.state.configurationRatioData.filter((v, i) => i < 7)}
            indexBy="_id"
            margin={{ top: 10, right: 30, bottom: 60, left: 30 }}
            padding={0.3}
            colors={{ scheme: "category10" }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            axisRight={{
              enable: true,
            }}
            yScale={{
              type: "linear",
              min: "auto",
            }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 55,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 8,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
            useMesh={true}
            enableGridY={false}
          />
        </div>
      </div>
    );
  }

  renderPurchaseRatioChart() {
    return (
      <div className="card mg-b-20 pd-t-20 pd-l-20 pd-r-20">
        <h6 className="card-body-title">
          PARCOURS D'ACHAT PAR RAPPORT À L'INSCRIPTION (%)
        </h6>
        <div className="table-wrapper bg-gray" style={{ height: "200px" }}>
          <ResponsiveLine
            data={this.state.configurationRatioData.filter(
              (v, i) => i === 0 || i >= 7
            )}
            indexBy="_id"
            margin={{ top: 10, right: 30, bottom: 60, left: 30 }}
            padding={0.3}
            colors={{ scheme: "category10" }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            enableSlices={false}
            axisLeft={{
              tickValues: [0.1, 1, 2, 5, 20, 50, 100],
            }}
            axisRight={{
              enable: true,
              tickValues: [0.1, 1, 2, 5, 20, 50, 100],
            }}
            gridYValues={[0.1, 1, 2, 5, 20, 50, 100]}
            yScale={{
              type: "log",
              base: 10,
              min: 0.1,
              max: 100,
            }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 55,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 8,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
            useMesh={true}
            enableGridY={false}
          />
        </div>
      </div>
    );
  }

  renderSubscriptionDateChart() {
    return (
      <div className="card pd-10 pd-sm-20 mg-b-20">
        <h6 className="card-body-title">
          INSCRIPTIONS PAR JOUR (APP+WEB et WEB only)
        </h6>
        <div className="table-wrapper bg-gray" style={{ height: "200px" }}>
          <ResponsiveLine
            data={this.state.subscriptionDates}
            colors={{ scheme: "paired" }}
            xScale={{
              type: "time",
              format: "%Y-%m-%d",
              precision: "day",
            }}
            xFormat="time:%a %d %B %Y"
            yScale={{
              type: "linear",
              stacked: false,
            }}
            margin={{ top: 20, right: 10, bottom: 25, left: 10 }}
            padding={0.3}
            axisBottom={{
              format: "%b %d",
              tickValues: "every monday",
              legendOffset: -12,
            }}
            axisLeft={null}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            animate={true}
            curve={"monotoneX"}
            enablePointLabel={true}
            pointSize={6}
            pointBorderWidth={1}
            useMesh={true}
            enableGridY={false}
          />
        </div>
      </div>
    );
  }

  render() {
    if (this.state.selectedWeek1) {
      return (
        <Layout>
          <nav className="breadcrumb sl-breadcrumb">
            <a className="breadcrumb-item" href="/dashboard">
              Dashboard
            </a>
            <span className="breadcrumb-item active">Statistiques</span>
          </nav>
          <div className="sl-pagebody">
            <div className="row row-sm">
              <div className="col-xl-12">
                {this.renderSubscriptionDateChart()}
                {this.renderSubscriptionWeekChart()}
                {this.renderSubscriptionMonthChart()}
                {this.renderConfigurationAbsoluteChart()}
                {this.renderConfigurationRatioChart()}
                {this.renderConfigurationTrueFunnel()}
                {this.renderPurchaseAbsoluteChart()}
                {this.renderPurchaseRatioChart()}
                {this.renderPurchaseTrueFunnel()}
                {this.renderConfigurationBarFunnel()}
              </div>
            </div>
          </div>
        </Layout>
      );
    }
    return null;
  }
}

export default Stats;
