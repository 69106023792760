import React, { Component } from "react";

import API from "../utils/API";
import Layout from "../components/Layout";

class ProUserScreen extends Component {
  state = {
    email: "",
    password: "Hello" + (1000 + Math.round(Math.random() * 8999)),
    product: "5cebcd016642f274b0f304b5", // INFINITY
    firstName: "",
    lastName: "",
  };

  constructor(url) {
    super();
    this.api = new API();
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  submitForm = async (e) => {
    e.preventDefault();
    try {
      await this.api.createProUserWithLicense(
        this.state.email,
        this.state.password,
        this.state.product,
        this.state.firstName,
        this.state.lastName
      );
      alert(
        `Utilisateur et licence créés avec le mot de passe : ${this.state.password}`
      );
    } catch (e) {
      console.warn("Erreur createProUserWithLicense", e);
      alert(`Problème lors de la création… ${e}`);
    }
  };

  render() {
    return (
      <Layout>
        <nav className="breadcrumb sl-breadcrumb">
          <a className="breadcrumb-item" href="/dashboard">
            Dashboard
          </a>
          <a className="breadcrumb-item" href="/pros">
            Pros
          </a>
          <span className="breadcrumb-item active">
            Ajouter un utilisateur professionnel
          </span>
        </nav>
        <div className="sl-pagebody">
          <div className="row row-sm">
            <div className="col-md-12">
              <div className="card pd-20 pd-sm-40">
                <h6 className="card-body-title">
                  Création d'un utilisateur professionnel
                </h6>
                <p className="mg-b-20 mg-sm-b-20">
                  Aucun email ne sera envoyé automatiquement. Notez bien le mot
                  de passe pour le communiquer.
                </p>
                <form className="form-layout" onSubmit={this.submitForm}>
                  <div className="row mg-b-25">
                    <div className="col-lg-8">
                      <div className="form-group">
                        <label className="form-control-label">
                          Email&nbsp;: <span className="tx-danger">*</span>
                        </label>
                        <div class="input-group">
                          <span class="input-group-addon">
                            <i class="icon ion-email tx-16 lh-0 op-6"></i>
                          </span>
                          <input
                            className="form-control"
                            name="email"
                            type="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            placeholder="Adresse email"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group mg-b-10-force">
                        <label className="form-control-label">
                          Mot de passe&nbsp;:{" "}
                          <span className="tx-danger">*</span>
                        </label>
                        <div class="input-group">
                          <span class="input-group-addon">
                            <i class="icon ion-eye tx-16 lh-0 op-6"></i>
                          </span>
                          <input
                            className="form-control"
                            name="password"
                            type="text"
                            value={this.state.password}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-control-label">
                          Prénom&nbsp;:
                        </label>
                        <div class="input-group">
                          <span class="input-group-addon">
                            <i class="icon ion-person tx-16 lh-0 op-6"></i>
                          </span>
                          <input
                            className="form-control"
                            name="firstName"
                            type="text"
                            value={this.state.firstName}
                            onChange={this.handleChange}
                            placeholder="Prénom"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-control-label">
                          Nom de famille&nbsp;:
                        </label>
                        <div class="input-group">
                          <span class="input-group-addon">
                            <i class="icon ion-person tx-16 lh-0 op-6"></i>
                          </span>
                          <input
                            className="form-control"
                            name="lastName"
                            type="text"
                            value={this.state.lastName}
                            onChange={this.handleChange}
                            placeholder="Nom de famille"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-control-label">
                          Licence&nbsp;: `<span className="tx-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          readOnly={true}
                          disabled={true}
                          value="Accès complet à la thérapie pendant 100 ans"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-layout-footer">
                    <button className="btn btn-info mg-r-5">
                      Créer l'utilisateur et la licence
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ProUserScreen;
