import "react-table/react-table.css";

import React, { Component } from "react";

import API from "../utils/API";
import Layout from "../components/Layout";
import ReactTable from "react-table";
import moment from "moment";

const now = moment();
class Dashboard extends Component {
  state = {
    versionedUsers: [],
    versionedUsersPages: -1,
    versionedUsersLoading: false,
  };

  constructor() {
    super();
    this.api = new API();
    this.buildVersionedUsersTableColumns();
  }

  fetchVersionedUsers = async (state, instance) => {
    let search = "";
    state.filtered.map((item) => {
      if (item.id === "version") {
        search = item.value;
      }
      return item;
    });
    this.setState(
      {
        versionedUsersLoading: true,
        versionedUsers: await this.api.getVersionedUsers(
          state.pageSize,
          state.page + 1,
          search
        ),
      },
      () => {
        console.warn("versionedUsers", this.state.versionedUsers);
        this.setState({
          versionedUsersLoading: false,
          versionedUsersPages: 100,
        });
      }
    );
  };

  buildVersionedUsersTableColumns() {
    this.versionedUsersTableColumns = [
      {
        id: "subscriptionDate",
        Header: "Création",
        filterable: false,
        sortable: false,
        accessor: (u) =>
          u.subscriptionDate
            ? u.subscriptionDate === +u.subscriptionDate
              ? moment(u.subscriptionDate, "X").format("YYYY-MM-DD")
              : moment(u.subscriptionDate).format("YYYY-MM-DD")
            : "",
        width: 105,
      },
      {
        id: "status",
        Header: "@",
        filterable: false,
        sortable: false,
        accessor: (u) => (u.status == null ? "" : u.status),
        maxWidth: 25,
        Cell: (row) => (
          <div
            title={
              row.value.indexOf("WAITING") > -1
                ? "EMAIL NON-CONFIRMÉ"
                : "EMAIL CONFIRMÉ"
            }
          >
            <span
              style={{ color: row.value === "ACTIVE" ? "#57d500" : "#ffbf00" }}
            >
              &#x25cf;
            </span>
          </div>
        ),
        filterAll: true,
      },
      {
        id: "action",
        Header: "-",
        filterable: false,
        sortable: false,
        width: 25,
        Cell: (row) => (
          <a href={`/user?id=${row.row._original.id}`}>
            <i className="fa fa-eye"></i>
          </a>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        sortable: false,
        filterable: false,
        Cell: (row) => {
          if (row.row._original.birthDate) {
            const age = now.diff(moment(row.row._original.birthDate), "years");
            return (
              <div>
                {row.row._original.email}{" "}
                <span className="small">
                  - {row.row._original.firstName} {row.row._original.lastName} -{" "}
                  {age} ans
                </span>
              </div>
            );
          } else if (row.row._original.firstName) {
            return (
              <div>
                {row.row._original.email}{" "}
                <span className="small">
                  - {row.row._original.firstName} {row.row._original.lastName}
                </span>
              </div>
            );
          } else {
            return <div>{row.row._original.email}</div>;
          }
        },
      },
      {
        id: "events",
        Header: "Évènements",
        accessor: (u) => u.eventCount,
        maxWidth: 90,
        filterable: false,
        sortable: false,
        value: "1.4.6",
      },
      {
        id: "version",
        Header: "Version",
        accessor: (u) => u.version,
        maxWidth: 70,
        filterable: true,
        sortable: false,
      },
      {
        id: "platform",
        Header: "Plate-forme",
        accessor: (u) =>
          u.license
            ? u.license.product && u.license.product.platform
              ? u.license.product.platform.substring(0, 9).toLowerCase()
              : "error"
            : "-",
        maxWidth: 90,
        filterable: false,
        sortable: false,
      },
      {
        id: "offer",
        Header: "Offre",
        accessor: (u) =>
          u.license
            ? u.license.product && u.license.product.offer
              ? u.license.product.offer.substring(0, 11).toLowerCase()
              : "error"
            : "-",
        maxWidth: 90,
        filterable: false,
        sortable: false,
      },
      {
        id: "expirationDate",
        Header: "Expiration",
        accessor: (u) =>
          u.license && u.license.expirationDate
            ? moment(u.license.expirationDate, "X").format("YYYY-MM-DD")
            : "",
        width: 105,
        sortable: false,
        filterable: false,
      },
      {
        id: "action",
        Header: "Id",
        sortable: false,
        filterable: false,
        width: 25,
        Cell: (row) => (
          <div className="exportContainer">
            <button
              onClick={() => copyToClipboard(row.row._original.id)}
              title={row.row._original.id}
              className="fa fa-copy"
            />
          </div>
        ),
        filterAll: true,
      },
    ];
  }

  renderVersionedUsersCard = () => {
    return (
      <div className="card pd-20 pd-sm-40 mg-b-20">
        <h6 className="card-body-title">Utilisateurs avec évènenements</h6>
        <div className="table-wrapper bg-gray">
          <ReactTable
            style={{ width: "100%" }}
            defaultPageSize={10}
            data={this.state.versionedUsers}
            pages={this.state.versionedUsersPages}
            loading={this.state.versionedUsersLoading}
            manual
            onFetchData={this.fetchVersionedUsers}
            columns={this.versionedUsersTableColumns}
            filterable={true}
            className="-striped -highlight"
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]).includes(filter.value)
            }
            defaultSorted={[{ id: "events", desc: true }]}
            defaultFiltered={[
              {
                id: "version",
                value: localStorage.getItem("searchVersion", ""),
              },
            ]}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <Layout>
        <nav className="breadcrumb sl-breadcrumb">
          <a className="breadcrumb-item" href="/dashboard">
            Dashboard
          </a>
          <span className="breadcrumb-item active">
            Utilisateurs par version d'application
          </span>
        </nav>

        <div className="sl-pagebody">
          <div className="row row-sm">
            <div className="col-xl-12">{this.renderVersionedUsersCard()}</div>
          </div>
        </div>
      </Layout>
    );
  }
}

function copyToClipboard(data) {
  var $body = document.getElementsByTagName("body")[0];
  var $tempInput = document.createElement("INPUT");
  $body.appendChild($tempInput);
  $tempInput.setAttribute("value", data);
  $tempInput.select();
  document.execCommand("copy");
  $body.removeChild($tempInput);
}

export default Dashboard;
