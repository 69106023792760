/* eslint-disable array-callback-return */
import React, { Component } from "react";

import API from "../utils/API";
import JSONPretty from "react-json-pretty";
import Layout from "../components/Layout";
import Radar from "../components/Radar";
import { ResponsiveLine } from "@nivo/line";
import countries from "../utils/countries";
import moment from "moment";

const now = moment();

const therapies = {
  trt: `Thérapie sonore`,
  cbt: `Thérapies cognitives`,
  residualInhibition: `Impulsions acoustiques`,
  relaxation: `Relaxation`,
};

const activities = {
  acouphenometry: { label: "Acou.", category: "diag" },
  residualInhibitionDiagnosis: { label: "RI diag", category: "diag" },
  lullaby: { label: "Berceuse", category: "trt" },
  plinko: { label: "Plinko", category: "trt" },
  theIsland: { label: `L'île`, category: "trt" },
  constellations: { label: "Constell.", category: "trt" },
  simons: { label: "Simon", category: "trt" },
  associationSort: { label: "Méli-Mélo", category: "cbt" },
  lostRecipe: { label: "R7 perdue", category: "cbt" },
  toyFactory: { label: "ToyFact", category: "cbt" },
  coherentBreathing: { label: "Inspirat°", category: "rlx" },
  squareBreathing: { label: "Respira²", category: "rlx" },
  abdominalBreathing: { label: "RespAbdo", category: "rlx" },
  tempo: { label: "Tempo", category: "ri" },
  catchThem: { label: "Attrap-les", category: "ri" },
  keepTheShape: { label: "GLForme", category: "ri" },
  smoothieBar: { label: "Smoothies", category: "ri" },
  whiteNoise: { label: "BBlanc", category: "mask" },
  capsules: { label: "Capsules", category: "learn" },
};

class Dashboard extends Component {
  state = {
    userId: null,
    email: "",
    editUserEmail: "",
    editUserPassword: "",
    reducers: [],
    tinnituses: [],
    events: [],
    selectedAction: null,
    mailchimp: null,
    selectedWfu1: 0,
    selectedWfu2: 0,
  };

  constructor(url) {
    super();
    const params = {};
    const query = url.location.search.substring(1).split("&");
    for (let i = 0; i < query.length; ++i) {
      let [name, value] = query[i].split("=");
      params[name] = decodeURIComponent(value);
    }
    this.state.userId = params["id"]
      .replace(`ObjectId("`, "")
      .replace(`")`, "");
  }

  async componentDidMount() {
    this.api = new API();
    try {
      await this.refreshData();
    } catch (e) {
      console.error("User exception", e);
      this.setState({
        reducers: {
          user: {
            id: "0",
            email: "Utilisateur inconnu",
            status: "UNKNOWN",
            subscriptionDate: null,
          },
        },
      });
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  refreshData = async () => {
    let reducers = await this.api.getUser(this.state.userId);
    if (!reducers) {
      throw new Error("UnknownUser");
    }
    let tinnituses = [];
    let filteredReducers = `Cet utilisateur ne s'est jamais connecté\nsur l'application mobile :'(`;
    if (reducers.reducers) {
      const { tinnituses: ts, ...frs } = reducers.reducers;
      tinnituses = ts;
      filteredReducers = frs;
      reducers.licenses = reducers.licenses.reverse();
      reducers.reducers = filteredReducers;
    }
    console.warn("reducers", reducers);
    const activitySummary = [];

    let allActivities = Object.keys(activities);
    if (reducers.reducers && reducers.reducers.summary) {
      const summary = reducers.reducers.summary;
      const summaryKeys = Object.keys(summary).filter(
        (item) =>
          ["programs", "welcome", "evaluation", "learning"].indexOf(item) === -1
      );
      summaryKeys.map((key) => {
        if (summary[key].count > 0) {
          activitySummary.push([key, summary[key]]);
          allActivities = allActivities.filter((item) => item !== key);
        }
      });
      activitySummary.sort((a, b) => b[1].lastDoneDate - a[1].lastDoneDate);
      allActivities.map((activity) => {
        activitySummary.push([activity, { count: 0 }]);
      });
    }

    // Ne pas afficher dans le cadre "reducers"
    if (typeof filteredReducers !== "string") {
      Object.keys(filteredReducers.summary)
        .filter((key) => key !== "evaluation")
        .map((key) => {
          delete filteredReducers.summary[key];
        });
    }

    this.setState(
      {
        activitySummary,
        reducers: { ...reducers, tinnituses, reducers: filteredReducers },
      },
      () => {
        this.wfus =
          (this.state.reducers.reducers &&
            this.state.reducers.reducers.summary &&
            this.state.reducers.reducers.summary.evaluation &&
            this.state.reducers.reducers.summary.evaluation.wfu) ||
          [];

        this.setState({
          selectedWfu1: this.wfus.length > 0 ? 1 : 0,
          selectedWfu2: this.wfus.length,
          userId: this.state.reducers.user.id,
          email: this.state.reducers.user.email,
          editUserEmail: this.state.reducers.user.email,
        });
      }
    );

    setTimeout(async () => {
      const events = (await this.api.getUserEventSummary(reducers.user.id)).map(
        (evt) => {
          return {
            x: evt._id,
            y: evt.count,
          };
        }
      );
      this.setState({ eventSummary: events }, () => {
        console.warn("evtSum", this.state.eventSummary);
      });
    });

    setTimeout(async () => {
      this.setState({
        events: await this.api.getUserEventSourcing(reducers.user.id),
      });
    });

    setTimeout(async () => {
      const data = await this.api.getUserMailChimpData(reducers.user.email);
      this.setState({
        mailchimpLoaded: true,
        mailchimp: data,
      });
    });
  };

  submitEditUserForm = async (e) => {
    e.preventDefault();
    const isEmail = this.state.email.trim() !== this.state.editUserEmail.trim();
    const isPassword = this.state.editUserPassword.trim() !== "";
    if (!isEmail && !isPassword) {
      alert("Aucun changement à sauvegarder");
    } else {
      let message = "Modifier email et mot de passe ?";
      if (isEmail && !isPassword) {
        message = "Modifier email ?";
      } else if (!isEmail && isPassword) {
        message = "Modifier password ?";
      }
      if (window.confirm(message)) {
        if (isEmail) {
          await this.api.patchUser(
            this.state.userId,
            "email",
            this.state.editUserEmail
          );
          alert("Email modifié");
          this.refreshData();
          this.setState({ editUserEmail: "", selectedAction: "" });
        }
        if (isPassword) {
          await this.api.patchUser(
            this.state.userId,
            "password",
            this.state.editUserPassword
          );
          alert("Mot de passe modifié");
          this.setState({ editUserPassword: "", selectedAction: "" });
        }
      }
    }
  };

  submitAddLicenseForm = async (e, value) => {
    e.preventDefault();
    await this.api.addLicense(this.state.userId, value);
    this.refreshData();
    this.setState({ selectedAction: "" });
  };

  submitDeleteUserForm = async (e) => {
    e.preventDefault();

    if (this.state.confirmDelete === "SUPPRIMER") {
      await this.api.deleteUser(this.state.userId);
      alert(
        `Compte supprimé. Il faut toujours envoyer un email de confirmation à l'utilisateur.`
      );
      window.location.href = "/dashboard";
    } else {
      alert(
        `Vous n'avez pas tapé SUPPRIMER dans le champ de confirmation de suppression. Aucune action n'a été exécutée.`
      );
    }
  };

  renderExternalCard = () => {
    return (
      <div className="card pd-10 pd-sm-20 mg-b-20">
        <h6 className="card-body-title">SERVICES EXTERNES</h6>
        <div className="table-wrapper bg-gray">
          <button
            className="mg-r-10"
            onClick={() =>
              window.open(
                `https://dashboard.stripe.com/search?query=${this.state.email}`,
                "_blank"
              )
            }
          >
            Stripe
          </button>
          <button
            className="mg-r-10"
            onClick={() =>
              window.open(`https://dashboard.getalma.eu/payments/`, "_blank")
            }
          >
            Alma
          </button>
          <button
            className="mg-r-10"
            onClick={() =>
              window.open(
                `https://play.google.com/console/u/0/developers/8289039352146858351/orders`,
                "_blank"
              )
            }
          >
            Google
          </button>
          <button
            className="mg-r-10"
            onClick={() =>
              window.open(
                `https://reportingitc2.apple.com/sales.html?measure=total_tax_usd_utc`,
                "_blank"
              )
            }
          >
            Apple
          </button>
          <button
            className="mg-r-10"
            onClick={() =>
              window.open(
                `https://immersivetherapy.zendesk.com/agent/search/1?q=${this.state.email}`,
                "_blank"
              )
            }
          >
            Zendesk
          </button>
          {this.state.mailchimp && this.state.mailchimp.web_id ? (
            <button
              className="mg-r-10"
              onClick={() =>
                window.open(
                  `https://us14.admin.mailchimp.com/lists/members/view?id=${this.state.mailchimp.web_id}`,
                  "_blank"
                )
              }
            >
              Mailchimp
            </button>
          ) : null}
        </div>
      </div>
    );
  };

  renderUIDCard = () => {
    return (
      <div className="card pd-10 pd-sm-20 mg-b-20">
        <h6 className="card-body-title">UID</h6>
        <div className="table-wrapper bg-gray">
          <div className="mg-r-10 mg-t-5">{this.state.userId}</div>
        </div>
      </div>
    );
  };

  onSelectAction = (event) => {
    this.setState({ selectedAction: event.target.value });
  };

  onWFU1Select = (event) => {
    this.setState({ selectedWfu1: event.target.value });
  };
  onWFU2Select = (event) => {
    this.setState({ selectedWfu2: event.target.value });
  };

  renderActionsCard = () => {
    return (
      <div className="card pd-10 pd-sm-20 mg-b-20">
        <h6 className="card-body-title">ACTIONS</h6>
        <div className="table-wrapper bg-gray">
          <select
            onChange={this.onSelectAction}
            defaultValue=""
            className="form-control compact"
          >
            <option value="">- Choisir une action -</option>
            <option value="edit-user">Modifier email ou mot de passe</option>
            <option value="add-license">Ajouter une license</option>
            <option value="delete-user">Supprimer le compte utilisateur</option>
          </select>
        </div>
      </div>
    );
  };

  renderActionForms = () => {
    if (!this.state.selectedAction) {
      return null;
    }
    let content;
    switch (this.state.selectedAction) {
      case "edit-user": {
        content = this.renderEditUserForm();
        break;
      }
      case "add-license": {
        content = this.renderAddLicenseForm();
        break;
      }
      case "delete-user": {
        content = this.renderDeleteUserForm();
        break;
      }
      default: {
        alert("nope.");
      }
    }
    return (
      <div className="row row-sm">
        <div className="col-md-12 mg-b-20">{content}</div>
      </div>
    );
  };

  renderEditUserForm = () => {
    return (
      <div className="card pd-20">
        <h6 className="card-body-title">
          Modification de l'adresse email ou du mot de passe de l'utilisateur
        </h6>
        <form className="form-layout" onSubmit={this.submitEditUserForm}>
          <div className="row">
            <div className="col-lg-5">
              <div className="form-group mg-b-0">
                <label className="form-control-label">Email&nbsp;:</label>
                <div className="input-group">
                  <span className="input-group-addon">
                    <i className="icon ion-email tx-16 lh-0 op-6"></i>
                  </span>
                  <input
                    className="form-control"
                    name="editUserEmail"
                    type="email"
                    value={this.state.editUserEmail}
                    onChange={this.handleChange}
                    placeholder="Adresse email"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="form-group mg-b-0">
                <label className="form-control-label">
                  Mot de passe&nbsp;: (laissez le champ vide pour ne pas le
                  modifier)
                </label>
                <div className="input-group">
                  <span className="input-group-addon">
                    <i className="icon ion-eye tx-16 lh-0 op-6"></i>
                  </span>
                  <input
                    className="form-control"
                    name="editUserPassword"
                    type="text"
                    value={this.state.editUserPassword}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-group mg-b-0">
                <label className="form-control-label">&nbsp;</label>
                <div className="input-group">
                  <button style={{ width: "100%" }} className="btn btn-info">
                    Modifier l'utilisateur
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  renderAddLicenseForm = () => {
    return (
      <div className="card pd-20">
        <h6 className="card-body-title">Ajout d'une licence à l'utilisateur</h6>
        <form className="form-layout" onSubmit={this.submitForm}>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group mg-b-0">
                <div className="input-group">
                  <button
                    style={{ width: "100%" }}
                    className="btn btn-success"
                    onClick={(e) => this.submitAddLicenseForm(e, "DISCOVERY24")}
                  >
                    Découverte 24h App&nbsp;mobile
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group mg-b-0">
                <div className="input-group">
                  <button
                    style={{ width: "100%" }}
                    className="btn btn-success"
                    onClick={(e) =>
                      this.submitAddLicenseForm(e, "DISCOVERY24WEB")
                    }
                  >
                    Découverte 24h Web
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group mg-b-0">
                <div className="input-group">
                  <button
                    style={{ width: "100%" }}
                    className="btn btn-primary"
                    onClick={(e) => this.submitAddLicenseForm(e, "FREE1WEEK")}
                  >
                    FREE 1 WEEK
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group mg-b-0">
                <div className="input-group">
                  <button
                    style={{ width: "100%" }}
                    className="btn btn-primary"
                    onClick={(e) => this.submitAddLicenseForm(e, "FREE1MONTH")}
                  >
                    FREE 1 MONTH
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2">
              <div className="form-group mg-b-0">
                <div className="input-group">
                  <button
                    style={{ width: "100%" }}
                    className="btn btn-info"
                    onClick={(e) => this.submitAddLicenseForm(e, "ALMA")}
                  >
                    Ajout de 12 mois Alma
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-group mg-b-0">
                <div className="input-group">
                  <button
                    style={{ width: "100%" }}
                    className="btn btn-info"
                    onClick={(e) => this.submitAddLicenseForm(e, "STRIPE")}
                  >
                    Ajout 12 mois Stripe
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-group mg-b-0">
                <div className="input-group">
                  <button
                    style={{ width: "100%" }}
                    className="btn btn-info"
                    onClick={(e) => this.submitAddLicenseForm(e, "APPLE")}
                  >
                    Ajout 12 mois Apple
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-group mg-b-0">
                <div className="input-group">
                  <button
                    style={{ width: "100%" }}
                    className="btn btn-info"
                    onClick={(e) => this.submitAddLicenseForm(e, "GOOGLE")}
                  >
                    Ajout 12 mois Google
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-group mg-b-0">
                <div className="input-group">
                  <button
                    style={{ width: "100%" }}
                    className="btn btn-primary"
                    onClick={(e) => this.submitAddLicenseForm(e, "FREE")}
                  >
                    Ajout 12 mois FREE
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-group mg-b-0">
                <div className="input-group">
                  <button
                    style={{ width: "100%" }}
                    className="btn btn-danger"
                    onClick={(e) => this.submitAddLicenseForm(e, "INFINITY")}
                  >
                    Ajout INFINITY/PRO
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  renderDeleteUserForm = () => {
    return (
      <div className="card pd-20">
        <h6 className="card-body-title">Suppression du compte utilisateur</h6>
        <form className="form-layout" onSubmit={this.submitForm}>
          <div className="row">
            <div className="col-lg-5">
              <div className="form-group mg-b-0">
                <label className="form-control-label">
                  Tapez pour confirmer&nbsp;: SUPPRIMER
                </label>
                <div className="input-group">
                  <span className="input-group-addon">
                    <i className="icon ion-checkmark-round tx-16 lh-0 op-6"></i>
                  </span>
                  <input
                    className="form-control"
                    name="confirmDelete"
                    type="text"
                    value={this.state.confirmDelete}
                    onChange={this.handleChange}
                    placeholder="SUPPRIMER"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-group mg-b-0">
                <label className="form-control-label">&nbsp;</label>
                <div className="input-group">
                  <button
                    style={{ width: "100%" }}
                    className="btn btn-danger"
                    onClick={(e) => this.submitDeleteUserForm(e)}
                  >
                    Supprimer l'utilisateur
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  renderReducersCard = () => {
    if (!this.state.reducers.user) {
      return null;
    }
    const user = this.state.reducers.user;
    let userName = user.lastName && `${user.firstName} ${user.lastName}`.trim();
    const birthDate = user.birthDate && moment(user.birthDate);
    const subscriptionDate = moment(user.subscriptionDate);
    const thi =
      this.state.reducers.reducers &&
      this.state.reducers.reducers.summary &&
      this.state.reducers.reducers.summary.evaluation &&
      this.state.reducers.reducers.summary.evaluation.thi &&
      this.state.reducers.reducers.summary.evaluation.thi.slice(-1)[0];
    this.eventSummarySize =
      this.state.eventSummary && this.state.eventSummary.length;
    let lastEvent = this.state.events && this.state.events[0];
    const lang = lastEvent && lastEvent.language;
    if (
      !lastEvent ||
      !lastEvent.language ||
      lastEvent.language.indexOf("-") === -1
    ) {
      lastEvent = null;
    }
    const country = lastEvent && lastEvent.language.split("-")[1];
    return (
      <div className="card pd-20 pd-sm-20 mg-b-20">
        <div className="table-wrapper bg-gray">
          <div className="row row-sm">
            <div className="col-md-12">
              <h6 className="card-body-title">UTILISATEUR</h6>
              <ul className="userCard">
                {userName ? (
                  <li>
                    <strong>Identité</strong>
                    {userName}
                    <br />
                    <em title="Nickname">{user.nickname}</em>
                  </li>
                ) : null}
                {birthDate ? (
                  <li>
                    <strong>Âge</strong>
                    {now.diff(birthDate, "years")} ans
                    <br />
                    {birthDate.format("D MMM YYYY")}
                  </li>
                ) : null}
                {country ? (
                  <li>
                    <strong>Pays</strong>
                    {countryLabel(country)}
                    <br />
                    {lang}
                  </li>
                ) : null}
                <li>
                  <strong>Inscrit</strong>
                  {subscriptionDate.fromNow()}
                  <br />
                  {moment(subscriptionDate).format("ddd D MMM YYYY à HH:mm")}
                </li>
                {lastEvent ? (
                  <li>
                    <strong>Téléphone</strong>
                    {lastEvent.os.model}
                    <br />
                    {`${lastEvent.os.type.toLowerCase()} ${
                      lastEvent.os.version
                    }`}
                  </li>
                ) : null}
                <li>
                  <strong>Email</strong>
                  {user.email}
                  <br />
                  <button
                    title="Copier email"
                    className="fa fa-copy"
                    onClick={() => copyToClipboard(user.email)}
                  />
                </li>
                {this.state.mailchimpLoaded ? (
                  <li>
                    <strong>Mailchimp</strong>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          this.state.mailchimp &&
                          this.state.mailchimp.status === "subscribed" &&
                          this.state.mailchimp.tags
                            ? this.state.mailchimp.tags
                                .filter((t) => t.indexOf("AB_") === -1)
                                .join("<br />")
                            : this.state.mailchimp
                            ? this.state.mailchimp.status
                            : "Absent",
                      }}
                    />
                  </li>
                ) : null}
                {this.eventSummarySize ? (
                  <li>
                    <strong>Sessions</strong>
                    {this.eventSummarySize} jour
                    {this.eventSummarySize > 1 ? "s" : null}
                    <br />
                    {this.eventSummarySize > 1 ? "distincts" : "seulement"}
                  </li>
                ) : null}
                {thi ? (
                  <li>
                    <strong>Dernier THI</strong>
                    {thi.score}
                    <br />
                    {moment(thi.date, "X").format("DD MMM YY")}
                  </li>
                ) : null}
                <li>
                  <strong>Lien Promo</strong>-15 euros
                  <br />
                  <button
                    title="Copier le lien SANS GARANTIE 30 JOURS"
                    className="fa fa-copy"
                    onClick={() =>
                      copyToClipboard(
                        "https://fr.diapason-app.com/acheter/?code=XQ76CP15&login=" +
                          user.email
                      )
                    }
                  />
                  &nbsp;
                  <button
                    title="Copier le lien AVEC GARANTIE 30 JOURS"
                    className="fa fa-copy"
                    onClick={() =>
                      copyToClipboard(
                        "https://fr.diapason-app.com/acheter/?code=XQ76CP15&ref=email&login=" +
                          user.email
                      )
                    }
                  />
                </li>
                <li>
                  <strong>Lien Promo</strong>-30 euros
                  <br />
                  <button
                    title="Copier le lien SANS GARANTIE 30 JOURS"
                    className="fa fa-copy"
                    onClick={() =>
                      copyToClipboard(
                        "https://fr.diapason-app.com/acheter/?code=SC76ZD30&login=" +
                          user.email
                      )
                    }
                  />
                  &nbsp;
                  <button
                    title="Copier le lien AVEC GARANTIE 30 JOURS"
                    className="fa fa-copy"
                    onClick={() =>
                      copyToClipboard(
                        "https://fr.diapason-app.com/acheter/?code=SC76ZD30&ref=email&login=" +
                          user.email
                      )
                    }
                  />
                </li>
                {}
              </ul>
            </div>
          </div>
          <div className="row row-sm">
            <div className="col-md-6">
              <h6 className="card-body-title">
                ACTIVITÉ DE L'UTILISATEUR{" "}
                <small>
                  (exercice/nombre de fois terminé/date de la dernière fois)
                </small>
              </h6>
              <ul className="summaryActivity">
                {this.state.activitySummary &&
                  this.state.activitySummary.map(([key, content]) => (
                    <li
                      key={`s${key}`}
                      title={`${key} - ${JSON.stringify(content, 1)}`}
                      className={activities[key] && activities[key].category}
                      dangerouslySetInnerHTML={{
                        __html: `${
                          (activities[key] && activities[key].label) ||
                          key.substring(0, 8)
                        }<br />${content.count || 0}<br />${
                          content.lastDoneDate
                            ? moment(content.lastDoneDate, "X").format(
                                "DD MMM YY"
                              )
                            : "Jamais fait"
                        }`,
                      }}
                    />
                  ))}
              </ul>
            </div>
            <div className="col-md-6">
              <h6 className="card-body-title">Évènements par jour</h6>
              <div
                className="table-wrapper bg-gray"
                style={{ height: "280px" }}
              >
                {this.state.eventSummary ? (
                  <ResponsiveLine
                    margin={{ top: 10, right: 10, bottom: 25, left: 25 }}
                    data={[{ id: "évènements", data: this.state.eventSummary }]}
                    xScale={{
                      type: "time",
                      format: "%Y-%m-%d",
                      precision: "day",
                    }}
                    xFormat="time:%Y-%m-%d"
                    yScale={{
                      type: "linear",
                    }}
                    axisBottom={{
                      format: "%b '%y",
                      tickValues: "every month",
                    }}
                    enablePointLabel={false}
                    pointSize={4}
                    pointBorderWidth={1}
                    pointBorderColor={{
                      from: "color",
                      modifiers: [["darker", 0.3]],
                    }}
                    useMesh={true}
                    enableSlices={false}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="row row-sm">
            {this.renderProgram()}
            <div className="col-md-3">
              <h6 className="card-body-title tx-12 mg-b-5">REDUCERS</h6>
              <div id="reducers">
                <JSONPretty json={this.state.reducers.reducers} />
              </div>
            </div>

            <div className="col-md-3">
              <h6 className="card-body-title tx-12 mg-b-5">ACOUPHÈNES</h6>
              <div className="tinnituses">
                {this.state.reducers.tinnituses &&
                  this.state.reducers.tinnituses.map((tinnitus) => {
                    let sideLabel = "bilatéral";
                    if (!tinnitus.isRight) {
                      sideLabel = "gauche";
                    } else {
                      sideLabel = "droit";
                    }
                    let pulsatility = "non renseigné";
                    switch (tinnitus.pulsatility) {
                      case "no": {
                        pulsatility = "non";
                        break;
                      }
                      case "yes": {
                        pulsatility = "oui";
                        break;
                      }
                      case "idk": {
                        pulsatility = "ne sait pas";
                        break;
                      }
                      default: {
                        pulsatility = tinnitus.pulsatility;
                      }
                    }
                    return (
                      <ul key={`t${tinnitus.id}`} className="tinnitus">
                        <li>
                          <strong>nom:</strong> {tinnitus.name}
                        </li>
                        <li title={tinnitus.frequency}>
                          <strong>fréquence:</strong> {tinnitus.frequencyHz}
                          &nbsp;Hz
                        </li>
                        <li title={tinnitus.bandwidth}>
                          <strong>bande&nbsp;passante:</strong>{" "}
                          {`${tinnitus.bandwidthOctave}`.replace(".", ",")}
                          &nbsp;octave
                        </li>
                        <li>
                          <strong>périodicité:</strong>{" "}
                          {tinnitus.isContinuous ? "permanent" : "intermittent"}
                        </li>
                        <li>
                          <strong>latéralité:</strong> {sideLabel}
                        </li>
                        <li>
                          <strong>pulsatif:</strong> {pulsatility}
                        </li>
                      </ul>
                    );
                  })}
                {/*<JSONPretty json={this.state.reducers.tinnituses}/>*/}
              </div>
            </div>
            <div className="col-md-3">
              <h6 className="card-body-title tx-12 mg-b-5">LICENCES</h6>
              <div className="licenses">
                {this.state.reducers.licenses &&
                  this.state.reducers.licenses.map((license) => {
                    const expDate = moment(license.expirationDate, "X");
                    const expired = expDate.isBefore(now);
                    let classNames = ["license"];
                    classNames.push(
                      license.refunded
                        ? "btn-warning"
                        : expired
                        ? "bg-primary-light"
                        : "bg-success-light"
                    );
                    let transaction = license.transactionId
                      ? license.transactionId
                      : "-";
                    if (
                      license.transactionId &&
                      license.product.platform === "STRIPE"
                    ) {
                      transaction = `<a href="https://dashboard.stripe.com/search?query=${transaction}" target="_blank">${transaction}</a>`;
                    }
                    const code = license.professionalCode;
                    const creationDate = moment(license.creationDate);
                    const activationDate = moment(license.activationDate, "X");
                    return (
                      <ul key={license.id} className={classNames.join(" ")}>
                        <li>
                          <strong>id:</strong> <small>{license.id}</small>
                        </li>
                        <li>
                          <strong>produit:</strong> {license.product.platform} /{" "}
                          {license.product.offer}
                        </li>
                        <li>
                          <strong>début:</strong>{" "}
                          {activationDate.format("YYYY-MM-DD HH:mm:ss")}
                        </li>
                        <li>
                          <strong>fin:</strong>{" "}
                          {expDate.format("YYYY-MM-DD HH:mm:ss")}
                        </li>
                        <li>
                          <strong>transaction:</strong>{" "}
                          <small>
                            <span
                              dangerouslySetInnerHTML={{ __html: transaction }}
                            />
                          </small>
                        </li>
                        {(() => {
                          return activationDate.diff(creationDate, "days") >
                            0 ? (
                            <li>
                              <strong>date d'achat:</strong>{" "}
                              {activationDate.diff(creationDate, "days")} jours
                              avant exp. licence précédente
                            </li>
                          ) : null;
                        })()}
                        {(() => {
                          return !expired && !license.refunded ? (
                            <li>
                              <strong>expiration:</strong> dans{" "}
                              {expDate.diff(now, "days")} jours
                            </li>
                          ) : null;
                        })()}
                        {(() => {
                          return license.refunded ? (
                            <li>
                              <strong>REMBOURSÉE:</strong> OUI
                            </li>
                          ) : null;
                        })()}
                        {(() => {
                          return code ? (
                            <li className="coupon">
                              <strong>COUPON</strong>
                              <br />
                              <span>{code.code}</span>
                            </li>
                          ) : null;
                        })()}
                      </ul>
                    );
                  })}
                {/*<JSONPretty json={this.state.reducers.licenses}/>*/}
              </div>
            </div>
          </div>
          <div className="row row-sm">
            {this.renderWfuRadar1()}
            {this.renderWfuRadar2()}
          </div>
        </div>
      </div>
    );
  };

  renderProgram = () => {
    if (
      this.state.reducers &&
      this.state.reducers.reducers &&
      this.state.reducers.reducers.progression
    ) {
      const progression = this.state.reducers.reducers.progression;

      let dayDosage = {};
      if (progression.program && progression.program.dayDosage) {
        dayDosage = progression.program.dayDosage;
      }

      let dayDuration = 0;
      Object.keys(dayDosage).map((key) => {
        dayDuration += Math.floor(
          Math.min(dayDosage[key].progression, dayDosage[key].objective) / 60
        );
      });

      const programName =
        (progression.program && progression.program.name) || "configuration";

      const isResidualInhibitionEnabled =
        this.state.reducers.reducers.medical &&
        this.state.reducers.reducers.medical.residualInhibitionEnabled;

      return (
        <div className="col-md-3">
          <h6 className="card-body-title tx-12 mg-b-10">
            {programName === "day" ? "PROGRAMME THÉRAPEUTIQUE" : "DIAGNOSTIC"}
          </h6>
          {programName === "day" ? (
            <div className="program">
              <div className="header">
                <div className="day">
                  Jour{" "}
                  {(progression.therapy && progression.therapy.dayCount) || 0}
                </div>
                <div className="time">{dayDuration} min./15 min.</div>
              </div>
              <div className="tasks">
                <ul>
                  <li className="task">
                    <span className="title">THI</span>
                    <span className="duration">&nbsp;</span>
                  </li>
                  <li className="task">
                    <span className="title">WFU</span>
                    <span className="duration">&nbsp;</span>
                  </li>
                  <li className="task">
                    <span className="title">Lire un encouragement</span>
                    <span className="duration">&nbsp;</span>
                  </li>
                </ul>
                -
                <ul>
                  {Object.keys(dayDosage).map((key) => {
                    return (
                      <li className="task">
                        <span className="title">{therapies[key]}</span>
                        <span className="duration">
                          {Math.floor(
                            Math.min(
                              dayDosage[key].progression,
                              dayDosage[key].objective
                            ) / 60
                          )}
                          /{dayDosage[key].objective / 60} min.
                        </span>
                      </li>
                    );
                  })}
                </ul>
                -
                <ul>
                  <li className="task">
                    <span className="title">Masquage</span>
                    <span className="duration">&nbsp;</span>
                  </li>
                  {Object.keys(therapies).map((key) => {
                    if (!dayDosage[key]) {
                      return (
                        <li className="task">
                          <span className="title">{therapies[key]}</span>
                          <span className="duration">
                            {key === "residualInhibition" &&
                            !isResidualInhibitionEnabled
                              ? `🔒`
                              : "0 min."}
                          </span>
                        </li>
                      );
                    }
                  })}
                  <li className="task">
                    <span className="title">Écrire un encouragement</span>
                    <span className="duration">&nbsp;</span>
                  </li>
                </ul>
              </div>
            </div>
          ) : null}
        </div>
      );
    }
  };

  renderWfuRadar1 = () => {
    if (this.wfus && this.state.selectedWfu2) {
      let lastWFUValues = [-1, -1, -1, -1, -1, -1, -1, -1];
      let firstWFUValues = [-1, -1, -1, -1, -1, -1, -1, -1];
      let lastWFU = null;
      let firstWFU = null;
      lastWFU = this.wfus[this.state.selectedWfu2 - 1];
      lastWFUValues = Object.values(lastWFU.subScores);
      if (this.wfus.length > 1 && this.state.selectedWfu1) {
        firstWFU = this.wfus[this.state.selectedWfu1 - 1];
        firstWFUValues = Object.values(firstWFU.subScores);
      }

      return (
        <div className="col-md-6">
          {this.renderWFUTitle1()}
          <div className="wfus">
            <Radar values1={lastWFUValues} values2={firstWFUValues} />
            {lastWFU ? (
              <div style={{ color: "blue" }}>
                WFU #{this.state.selectedWfu2}&nbsp;
                {moment(lastWFU.date, "X").fromNow()} (
                {moment(lastWFU.date, "X").format("ddd D MMM YYYY à HH:mm")}) :{" "}
                <strong>{80 - lastWFU.score} points de confort</strong>
              </div>
            ) : null}
            {firstWFU ? (
              <div style={{ color: "#F60" }}>
                WFU #{this.state.selectedWfu1}{" "}
                {moment(firstWFU.date, "X").fromNow()} (
                {moment(firstWFU.date, "X").format("ddd D MMM YYYY à HH:mm")}) :{" "}
                <strong>{80 - firstWFU.score} points de confort</strong>
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  };

  renderWFUTitle1 = () => {
    if (this.wfus.length > 1) {
      return (
        <h6 className="card-body-title tx-12 mg-b-5 mg-t-15">
          COMPARAISON DES WFU ENTRE LE&nbsp;
          <select onChange={this.onWFU1Select} value={this.state.selectedWfu1}>
            {this.wfus.map((wfu, index) => (
              <option value={index + 1}>
                #{index + 1} ({80 - wfu.score} pts)
              </option>
            ))}
          </select>{" "}
          ET LE&nbsp;
          <select onChange={this.onWFU2Select} value={this.state.selectedWfu2}>
            {this.wfus.map((wfu, index) => (
              <option value={index + 1}>
                #{index + 1} - {80 - wfu.score} pts
              </option>
            ))}
          </select>
        </h6>
      );
    }
    return <h6 className="card-body-title tx-12 mg-b-5 mg-t-15">WFU</h6>;
  };

  renderWfuRadar2 = () => {
    if (this.wfus && this.wfus.length > 0) {
      const currentWFU = this.wfus[this.wfus.length - 1];
      let currentWFUValues = Object.values(currentWFU.subScores);

      let worstWFUValues = [-1, -1, -1, -1, -1, -1, -1, -1];
      let bestWFUValues = [-1, -1, -1, -1, -1, -1, -1, -1];

      let bestWFU = currentWFU;
      let worstWFU = currentWFU;

      if (this.wfus.length > 1) {
        for (let i = this.wfus.length - 2; i >= 0; --i) {
          const wfu = this.wfus[i];
          if (wfu.score !== 40) {
            if (wfu.score < bestWFU.score) {
              bestWFU = wfu;
            } else if (wfu.score > worstWFU.score) {
              worstWFU = wfu;
            }
          }
        }
        bestWFUValues = Object.values(bestWFU.subScores);
        worstWFUValues = Object.values(worstWFU.subScores);
      }

      return (
        <div className="col-md-6">
          <h6 className="card-body-title tx-12 mg-b-5 mg-t-15">
            COMPARAISON DES WFU COURANT/MEILLEUR/PIRE (au score)
          </h6>
          <div className="wfus">
            <Radar
              values1={currentWFUValues}
              values2={bestWFUValues}
              values3={worstWFUValues}
            />
            {currentWFU ? (
              <div style={{ color: "#00C" }}>
                {moment(currentWFU.date, "X").fromNow()} (
                {moment(currentWFU.date, "X").format("ddd D MMM YYYY à HH:mm")})
                : <strong>{80 - currentWFU.score} points de confort</strong>
              </div>
            ) : null}
            {bestWFU ? (
              <div style={{ color: "#080" }}>
                {moment(bestWFU.date, "X").fromNow()} (
                {moment(bestWFU.date, "X").format("ddd D MMM YYYY à HH:mm")}) :{" "}
                <strong>{80 - bestWFU.score} points de confort</strong>
              </div>
            ) : null}
            {worstWFU ? (
              <div style={{ color: "#C00" }}>
                {moment(worstWFU.date, "X").fromNow()} (
                {moment(worstWFU.date, "X").format("ddd D MMM YYYY à HH:mm")}) :{" "}
                <strong>{80 - worstWFU.score} points de confort</strong>
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  };

  renderEventsCard = () => {
    return (
      <div
        className="card pd-20 pd-sm-20"
        style={{ backgroundColor: "#d8fed2" }}
      >
        <h6 className="card-body-title">
          ÉVÈNEMENTS ({this.state.events.length}) -&nbsp;
          <button
            onClick={() => {
              this.setState({ showEventListRaw: !this.state.showEventListRaw });
            }}
          >
            Afficher le JSON
          </button>
        </h6>
        <div className="table-wrapper bg-gray">
          <div className="row row-sm">
            <div className="col-xl-12">
              {this.state.showEventListRaw ? (
                <JSONPretty json={this.state.events} />
              ) : (
                this.renderEvents(this.state.events)
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderSpecialEventsCard = () => {
    const events = this.state.events.filter((ev) => {
      return !(
        ev.type.indexOf("ACTIVITY_") === 0 ||
        ev.type.indexOf("LOG_") === 0 ||
        ev.type.indexOf("SETTING_") === 0 ||
        ev.type === "ENCOURAGEMENT_FETCH" ||
        ev.type.indexOf("USER_LOG") === 0
      );
    });
    return (
      <div className="card pd-20 pd-sm-20" style={{ backgroundColor: "#ffc" }}>
        <h6 className="card-body-title">
          ÉVÈNEMENTS REMARQUABLES ({events.length}) -&nbsp;
          <button
            onClick={() => {
              this.setState({ showEventListRaw: !this.state.showEventListRaw });
            }}
          >
            Afficher le JSON
          </button>
        </h6>
        <div className="table-wrapper bg-gray">
          <div className="row row-sm">
            <div className="col-xl-12">
              {this.state.showEventListRaw ? (
                <JSONPretty json={events} />
              ) : (
                this.renderEvents(events)
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderEvents = (events) => {
    return events.map((event) => {
      if (event.data && event.data.points) {
        event.data.points = {
          comment: "valeur présente mais cachée dans le backoffice",
        };
      }
      return (
        <div
          className={`col-xl-12 event ${
            event.type.toLowerCase().split("_")[0]
          }`}
          key={event.id}
        >
          <span className="eventAnchor" id={`event${event.id}`}></span>
          <header>
            <div>
              <span>
                {moment(event.date, "X").format("YYYY-MM-DD HH:mm:ss")}
              </span>
              <span>{event.type}</span>
              <span>
                {event.data && event.data.activity}
                {event.data && event.data.program}
              </span>
            </div>
            <div>
              <span>
                <a href={`#event${event.id}`}>#{event.id}</a>
              </span>
              <span>{this.renderProgramName(event.data)}</span>
            </div>
            <div>
              <span>
                {this.renderOS(event.os && event.os.type)}{" "}
                {event.os && event.os.version}
              </span>
              <span>{event.version ? `v${event.version}` : "worker"}</span>
              <span>{event.language}</span>
            </div>
          </header>
          {this.renderJson({ ...event.data })}
        </div>
      );
    });
  };

  render() {
    return (
      <Layout>
        <nav className="breadcrumb sl-breadcrumb">
          <a className="breadcrumb-item" href="/dashboard">
            Dashboard
          </a>
          <span className="breadcrumb-item active">
            Fiche utilisateur {this.state.email} ({this.state.userId})
          </span>
        </nav>

        <div className="sl-pagebody">
          <div className="row row-sm">
            <div className="col-md-6">{this.renderExternalCard()}</div>
            <div className="col-md-3">{this.renderActionsCard()}</div>
            <div className="col-md-3">{this.renderUIDCard()}</div>
          </div>

          {this.renderActionForms()}

          <div className="row row-sm">
            <div className="col-md-12">{this.renderReducersCard()}</div>
            <div className="col-md-6">{this.renderEventsCard()}</div>
            <div className="col-md-6">{this.renderSpecialEventsCard()}</div>
          </div>
        </div>
      </Layout>
    );
  }

  renderJson(json) {
    if (Object.keys(json).length) {
      return <JSONPretty json={json}></JSONPretty>;
    }
    return null;
  }

  renderProgramName(eventData) {
    if (!eventData) {
      return null;
    }
    if (eventData.referrer) {
      return eventData.referrer.name;
    }
    return eventData.program;
  }

  renderOS(os) {
    switch (os) {
      case "android":
        return "Android";
      case "ios":
        return "iOS";
      default:
        return os;
    }
  }
}

function countryLabel(iso) {
  if (countries[iso]) {
    return countries[iso];
  }
  return iso;
}

function copyToClipboard(data) {
  var $body = document.getElementsByTagName("body")[0];
  var $tempInput = document.createElement("INPUT");
  $body.appendChild($tempInput);
  $tempInput.setAttribute("value", data);
  $tempInput.select();
  document.execCommand("copy");
  $body.removeChild($tempInput);
}

export default Dashboard;
