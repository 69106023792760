/* eslint-disable array-callback-return */
import React, { Component } from "react";

import API from "../utils/API";
import { DatePicker } from "antd";
import JSONPretty from "react-json-pretty";
import Layout from "../components/Layout";
import { Pie } from "@nivo/pie";
import { ResponsiveLine } from "@nivo/line";
import Select from "react-select";
import moment from "moment";

const { RangePicker } = DatePicker;

const allLanguages = [
  { value: "fr", label: "Français" },
  { value: "en", label: "Anglais" },
  { value: "de", label: "Allemand" },
  { value: "es", label: "Espagnol" },
];

const allCountries = [
  { value: "FR", label: "France" },
  { value: "CA", label: "Canada" },
  { value: "DE", label: "Belgique" },
  { value: "CH", label: "Suisse" },
  { value: "US", label: "USA" },
  { value: "GB", label: "Royaume-Uni" },
  { value: "DZ", label: "Algérie" },
  { value: "MA", label: "Maroc" },
  { value: "IL", label: "Israël" },
];

const eventSubTypes = {
  activity: [
    { value: "*", label: "- Toutes les activités -" },
    { value: "lullaby", label: "Berceuse" },
    { value: "constellations", label: "Constellations" },
    { value: "plinko", label: "Plinko" },
    { value: "simons", label: "Bulles de mémoire" },
    { value: "theIsland", label: "L'île" },
    { value: "associationSort", label: "Méli-Mélo" },
    { value: "lostRecipe", label: "Recette perdue" },
    { value: "toyFactory", label: "Usine à jouet" },
    { value: "tempo", label: "Tempo" },
    { value: "catchThem", label: "Attrapez-les !" },
    { value: "keepTheShape", label: "Garde la forme" },
    { value: "smoothieBar", label: "Bar à smoothies" },
    { value: "coherentBreathing", label: "Inspirations" },
    { value: "squareBreathing", label: "Respiration carrée" },
    { value: "abdominalBreathing", label: "Respiration abdo" },
    { value: "whiteNoise", label: "Bruit blanc" },
    { value: "acouphenometry", label: "Acouphénométrie" },
    { value: "residualInhibitionDiagnosis", label: "Diag IR" },
    { value: "evaluation", label: "Questionnaires" },
    { value: "evaluation.wfu", label: "> Questionnaire WFU" },
    { value: "evaluation.thi", label: "> Questionnaire THI" },
    { value: "evaluation.hads", label: "> Questionnaire HADS" },
    { value: "evaluation.isi", label: "> Questionnaire ISI" },
    { value: "capsules", label: "Capsules" },
    { value: "capsules.introduction", label: "> Capsule (Présentation)" },
    { value: "capsules.therapies", label: "> Capsule (Thérapies)" },
    {
      value: "capsules.nonTherapeuticApproaches",
      label: "> Capsule (Approches)",
    },
    { value: "capsules.diagnosisAndFollowUp", label: "> Capsule (Diag&Suivi)" },
    { value: "capsules.songOfTheBird", label: "> Capsule (Oiseau)" },
    { value: "capsules.snailInTheEar", label: "> Capsule (Escargot)" },
    { value: "capsules.anitaSmiles", label: "> Capsule (Anita)" },
  ],
  logAction: [
    { value: "*", label: "- Toutes les actions -" },
    { value: "BUY_BUTTON", label: "BUY_BUTTON" },
    { value: "FAQ_QUESTION", label: "FAQ_QUESTION" },
    { value: "IAP_BUY_TRANSACTION", label: "IAP_BUY_TRANSACTION" },
    { value: "IAP_BUY_ATTEMPT", label: "IAP_BUY_ATTEMPT" },
    { value: "IAP_BUY_SLOW_TRANSACTION", label: "IAP_BUY_SLOW_TRANSACTION" },
    { value: "IAP_CANCEL", label: "IAP_CANCEL" },
    { value: "IAP_FAILURE", label: "IAP_FAILURE" },
    { value: "IAP_SUCCESS", label: "IAP_SUCCESS" },
    {
      value: "CONFIGURATION_DO_NOT_SEND_TINNITUS_CHECKUP_EMAIL",
      label: "CONFIG_NO_EMAIL",
    },
  ],
};

const allEventTypes = [
  { value: "ACTIVITY_START", label: "ACTIVITY_START" },
  { value: "ACTIVITY_CANCEL", label: "ACTIVITY_CANCEL" },
  { value: "ACTIVITY_COMPLETE", label: "ACTIVITY_COMPLETE" },
  {
    value: "EMAIL_TINNITUS_CHECKUP_SENT",
    label: "EMAIL_TINNITUS_CHECKUP_SENT",
  },
  { value: "ENCOURAGEMENT_FETCH", label: "ENCOURAGEMENT_FETCH" },
  { value: "ENCOURAGEMENT_SHARE", label: "ENCOURAGEMENT_SHARE" },
  { value: "ENCOURAGEMENT_THANK", label: "ENCOURAGEMENT_THANK" },
  { value: "ENCOURAGEMENT_VIEW", label: "ENCOURAGEMENT_VIEW" },
  { value: "LICENSE_CREATE", label: "LICENSE_CREATE" },
  { value: "LICENSE_REFUND", label: "LICENSE_REFUND" },
  { value: "LICENSE_REQUEST", label: "LICENSE_REQUEST" },
  { value: "LOG_ACTION", label: "LOG_ACTION" },
  { value: "LOG_ALARM", label: "LOG_ALARM" },
  { value: "LOG_NAVIGATE", label: "LOG_NAVIGATE" },
  { value: "NOTIFICATION_OPEN", label: "NOTIFICATION_OPEN" },
  { value: "PROFESSIONAL_ADD", label: "PROFESSIONAL_ADD" },
  { value: "PROFESSIONAL_CALL", label: "PROFESSIONAL_CALL" },
  { value: "PROFESSIONAL_EDIT", label: "PROFESSIONAL_EDIT" },
  { value: "PROGRAM_CANCEL", label: "PROGRAM_CANCEL" },
  { value: "PROGRAM_COMPLETE", label: "PROGRAM_COMPLETE" },
  { value: "PROGRAM_POSOLOGY", label: "PROGRAM_POSOLOGY" },
  { value: "PROGRAM_START", label: "PROGRAM_START" },
  { value: "RATING_NPS", label: "RATING_NPS" },
  { value: "RATING_STORE", label: "RATING_STORE" },
  { value: "RATING_SURVEY", label: "RATING_SURVEY" },
  { value: "SETTING_EDIT", label: "SETTING_EDIT" },
  { value: "USER_AUTOMATIC_LOGOUT", label: "USER_AUTOMATIC_LOGOUT" },
  { value: "USER_DELETE", label: "USER_DELETE" },
  { value: "USER_EDIT", label: "USER_EDIT" },
  { value: "USER_EMAIL_EDIT_REQUEST", label: "USER_EMAIL_EDIT_REQUEST" },
  { value: "USER_LOGIN", label: "USER_LOGIN" },
  { value: "USER_LOGOUT", label: "USER_LOGOUT" },
  { value: "USER_PASSWORD_RESET", label: "USER_PASSWORD_RESET" },
  {
    value: "USER_REQUEST_PASSWORD_RESET",
    label: "USER_REQUEST_PASSWORD_RESET",
  },
  { value: "USER_SIGNUP", label: "USER_SIGNUP" },
];

class StatsEventType extends Component {
  state = {
    kpis: {},
    eventSummary: [],
    events: [],
    from: moment().startOf("day").subtract(3, "month"),
    to: moment().endOf("day"),
    step: "week",
  };

  async componentDidMount() {
    this.api = new API();
    this.reset();
  }

  reset = () => {
    this.setState({ kpis: {} }, () => {
      this.addKPIForm();
    });
  };

  submitSearch = async (e) => {
    if (e) {
      e.preventDefault();
    }
    const langCriterias = {};
    const eventSummary = await Promise.all(
      Object.keys(this.state.kpis).map(async (kpiKey) => {
        const kpi = this.state.kpis[kpiKey];

        const langCriteria = [];
        if (kpi.languages.length > 0 || kpi.countries.length > 0) {
          const languages = kpi.languages.length
            ? [...kpi.languages.map((item) => item.value)]
            : [".*"];
          const countries = kpi.countries.length
            ? [...kpi.countries.map((item) => item.value)]
            : [".*"];

          languages.map((lang) => {
            countries.map((country) => {
              langCriteria.push(`${lang}-${country}`);
            });
          });
        }
        // Sauvegarde de langCriteria pour les pies
        langCriterias[kpiKey] = langCriteria;

        let subCriteria = {};
        if (kpi.subTypes && kpi.subTypes.value !== "*") {
          const subTypeKey = this.getSubTypeKey(kpi.eventType.value);
          if (subTypeKey === "activity") {
            const [activity, name] = kpi.subTypes.value.split(".");
            subCriteria = { "data.activity": activity };
            if (name) {
              subCriteria["data.name"] = name;
            }
          } else {
            subCriteria = { "data.name": kpi.subTypes.value };
          }
        }

        return {
          id: `${kpiKey}. ${kpi.eventType.value}`,
          kpiKey: kpiKey,
          data: (
            await this.api.getStatsEventGroups(
              this.state.from.format("YYYY-MM-DD"),
              this.state.to.format("YYYY-MM-DD"),
              this.state.step,
              {
                type: kpi.eventType.value,
                ...subCriteria,
                ...(langCriteria.length > 0 && {
                  language: { $in: langCriteria },
                }),
              }
            )
          ).map((raw) => {
            if (this.state.step === "week") {
              const [year, week] = raw._id.split(" ");
              if (week) {
                return {
                  x: moment()
                    .year(year)
                    .isoWeek(week)
                    .day("Monday")
                    .add(1, "day")
                    .format("Y-MM-DD"),
                  y: raw.total,
                  android: raw.android,
                  ios: raw.ios,
                };
              }
            }
            return {
              x: raw._id,
              y: raw.total,
              android: raw.android,
              ios: raw.ios,
            };
          }),
        };
      })
    );
    this.setState({ eventSummary }, () => {
      Object.keys(this.state.kpis).map(async (kpiKey) => {
        const kpi = this.state.kpis[kpiKey];

        const langCriteria = langCriterias[kpiKey] || [];
        const langData = await this.buildData("lang", kpi, langCriteria);
        const countryData = await this.buildData("country", kpi, langCriteria);
        const osData = this.buildOSData(kpiKey);

        this.setState({
          kpis: {
            ...this.state.kpis,
            [kpiKey]: {
              ...this.state.kpis[kpiKey],
              langData,
              countryData,
              osData,
            },
          },
        });
      });
    });
  };

  // Pour les pies
  buildData = async (dataType, kpi, langCriteria) => {
    const rawData = await this.api.getStatsEventGroups(
      this.state.from.format("YYYY-MM-DD"),
      this.state.to.format("YYYY-MM-DD"),
      this.state.step,
      {
        type: kpi.eventType.value,
        ...(langCriteria.length > 0 && {
          language: { $in: langCriteria },
        }),
      },
      dataType
    );

    const data = rawData.map((raw) => {
      return {
        id: raw._id || "null",
        label: raw._id || "null",
        value: raw.total,
      };
    });

    return data;
  };

  buildOSData = (kpiKey) => {
    console.warn(
      "eventSummary",
      kpiKey,
      this.state.eventSummary,
      this.state.eventSummary[kpiKey]
    );

    let android = 0;
    let ios = 0;
    this.state.eventSummary
      .filter((item) => item.kpiKey === kpiKey)[0]
      .data.map((raw) => {
        if (raw.android) {
          android += raw.android;
        }
        if (raw.ios) {
          ios += raw.ios;
        }
      });

    return [
      {
        id: "android",
        label: "Android",
        value: android,
      },
      {
        id: "ios",
        label: "iOS",
        value: ios,
      },
    ];
  };

  renderForm = () => {
    return (
      <div className="card pd-20 mg-b-20">
        <h6 className="card-body-title">
          Statistiques pour un type d'évènement
        </h6>
        <form className="form-layout" onSubmit={this.submitForm}>
          <div className="row">
            <div className="col-lg-12 card-head">
              <RangePicker
                defaultValue={[this.state.from, this.state.to]}
                allowClear={false}
                format="D MMM YYYY"
                ranges={{
                  "Toute la période": [moment("2018-10-10"), moment()],
                  "Les 14 derniers mois": [
                    moment().startOf("month").subtract(13, "months"),
                    moment(),
                  ],
                  "Les 12 derniers mois complets": [
                    moment().startOf("month").subtract(12, "months"),
                    moment().endOf("month").subtract(1, "month"),
                  ],
                  "Les 6 derniers mois": [
                    moment().startOf("month").subtract(6, "months"),
                    moment(),
                  ],
                  "Les 6 derniers mois complets": [
                    moment().startOf("month").subtract(6, "months"),
                    moment().endOf("month").subtract(1, "month"),
                  ],
                  "Les 3 derniers mois": [
                    moment().startOf("month").subtract(3, "months"),
                    moment(),
                  ],
                  "Les 3 derniers mois complets": [
                    moment().startOf("month").subtract(3, "months"),
                    moment().endOf("month").subtract(1, "month"),
                  ],
                }}
                onChange={(dates) => {
                  this.setState({
                    from: dates[0],
                    to: dates[1],
                    eventSummary: [],
                  });
                }}
              />

              <select
                className="step-select"
                defaultValue="week"
                onChange={(event) => {
                  this.setState({ step: event.target.value, eventSummary: [] });
                }}
              >
                <option value="day">par jour</option>
                <option value="week">par semaine</option>
                <option value="month">par mois</option>
                <option value="all">par siècle</option>
              </select>

              <button
                className="btn btn-warn mg-l-10"
                onClick={(e) => {
                  e.preventDefault();
                  if (window.confirm("reset ?")) {
                    this.reset();
                  }
                }}
              >
                Reset
              </button>

              <button
                className="btn btn-info mg-l-10"
                onClick={this.addKPIForm}
              >
                Ajouter une métrique
              </button>

              <button
                className="btn btn-success mg-l-10"
                onClick={this.submitSearch}
              >
                Rechercher
              </button>
            </div>
          </div>
          {Object.keys(this.state.kpis).map((kpiKey) => {
            return (
              <div className="row mg-t-10" key={kpiKey}>
                <div className="col-lg-12 card-head">
                  <code className="kpiKey">{kpiKey}.</code>&nbsp;
                  <Select
                    className="minWidth100"
                    options={allEventTypes}
                    value={this.state.kpis[kpiKey].eventType}
                    onChange={(value) => {
                      let subTypeChoices = [];
                      const subTypeKey = this.getSubTypeKey(value.value);
                      if (subTypeKey) {
                        subTypeChoices = eventSubTypes[subTypeKey];
                      }
                      this.setState({
                        kpis: {
                          ...this.state.kpis,
                          [kpiKey]: {
                            ...this.state.kpis[kpiKey],
                            subTypes: subTypeChoices[0],
                            subTypeChoices,
                            eventType: value,
                          },
                        },
                      });
                    }}
                    placeholder="Indicateur"
                  />
                  {this.state.kpis[kpiKey].subTypeChoices.length > 0 && (
                    <Select
                      className="mg-l-10 minWidth100"
                      options={this.state.kpis[kpiKey].subTypeChoices}
                      value={this.state.kpis[kpiKey].subTypes}
                      onChange={(value) => {
                        this.setState({
                          kpis: {
                            ...this.state.kpis,
                            [kpiKey]: {
                              ...this.state.kpis[kpiKey],
                              subTypes: value,
                            },
                          },
                        });
                      }}
                      placeholder="Sous-indicateur"
                    />
                  )}
                  <Select
                    className="mg-l-10 mg-r-10 minWidth60"
                    isMulti
                    options={allLanguages}
                    value={this.state.kpis[kpiKey].languages}
                    onChange={(value) => {
                      this.setState({
                        kpis: {
                          ...this.state.kpis,
                          [kpiKey]: {
                            ...this.state.kpis[kpiKey],
                            languages: value,
                          },
                        },
                      });
                    }}
                    placeholder="Toutes les langues"
                  />
                  <Select
                    className="minWidth60"
                    isMulti
                    options={allCountries}
                    value={this.state.kpis[kpiKey].countries}
                    onChange={(value) => {
                      this.setState({
                        kpis: {
                          ...this.state.kpis,
                          [kpiKey]: {
                            ...this.state.kpis[kpiKey],
                            countries: value,
                          },
                        },
                      });
                    }}
                    placeholder="Tous les pays"
                  />
                </div>
              </div>
            );
          })}
        </form>
      </div>
    );
  };

  getSubTypeKey = (kpiName) => {
    if (kpiName.indexOf("ACTIVITY_") === 0) {
      return "activity";
    } else if (kpiName === "LOG_ACTION") {
      return "logAction";
    }
    return null;
  };

  addKPIForm = (e) => {
    if (e) {
      e.preventDefault();
    }
    const index = Object.keys(this.state.kpis).length + 1;
    if (index > 3) {
      alert("Pas plus de 3 indicateurs par page.");
    } else {
      const eventType = allEventTypes[~~(Math.random() * allEventTypes.length)];
      let subTypeChoices = [];
      let subTypes = null;
      const subTypeKey = this.getSubTypeKey(eventType.value);
      if (subTypeKey) {
        subTypeChoices = eventSubTypes[subTypeKey];
        subTypes = subTypeChoices[0];
      }

      this.setState({
        kpis: {
          ...this.state.kpis,
          [index]: {
            eventType,
            subTypeChoices,
            subTypes,
            languages: [],
            countries: [],
            timeData: [],
            langData: [],
            countryData: [],
            osData: [],
          },
        },
      });
    }
  };

  renderPie = (dataType, kpiKey, label) => {
    const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
      return (
        <>
          <text
            x={centerX}
            y={centerY - 15}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
              fontSize: "12pt",
              fontWeight: "300",
            }}
          >
            {kpiKey}.
          </text>
          <text
            x={centerX}
            y={centerY + 7}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
              fontSize: "12pt",
              fontWeight: "300",
            }}
          >
            {label}
          </text>
        </>
      );
    };

    console.warn(
      "this.state.kpis[kpiKey][`${dataType}Data`]",
      this.state.kpis[kpiKey][`${dataType}Data`]
    );
    let total = this.state.kpis[kpiKey][`${dataType}Data`].reduce(
      (sum, item) => {
        if (item.value) {
          sum += item.value;
          console.warn("kdsfjnsdlk", item.value, sum);
        }
        return sum;
      },
      0
    );

    if (total === 0) {
      total = 1;
    }
    console.warn("TOTAL", total);

    return (
      <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
        <Pie
          width={320}
          height={320}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          data={this.state.kpis[kpiKey][`${dataType}Data`]}
          animate={true}
          innerRadius={0.6}
          enableSliceLabels={true}
          sliceLabel={(item) => {
            const percentage = Math.round((item.value * 100) / total);
            if (percentage < 3) {
              return "";
            } else if (percentage < 5) {
              return percentage;
            }
            return percentage + "%";
          }}
          layers={["slices", "sliceLabels", CenteredMetric]}
        />
      </div>
    );
  };

  renderCharts = () => {
    return (
      <>
        {this.state.step !== "all" && this.renderLineChart()}
        {Object.keys(this.state.kpis).map((kpiKey) => {
          return (
            <div className="row mg-t-10">
              <div className="col-md-4">
                {this.renderPie("lang", kpiKey, "Langues")}
              </div>
              <div className="col-md-4">
                {this.renderPie("country", kpiKey, "Pays")}
              </div>
              <div className="col-md-4">
                {this.renderPie("os", kpiKey, "OS")}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  renderLineChart = () => {
    let xScaleFormat = "%Y-%m-%d";
    let xScalePrecision = "day";
    let xFormat = "time:%Y-%m-%d";
    let axisBottom = {
      format: "%d %b",
      tickValues: "every monday",
    };
    if (this.state.step === "month") {
      xScaleFormat = "%Y-%m";
      xScalePrecision = "month";
      xFormat = "time:%Y-%m";
      axisBottom = {
        format: "%b '%y",
        tickValues: "every month",
      };
    }

    return (
      <div className="table-wrapper bg-gray" style={{ height: "320px" }}>
        <ResponsiveLine
          margin={{ top: 10, right: 80, bottom: 70, left: 35 }}
          data={this.state.eventSummary}
          xScale={{
            type: "time",
            format: xScaleFormat,
            precision: xScalePrecision,
          }}
          xFormat={xFormat}
          yScale={{
            type: "linear",
          }}
          axisLeft={{ enabled: true }}
          axisRight={{ enabled: true }}
          axisBottom={axisBottom}
          enablePointLabel={false}
          pointSize={4}
          pointBorderWidth={1}
          pointBorderColor={{
            from: "color",
            modifiers: [["darker", 0.3]],
          }}
          useMesh={true}
          enableSlices={false}
          onClick={(point, event) => {
            console.warn("click", point, event);
          }}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 49,
              itemWidth: 200,
              itemHeight: 10,
              itemsSpacing: 4,
              symbolSize: 5,
              symbolShape: "circle",
              itemDirection: "left-to-right",
              itemTextColor: "#777",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    );
  };

  render() {
    return (
      <Layout>
        <nav className="breadcrumb sl-breadcrumb">
          <a className="breadcrumb-item" href="/dashboard">
            Dashboard
          </a>
          <span className="breadcrumb-item active">
            Stats par type d'évènement
          </span>
        </nav>

        <div className="sl-pagebody">
          <div className="row row-sm">
            <div className="col-md-12">{this.renderForm()}</div>
          </div>

          <div className="row row-sm">
            <div className="col-xl-12">{this.renderCharts()}</div>
          </div>

          {/*<div className="row row-sm mg-t-20">
            <div className="col-md-12">{this.renderEventsCard()}</div>
    </div>*/}
        </div>
      </Layout>
    );
  }

  renderEventsCard = () => {
    return (
      <div
        className="card pd-20 pd-sm-20"
        style={{ backgroundColor: "#d8fed2" }}
      >
        <h6 className="card-body-title">
          ÉVÈNEMENTS ({this.state.events.length}) -&nbsp;
          <button
            onClick={() => {
              this.setState({ showEventListRaw: !this.state.showEventListRaw });
            }}
          >
            Afficher le JSON
          </button>
        </h6>
        <div className="table-wrapper bg-gray">
          <div className="row row-sm">
            <div className="col-xl-12">
              {this.state.showEventListRaw ? (
                <JSONPretty json={this.state.events} />
              ) : (
                this.renderEvents(this.state.events)
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderEvents = (events) => {
    return events.map((event) => {
      if (event.data && event.data.points) {
        event.data.points = {
          comment: "valeur présente mais cachée dans le backoffice",
        };
      }
      return (
        <div
          className={`col-xl-12 event ${
            event.type.toLowerCase().split("_")[0]
          }`}
          key={event.id}
        >
          <span className="eventAnchor" id={`event${event.id}`}></span>
          <header>
            <div>
              <span>
                {moment(event.date, "X").format("YYYY-MM-DD HH:mm:ss")}
              </span>
              <span>{event.type}</span>
              <span>
                {event.data && event.data.activity}
                {event.data && event.data.program}
              </span>
            </div>
            <div>
              <span>
                <a href={`#event${event.id}`}>#{event.id}</a>
              </span>
              <span>{this.renderProgramName(event.data)}</span>
            </div>
            <div>
              <span>
                {this.renderOS(event.os && event.os.type)}{" "}
                {event.os && event.os.version}
              </span>
              <span>{event.version ? `v${event.version}` : "worker"}</span>
              <span>{event.language}</span>
            </div>
          </header>
          {this.renderJson({ ...event.data })}
        </div>
      );
    });
  };

  renderJson(json) {
    if (Object.keys(json).length) {
      return <JSONPretty json={json}></JSONPretty>;
    }
    return null;
  }

  renderProgramName(eventData) {
    if (!eventData) {
      return null;
    }
    if (eventData.referrer) {
      return eventData.referrer.name;
    }
    return eventData.program;
  }

  renderOS(os) {
    switch (os) {
      case "android":
        return "Android";
      case "ios":
        return "iOS";
      default:
        return os;
    }
  }
}

export default StatsEventType;
