import React, { Component } from "react";

import API from "../utils/API";
import Layout from "../components/Layout";
import ReactTable from "react-table";
import moment from "moment";

const newArticle = {
  id: 0,
  date: moment().format("YYYY-MM-DD"),
  source: "",
  lang: "fr",
  title: "",
  snippet: "",
  url: "",
};

class ArticlesScreen extends Component {
  constructor() {
    super();
    this.api = new API();
    this.cols = [
      {
        id: "date",
        Header: "Date",
        filterable: false,
        sortable: false,
        accessor: (u) => (u.date ? moment(u.date).format("YYYY-MM-DD") : ""),
        width: 150,
      },
      {
        id: "lang",
        Header: "Lang",
        accessor: (u) => (u.lang === "fr" ? "fr-FR" : "en-GB"),
        maxWidth: 55,
        filterable: false,
        sortable: false,
        Cell: (row) => (
          <div className={`flag ${row.value}`}>
            <span>{row.value}</span>
          </div>
        ),
      },
      {
        id: "action",
        Header: "-",
        filterable: false,
        sortable: false,
        width: 25,
        Cell: (row) => (
          <a href={`/user?id=${row.row._original.id}`}>
            <i className="fa fa-eye"></i>
          </a>
        ),
      },
      {
        id: "title",
        Header: "Titre",
        filterable: false,
        sortable: false,
        Cell: (row) => (
          <span dangerouslySetInnerHTML={{ __html: row.row._original.title }} />
        ),
      },
      {
        id: "snippet",
        Header: "Description",
        filterable: false,
        sortable: false,
        Cell: (row) => (
          <span
            dangerouslySetInnerHTML={{
              __html: row.row._original.snippet.replace(/\n/g, "<br />"),
            }}
          />
        ),
      },
      {
        id: "source",
        Header: "Source",
        filterable: false,
        sortable: false,
        accessor: (u) => u.source,
      },
      {
        id: "action",
        Header: "-",
        filterable: false,
        sortable: false,
        width: 25,
        Cell: (row) => (
          <a
            href={row.row._original.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fa fa-eye"></i>
          </a>
        ),
      },
      {
        id: "mongo",
        Header: "Mongo",
        sortable: false,
        filterable: false,
        width: 58,
        Cell: (row) => (
          <div className="exportContainer">
            <button
              onClick={() =>
                copyToClipboard(
                  `db.getCollection('articles').find({_id:ObjectId('${row.row._original._id}')})`
                )
              }
              title={row.row._original.id}
              className="fa fa-copy"
            />
          </div>
        ),
        filterAll: true,
      },
    ];
  }

  state = {
    new: { ...newArticle },
    articles: [],
  };

  componentDidMount() {
    this.updateData();
  }

  async updateData() {
    this.setState({
      new: { ...JSON.parse(JSON.stringify(newArticle)) },
      articles: await this.api.getArticles(),
    });
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name.split(";");

    const newState = { ...this.state };
    if (name.length === 2) {
      newState[name[0]][name[1]] = value;
    } else {
      newState[name[0]][name[1]][name[2]][name[3]] = value;
    }

    this.setState(newState);
  };

  submitForm = async (e) => {
    e.preventDefault();

    if (this.state.new.url.trim()) {
      try {
        await this.api.updateArticles([this.state.new], []);
        // eslint-disable-next-line no-self-assign
        setTimeout(() => (window.location.href = window.location.href), 1000);
      } catch (e) {
        console.warn("Erreur submitForm", e);
        alert(`Problème lors de la mise à jour… ${e}`);
      }
    }
  };

  render() {
    return (
      <Layout>
        <nav className="breadcrumb sl-breadcrumb">
          <a className="breadcrumb-item" href="/dashboard">
            Dashboard
          </a>
          <span className="breadcrumb-item active">Articles</span>
        </nav>
        <div className="sl-pagebody">
          <div className="row row-sm mg-b-20">
            <div className="col-md-12">
              <div className="card pd-t-15 pd-l-20 pd-b-15">
                <h6 className="card-body-title">Nouvel article</h6>
                <div className="row row-sm">
                  <div className="col-md-8">
                    <div className="row row-sm">
                      <div className="col-md-5">
                        <div className="input-group">
                          <span className="input-group-addon" title="Date">
                            <code>Date</code>
                          </span>
                          <input
                            className="form-control"
                            name={`new;date`}
                            type="date"
                            required
                            onChange={this.handleChange}
                            value={this.state.new.date}
                            placeholder="Date (YYYY-MM-DD)"
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="input-group">
                          <span className="input-group-addon" title="Langue">
                            <code>Lang</code>
                          </span>
                          <input
                            className="form-control"
                            name={`new;lang`}
                            type="text"
                            required
                            onChange={this.handleChange}
                            value={this.state.new.lang}
                            placeholder="fr|en"
                          />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="input-group">
                          <span className="input-group-addon" title="URL">
                            <code>URL</code>
                          </span>
                          <input
                            className="form-control"
                            name={`new;url`}
                            type="text"
                            onChange={this.handleChange}
                            value={this.state.new.url}
                            placeholder="https://"
                          />
                        </div>
                      </div>
                    </div>

                    {/** START ANDROID */}

                    <div className="row row-sm mg-t-10">
                      <div className="col-md-12">
                        <div className="input-group">
                          <span className="input-group-addon" title="Titre">
                            <code>Titre.</code>
                          </span>
                          <input
                            className="form-control"
                            name="new;title"
                            onChange={this.handleChange}
                            value={this.state.new.title || ""}
                            placeholder="Titre de l'article"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row row-sm mg-t-1">
                      <div className="col-md-12">
                        <div className="input-group">
                          <span className="input-group-addon" title="Auteur">
                            <code>Source</code>
                          </span>
                          <input
                            className="form-control"
                            name="new;source"
                            onChange={this.handleChange}
                            value={this.state.new.source || ""}
                            placeholder="Source - Auteur"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row row-sm mg-t-10">
                      <div className="col-md-12">
                        <div className="input-group">
                          <span className="input-group-addon" title="Snippet">
                            <code>Descri</code>
                          </span>
                          <textarea
                            className="form-control"
                            style={{ minHeight: 80 }}
                            name="new;snippet"
                            onChange={this.handleChange}
                            value={this.state.new.snippet || ""}
                            placeholder="Description courte"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <h6 className="card-body-title">Aperçu in-app</h6>
                    <div className="preview article">
                      <small>
                        {moment(this.state.new.date).format("DD MMM YYYY")} -{" "}
                        {this.state.new.source}
                      </small>
                      <br />
                      <strong
                        dangerouslySetInnerHTML={{
                          __html: this.state.new.title,
                        }}
                      />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.new.snippet.replace(
                            "\n",
                            "<br />"
                          ),
                        }}
                      />
                      <a
                        href={this.state.new.url}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Lire l'article
                      </a>
                    </div>
                  </div>
                </div>

                <div className="row row-sm mg-t-20">
                  <div className="col-md-12">
                    <div className="form-layout-footer">
                      <button
                        className="btn btn-primary mg-r-5"
                        disabled={
                          ["fr", "en"].indexOf(this.state.new.lang) === -1 ||
                          this.state.new.url === "" ||
                          this.state.new.title === "" ||
                          this.state.new.snippet === ""
                        }
                        onClick={this.submitForm}
                      >
                        Sauvegarder
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-sm">
            <div className="col-md-12">
              <div className="card pd-20 pd-sm-40 mg-b-20">
                <h6 className="card-body-title">Mises à jour</h6>
                <div className="table-wrapper bg-gray">
                  <ReactTable
                    style={{ width: "100%" }}
                    defaultPageSize={100}
                    data={this.state.articles}
                    pages={1}
                    loading={false}
                    columns={this.cols}
                    filterable={true}
                    className="no-filters -striped -highlight"
                    defaultFilterMethod={(filter, row) =>
                      String(row[filter.id]).includes(filter.value)
                    }
                    defaultSorted={[{ id: "date", desc: true }]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function copyToClipboard(data) {
  var $body = document.getElementsByTagName("body")[0];
  var $tempInput = document.createElement("TEXTAREA");
  $body.appendChild($tempInput);
  $tempInput.innerHTML = data;
  $tempInput.select();
  document.execCommand("copy");
  $body.removeChild($tempInput);
}

export default ArticlesScreen;
