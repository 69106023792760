import React, { Component } from "react";

import API from "../utils/API";
import Layout from "../components/Layout";
import Select from "react-select";

class LostRecipe extends Component {
  state = {
    editMode: false,
    recipes: [],
    ingredients: [],
    formRecipe: {
      lang: "fr",
      serving: { quantity: 6, unit: "people" },
      ingredients: [{ list: [] }],
    },
    servingUnitChoices: [
      { value: null, label: "unités" },
      { value: "people", label: "personnes" },
    ],
    ingredientChoices: [],
    ingredientSelectedChoices: [],
    formIngredient: {
      name: "",
      aliasFor: null,
      text: "",
    },
    aliasFor: null,
  };

  constructor() {
    super();
    this.api = new API();
  }

  async componentDidMount() {
    this.refreshRecipesAndIngredients(false);
  }

  refreshRecipesAndIngredients = async (ingredientOnly) => {
    const ingredients = await this.api.getLostRecipeIngredients(
      this.state.formRecipe.lang
    );
    const ingredientChoices = [];
    ingredients.map((ing) => {
      ingredientChoices.push({
        value: ing.name,
        isAlias: ing.aliasFor ? true : false,
        reference: ing.aliasFor ? ing.aliasFor : ing.name,
        label: (ing.textPlural || ing.text).replace("{{count}} ", "") || "",
      });
    });

    ingredientChoices.sort((a, b) =>
      a.label < b.label ? -1 : a.label > b.label ? 1 : 0
    );
    if (ingredientOnly) {
      this.setState({
        ingredients,
        ingredientChoices,
      });
    } else {
      this.setState({
        recipes: await this.api.getLostRecipes(this.state.formRecipe.lang),
        ingredients,
        ingredientChoices,
      });
    }
  };

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      formRecipe: {
        ...this.state.formRecipe,
        [name]: value,
      },
    });
  };

  handleFormIngredientChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      formIngredient: {
        ...this.state.formIngredient,
        [name]: value,
      },
    });
  };

  handleQuantityChange = (event) => {
    const target = event.target;
    const quantity = +target.value;
    const ingIndex = target.name;

    const ingredients = [...this.state.formRecipe.ingredients];
    ingredients[0].list[ingIndex] = {
      name: this.state.formRecipe.ingredients[0].list[ingIndex].name,
      quantity,
    };

    this.setState({
      formRecipe: {
        ...this.state.formRecipe,
        ingredients,
      },
    });
  };

  submitEditForm = async (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    try {
      // api
    } catch (e) {
      console.warn("Erreur submitEditForm", e);
      alert(`Problème avec le formulaire… ${e}`);
    }
  };

  render() {
    return (
      <Layout>
        <nav className="breadcrumb sl-breadcrumb">
          <a className="breadcrumb-item" href="/dashboard">
            Dashboard
          </a>
          <a className="breadcrumb-item">Recettes perdues 🇫🇷</a>
        </nav>
        <div className="sl-pagebody lrecipe">
          <div className="row row-sm mg-b-20">
            <div className="col-md-3">
              <div className="card pd-20 pd-sm-20">
                <h6 className="card-body-title">
                  Liste des recettes&nbsp;-&nbsp;
                  <button
                    onClick={() => {
                      this.setState({
                        formRecipe: {
                          lang: "fr",
                          title: "",
                          code: "",
                          serving: { quantity: 6, unit: "people" },
                          source: "",
                          instructions: "",
                          ingredients: [{ list: [] }],
                        },
                        ingredientSelectedChoices: [],
                        editMode: false,
                      });
                    }}
                  >
                    Nouvelle recette
                  </button>
                </h6>
                <ul className="recipe-list">
                  {this.state.recipes
                    .sort((a, b) => {
                      return a.title < b.title ? -1 : 1;
                    })
                    .map((recipe) => {
                      return (
                        <li
                          key={`li${recipe.code}`}
                          className="recipe-item"
                          onClick={() => {
                            const ingredientSelectedChoices =
                              recipe.ingredients[0].list
                                .map((ing) => {
                                  return this.state.ingredientChoices.find(
                                    (element) => element.value === ing.name
                                  );
                                })
                                .filter((notNull) => !!notNull);
                            this.setState({
                              formRecipe: recipe,
                              editMode: true,
                              ingredientSelectedChoices,
                            });
                          }}
                        >
                          {recipe.status === "published" ? "✅  " : null}
                          {recipe.title}
                        </li>
                      );
                    })}
                </ul>
              </div>

              <div className="card pd-20 pd-sm-20 mg-t-10">
                <h6 className="card-body-title">Créer un ingrédient</h6>
                <div className="form-group">
                  <input
                    className="form-control"
                    name="name"
                    type="text"
                    value={this.state.formIngredient.name}
                    onChange={this.handleFormIngredientChange}
                    placeholder="Code technique en anglais sans espace"
                    title="Code technique en anglais sans espace (exemple: sugarTsp)"
                  />
                </div>
                <div className="form-group">
                  <Select
                    className="select-flex-top"
                    options={this.state.ingredientChoices.filter(
                      (item) => !item.isAlias
                    )}
                    value={this.state.aliasFor}
                    onChange={(value) => {
                      this.setState({
                        aliasFor: value,
                        formIngredient: {
                          ...this.state.formIngredient,
                          aliasFor: value.value,
                        },
                      });
                    }}
                    placeholder="Référence (pour l'image en jeu)"
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    name="text"
                    type="text"
                    value={this.state.formIngredient.text}
                    onChange={this.handleFormIngredientChange}
                    placeholder="Unité d'ingrédient (cl d'eau, càc de sel)"
                    title="Unité d'ingrédient (g de sucre, càc de sel)"
                  />
                </div>

                <button
                  className="btn btn-success-dark mg-r-5"
                  onClick={async () => {
                    try {
                      await this.api.createIngredient(
                        this.state.formIngredient
                      );
                      this.refreshRecipesAndIngredients(true);
                      this.setState({
                        formIngredient: {
                          name: "",
                          aliasFor: null,
                          text: "",
                        },
                      });
                      alert("Ingrédient sauvegardé");
                      this.refreshRecipesAndIngredients(false);
                    } catch (e) {
                      prompt("Problème, ingrédient non sauvegardé", e);
                    }
                  }}
                >
                  Ajouter cet ingrédient
                </button>
              </div>
            </div>
            <div className="col-md-9">
              <div className="card pd-20 pd-sm-20">
                <h6 className="card-body-title">
                  {this.state.editMode
                    ? `Modifier ${this.state.formRecipe.title}`
                    : `Nouvelle recette`}
                </h6>

                <div className="form-group recipe-form">
                  <div className="input-group">
                    <span className="input-group-addon">Libellé</span>
                    <input
                      className="form-control"
                      name="title"
                      type="text"
                      value={this.state.formRecipe.title}
                      onChange={this.handleChange}
                      placeholder="Nom de la recette"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-group">
                    <span className="input-group-addon">Pour combien</span>
                    <input
                      className="form-control"
                      name="servingQuantity"
                      type="text"
                      value={this.state.formRecipe.serving.quantity}
                      placeholder="Quantité"
                      onChange={(event) => {
                        const quantity = +event.target.value;
                        this.setState({
                          formRecipe: {
                            ...this.state.formRecipe,
                            serving: {
                              ...this.state.formRecipe.serving,
                              quantity,
                            },
                          },
                        });
                      }}
                    />
                    <Select
                      className="select-flex-top"
                      options={this.state.servingUnitChoices}
                      value={
                        this.state.formRecipe.serving.unit === "people"
                          ? this.state.servingUnitChoices.find(
                              (element) => element.value === "people"
                            )
                          : this.state.servingUnitChoices.find(
                              (element) => element.value === null
                            )
                      }
                      onChange={(value) => {
                        this.setState({
                          formRecipe: {
                            ...this.state.formRecipe,
                            serving: {
                              ...this.state.formRecipe.serving,
                              unit: value.value,
                            },
                          },
                        });
                      }}
                      placeholder="personnes"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-group">
                    <span className="input-group-addon">
                      Nom technique unique
                    </span>
                    <input
                      className="form-control"
                      name="code"
                      type="text"
                      value={this.state.formRecipe.code}
                      onChange={this.handleChange}
                      placeholder="Nom de la recette en anglais, sans accent (exemple: carrotCake)."
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-group">
                    <span className="input-group-addon">
                      Choix des ingrédients
                    </span>
                    <Select
                      className="select-flex"
                      isMulti
                      options={this.state.ingredientChoices}
                      value={this.state.ingredientSelectedChoices}
                      onChange={(values) => {
                        const ingredients = [{ list: [] }];
                        values.map((value) => {
                          const quantity = (
                            this.state.formRecipe.ingredients[0].list.find(
                              (element) => element.name === value.value
                            ) || { quantity: 10 }
                          ).quantity;
                          ingredients[0].list.push({
                            name: value.value,
                            quantity,
                          });
                        });
                        this.setState({
                          ingredientSelectedChoices: values,
                          formRecipe: {
                            ...this.state.formRecipe,
                            ingredients,
                          },
                        });
                      }}
                      placeholder="Ingrédients"
                    />
                  </div>
                </div>

                {this.state.formRecipe.ingredients[0].list.map((ing, index) => {
                  const ingredient = this.state.ingredientChoices.find(
                    (element) =>
                      element.value ===
                      this.state.formRecipe.ingredients[0].list[index].name
                  );
                  if (!ingredient) {
                    alert(
                      "Ingredient foireux : " +
                        this.state.formRecipe.ingredients[0].list[index].name
                    );
                  }

                  return (
                    <div
                      className="form-group"
                      key={`ing-${this.state.formRecipe.code}-${index}`}
                    >
                      <div className="input-group">
                        <span className="input-group-addon">Ingrédient</span>
                        <input
                          className="form-control"
                          name={index}
                          type="number"
                          value={
                            this.state.formRecipe.ingredients[0].list[index]
                              .quantity
                          }
                          onChange={this.handleQuantityChange}
                          placeholder="Quantité"
                        />
                        <span className="input-group-addon">
                          {ingredient && ingredient.label.replace("10 ", "")}
                        </span>
                      </div>
                    </div>
                  );
                })}

                <div className="form-group">
                  <div className="input-group">
                    <span className="input-group-addon">Instructions</span>
                    <textarea
                      className="form-control"
                      name="instructions"
                      type="text"
                      rows="10"
                      value={this.state.formRecipe.instructions}
                      onChange={this.handleChange}
                      placeholder="- Instructions&#10;- sous forme de liste"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-group">
                    <span className="input-group-addon">Source</span>
                    <input
                      className="form-control"
                      name="source"
                      type="text"
                      value={this.state.formRecipe.source}
                      onChange={this.handleChange}
                      placeholder="URL(s) de la recette qui a servi d'inspiration"
                    />
                  </div>
                </div>

                <div className="form-layout-footer">
                  {this.state.editMode ? (
                    <div className="flex-1 d-xs-flex justify-content-between">
                      <button
                        className="btn btn-info mg-r-5"
                        onClick={async () => {
                          try {
                            await this.api.editLostRecipe(
                              this.state.formRecipe
                            );
                            alert("Modifications sauvegardées");
                          } catch (e) {
                            prompt("Problème, modification non sauvegardée", e);
                          }
                        }}
                      >
                        Enregistrer les modifications
                      </button>

                      <button
                        className="btn btn-light mg-l-50"
                        onClick={() => {
                          const recipe = this.state.formRecipe;
                          // console.warn("ings", this.state.ingredientChoices[0]);
                          console.warn("rrrr", recipe);
                          const wordings = {
                            [recipe.code]: {
                              title: recipe.title.trim(),
                              instructions: recipe.instructions.trim(),
                              dummy: 1 /** sert à avoir une virgule après les instructions */,
                            },
                          };
                          prompt(
                            "wording.js (fr)",
                            (
                              JSON.stringify(wordings, null, 2)
                                .replace(/^\{/, "")
                                .replace(/\}$/, "") + ","
                            )
                              .replace(/ ([!?:]{1})/g, "\\u00A0$1")
                              .replace(`"dummy": 1\n`, "")
                              .replace(/: "/g, ": `")
                              .replace(/",/g, "`,")
                              .trim()
                          );
                          const ingredients = [];
                          const aliases = {};
                          this.state.ingredientChoices.map((ing) => {
                            aliases[ing.value] = ing.reference;
                          });
                          recipe.ingredients[0].list.map((ing) => {
                            const alias =
                              aliases[ing.name] === ing.name
                                ? null
                                : aliases[ing.name];
                            if (alias) {
                              ingredients.push({
                                name: alias,
                                alias: ing.name,
                                quantity: ing.quantity,
                              });
                            } else {
                              ingredients.push({
                                name: ing.name,
                                quantity: ing.quantity,
                              });
                            }
                          });
                          const recipeContent = {
                            [recipe.code]: {
                              howMany: recipe.serving,
                              ingredients,
                            },
                          };
                          prompt(
                            "recipes.js",
                            (
                              JSON.stringify(recipeContent, null, 2)
                                .replace(/^\{/, "")
                                .replace(/\}$/, "") + ","
                            )
                              .replace(/\n/g, "")
                              .trim()
                          );
                        }}
                      >
                        Intégrer dans Diapason
                      </button>
                    </div>
                  ) : (
                    <button
                      className="btn btn-success mg-r-5"
                      onClick={async () => {
                        try {
                          await this.api.createLostRecipes(
                            this.state.formRecipe
                          );
                          alert("Recette sauvegardée");
                          this.refreshRecipesAndIngredients(false);
                        } catch (e) {
                          prompt("Problème, recette non sauvegardée", e);
                        }
                      }}
                    >
                      Ajouter cette recette
                    </button>
                  )}
                </div>

                {/* Fin du form-group ci-dessous */}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default LostRecipe;
