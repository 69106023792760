/* eslint-disable array-callback-return */
import React, { Component } from "react";

import Radar from "./Radar";
import moment from "moment";

class WFURadar extends Component {
  render() {
    if (this.props.wfus) {
      const currentWFU = this.props.wfus[this.props.wfus.length - 1];
      let currentWFUValues = Object.values(currentWFU.subScores);

      let worstWFUValues = [-1, -1, -1, -1, -1, -1, -1, -1];
      let bestWFUValues = [-1, -1, -1, -1, -1, -1, -1, -1];

      let bestWFU = currentWFU;
      let worstWFU = currentWFU;

      if (this.props.wfus.length > 1) {
        for (let i = this.props.wfus.length - 2; i >= 0; --i) {
          const wfu = this.props.wfus[i];
          if (wfu.score !== 40) {
            if (wfu.score < bestWFU.score) {
              bestWFU = wfu;
            } else if (wfu.score > worstWFU.score) {
              worstWFU = wfu;
            }
          }
        }
        bestWFUValues = Object.values(bestWFU.subScores);
        worstWFUValues = Object.values(worstWFU.subScores);
      }

      return (
        <div className="wfu">
          <h4 className="wfuIndex">
            <a href={`/user?id=${this.props.user}`}>#{this.props.index}</a>
          </h4>
          <Radar
            values1={currentWFUValues}
            values2={bestWFUValues}
            values3={worstWFUValues}
          />
          {currentWFU ? (
            <div style={{ color: "#00C" }}>
              {moment(currentWFU.date, "X").fromNow()} (
              {moment(currentWFU.date, "X").format("ddd D/MM/YY à HH:mm")}) :{" "}
              <strong>{80 - currentWFU.score} pts de confort</strong>
            </div>
          ) : null}
          {bestWFU ? (
            <div style={{ color: "#080" }}>
              {moment(bestWFU.date, "X").fromNow()} (
              {moment(bestWFU.date, "X").format("ddd D/MM/YY à HH:mm")}) :{" "}
              <strong>{80 - bestWFU.score} pts de confort</strong>
            </div>
          ) : null}
          {worstWFU ? (
            <div style={{ color: "#C00" }}>
              {moment(worstWFU.date, "X").fromNow()} (
              {moment(worstWFU.date, "X").format("ddd D/MM/YY à HH:mm")}) :{" "}
              <strong>{80 - worstWFU.score} pts de confort</strong>
            </div>
          ) : null}
        </div>
      );
    }
    return null;
  }
}

export default WFURadar;
