import Request from "./Request";
import moment from "moment";

export default class API {
  async login(email, password) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");

    const res = await Request.post(`/login`, null, { email, password });

    if (res.status >= 400) {
      switch (res.json.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        case "EmailNotConfirmedError":
        case "InvalidCredentialsError": {
          throw Error(`error.login.${res.json.name}`);
        }
        default: {
          console.error(`signup(${email},...)`, res.json);
          throw Error(`error.default`);
        }
      }
    }

    localStorage.setItem("accessToken", res.json.accessToken);
    localStorage.setItem("refreshToken", res.json.refreshToken);
    return res.json;
  }

  async getUsers(size = 10, page = 1, email = "") {
    const response = await Request.get(
      `/diapason/admin/users?perPage=${size}&page=${page}&email=${email}`
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getUsers()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getLicensedUsers(size = 10, page = 1, platform = "") {
    const response = await Request.get(
      `/diapason/admin/licenses/users?perPage=${size}&page=${page}&platform=${platform}`
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getLicensedUsers()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getStatsUsers(from, to) {
    const response = await Request.get(
      `/diapason/stats/users?from=${from}&to=${to}`
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getStatsSubscriptions()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getStatsSubscriptions(from, to, period) {
    const response = await Request.get(
      `/diapason/stats/subscriptions?from=${from}&to=${to}&period=${period}`
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getStatsSubscriptions()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async batchLicenseCountries(fromPage) {
    const response = await Request.get(
      `/diapason/maintenance/batchLicenseCountries?fromPage=${fromPage}`
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getStatsEventGroups()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getStatsEventGroups(from, to, period, criteria, groupBy = "") {
    const response = await Request.post(
      `/diapason/stats/events/groups?from=${from}&to=${to}&period=${period}&groupBy=${groupBy}`,
      null,
      criteria
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getStatsEventGroups()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getStatsEvents(from, to, period, criteria) {
    const response = await Request.post(
      `/diapason/stats/events?from=${from}&to=${to}&period=${period}`,
      null,
      criteria
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getStatsEvents()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getStatsDiagnosisSurveyRates(from, to, period, licensed, criteria) {
    const response = await Request.post(
      `/diapason/stats/events/surveys/diagnosis/rates?from=${from}&to=${to}&period=${period}&licensed=${licensed}`,
      null,
      criteria
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getStatsDiagnosisSurveyRates()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getStatsWelcomeEvents(from, to, period, licensed, criteria) {
    const response = await Request.post(
      `/diapason/stats/events/welcome?from=${from}&to=${to}&period=${period}&licensed=${licensed}`,
      null,
      criteria
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getStatsWelcomeEvents()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getVersionedUsers(size = 10, page = 1, version = "") {
    if (!version || version.length < 5) {
      return [];
    }

    localStorage.setItem("searchVersion", version);
    const response = await Request.get(
      `/diapason/admin/users?perPage=${size}&page=${page}&version=${version}`
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getVersionedUsers()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getUser(token) {
    const isEmail = token.indexOf("@") > -1;
    const queryString = isEmail ? `?email=${token}` : "";
    const userId = isEmail ? "-" : token;
    const response = await Request.get(
      `/diapason/admin/users/${userId}${queryString}`
    );
    if (response.status === 204) {
      return null;
    }
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getUser(${userId})`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getUserEventSourcing(userId) {
    try {
      return await (
        await Request.get(`/diapason/admin/users/${userId}/events?perPage=1000`)
      ).json;
    } catch (e) {
      throw Error(`ERROR getUserEventSourcing(${userId}) - ${e}`);
    }
  }

  async getUserEventSummary(userId) {
    try {
      return await (
        await Request.get(`/diapason/admin/users/${userId}/events/summary`)
      ).json;
    } catch (e) {
      throw Error(`ERROR getUserEventSummary(${userId}) - ${e}`);
    }
  }

  async patchUser(userId, name, value) {
    try {
      await Request.patch(`/diapason/admin/users/${userId}`, null, {
        [name]: value,
      });
    } catch (e) {
      throw Error(`ERROR patchUser(${userId}) - ${e}`);
    }
  }

  async deleteUser(userId) {
    try {
      await Request.delete(`/diapason/admin/users/${userId}`);
    } catch (e) {
      throw Error(`ERROR deleteUser(${userId}) - ${e}`);
    }
  }

  async addLicense(userId, productCode) {
    let product;
    switch (productCode) {
      case "ALMA": {
        product = "5e4081961e2150f34b8c91ae";
        break;
      }
      case "STRIPE": {
        product = "5cebcd016642f274b0f304ae";
        break;
      }
      case "DISCOVERY24": {
        product = "607e91a56dccaeb2938630d7";
        break;
      }
      case "DISCOVERY24WEB": {
        product = "61643a2aa43ab4302e9db235";
        break;
      }
      case "APPLE": {
        product = "5cebcd016642f274b0f304b3";
        break;
      }
      case "GOOGLE": {
        product = "5cebcd016642f274b0f304b1";
        break;
      }
      case "FREE": {
        product = "5cebcd016642f274b0f304b6";
        break;
      }
      case "FREE1WEEK": {
        product = "616ed99be1255884ef04e8e4";
        break;
      }
      case "FREE1MONTH": {
        product = "616ed9ace1255884ef04e8f0";
        break;
      }
      case "INFINITY": {
        product = "5cebcd016642f274b0f304b5";
        break;
      }
      default: {
        return false;
      }
    }

    try {
      await Request.post(`/diapason/admin/users/${userId}/licenses`, null, {
        product,
      });
    } catch (e) {
      throw Error(`ERROR addLicense(${userId},${productCode}) - ${e}`);
    }
  }

  async createProUserWithLicense(
    email,
    password,
    product,
    firstName,
    lastName
  ) {
    let response = await Request.post(`/diapason/admin/users`, null, {
      email,
      password,
      firstName: firstName ? firstName : undefined,
      lastName: lastName ? lastName : undefined,
    });
    let result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        case "EmailAlreadyExistsError": {
          throw Error(`Il y a déjà un compte avec cette adresse email`);
        }
        default: {
          console.error(`createProUser() step1`, result);
          throw Error(`error.default`);
        }
      }
    }

    const userId = result.id;
    response = await Request.post(
      `/diapason/admin/users/${userId}/licenses`,
      null,
      {
        product,
      }
    );
    result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`createProUser() step2`, result);
          throw Error(`error.default`);
        }
      }
    }
  }

  async getSales(from, to) {
    const response = await Request.get(`/diapason/sales?from=${from}&to=${to}`);
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getSales(${from}, ${to})`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getSurveys(reviewOnly, topic, review, from, to) {
    const response = await Request.get(
      `/diapason/admin/events/surveys?reviewOnly=${reviewOnly}&topic=${topic}&review=${review}&from=${from}&to=${to}`
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getSurveys(${from}, ${to})`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getUserMailChimpData(email) {
    const response = await Request.get(
      `/diapason/admin/users/-/mailchimp-status?email=${email}`
    );
    if (response.status === 204) {
      return null;
    }
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getUserMailChimpData(${email})`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getUserCrispData(email) {
    const response = await Request.get(
      `/diapason/admin/users/-/crisp-status?email=${email}`
    );
    if (response.status === 204) {
      return null;
    }
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getUserMailChimpData(${email})`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getPartnerStatsData() {
    const response = await Request.get(`/diapason/stats/partners/ta`);
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getTAStatsData()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getActivationCodesBetween(start, stop) {
    try {
      return await (
        await Request.get(
          `/diapason/admin/activationcodes/?start=${start}&stop=${stop}`
        )
      ).json.map((code) => {
        return {
          ...code,
          checked: code.status === "NOT_USED" && !code.merchant,
        };
      });
    } catch (e) {
      throw Error(`ERROR getActivationCodesBetween(${start}, ${stop}) - ${e}`);
    }
  }

  async setActivationCodesMerchant(
    serials,
    reference,
    corporationName,
    contactName,
    phone,
    address,
    email
  ) {
    try {
      return await (
        await Request.patch(`/diapason/admin/activationcodes/`, null, {
          serials,
          reference,
          corporationName,
          contactName,
          phone,
          address,
          email,
        })
      ).json;
    } catch (e) {
      throw Error(
        `ERROR setActivationCodesMerchant(${corporationName}) - ${e}`
      );
    }
  }

  async getLastReferrerBuyers(from, to) {
    const response = await Request.get(
      `/diapason/stats/purchases/buyers/lastReferrer?from=${from}&to=${to}`
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getLastReferrerBuyers(${from}, ${to})`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getAllReferrersNonBuyers(from, to, cache = "true") {
    const response = await Request.get(
      `/diapason/stats/purchases/nonBuyers/referrers?cache=${cache}&from=${from}&to=${to}`
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getAllReferrersNonBuyers(${from}, ${to})`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getLastReferrerNonBuyers(from, to, cache = "true") {
    const response = await Request.get(
      `/diapason/stats/purchases/nonBuyers/lastReferrer?cache=${cache}&from=${from}&to=${to}`
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getLastReferrerNonBuyers(${from}, ${to})`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getRouteActionsAndProfiles(to, cache = "true") {
    const from = moment(to).subtract(3, "month").format("YYYY-MM-DD");
    const response = await Request.get(
      `/diapason/stats/purchases/routes/profiles?cache=${cache}&from=${from}&to=${to}`
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getRouteActionsAndProfiles(${to})`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getFAQ() {
    const response = await Request.get(`/diapason/admin/faqs`);
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getFAQ()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async updateFAQ(faqs, faqsToDelete) {
    try {
      await Request.put(`/diapason/admin/faqs`, null, {
        faqs,
        faqsToDelete,
      });
    } catch (e) {
      throw Error(`ERROR updateFAQ(…) - ${e}`);
    }
  }

  async getTherapyStats() {
    const response = await Request.get(`/diapason/admin/therapy/stats`);
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getTherapyStats()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getWFUUsers(wfuCount) {
    const response = await Request.get(
      `/diapason/admin/users-wfus?wfuCount=${wfuCount}`
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getWFUUsers(${wfuCount})`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getRenewalStats(from, to) {
    const response = await Request.get(
      `/diapason/stats/licenses/renewals/?from=${from}&to=${to}`
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getRenewalStats()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getEncouragementsToValidate() {
    const response = await Request.get(
      `/diapason/admin/encouragements/to-validate`
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getEncouragementsToValidate()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getEncouragements(sort) {
    const response = await Request.get(
      `/diapason/admin/encouragements?sort=${sort}`
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getEncouragements()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getModeratedEncouragements(sort) {
    const response = await Request.get(
      `/diapason/admin/encouragements?sort=${sort}&moderated=true`
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getModeratedEncouragements()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async resetEncouragementImageCache() {
    const response = await Request.post(
      `/diapason/admin/encouragements/reset-cache?doIt=1`
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`resetEncouragementImageCache()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getEncouragementStats() {
    const response = await Request.get(`/diapason/admin/encouragements/stats`);
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getEncouragementStats()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async validateEncouragement(encouragementId, message) {
    try {
      return await Request.patch(
        `/diapason/admin/encouragements/${encouragementId}/validate`,
        null,
        {
          message,
        }
      );
    } catch (e) {
      throw Error(`ERROR validateEncouragement(${encouragementId}) - ${e}`);
    }
  }

  async rejectEncouragement(encouragementId) {
    try {
      return await Request.patch(
        `/diapason/admin/encouragements/${encouragementId}/reject`
      );
    } catch (e) {
      throw Error(`ERROR rejectEncouragement(${encouragementId}) - ${e}`);
    }
  }

  async getAppUpdates() {
    const response = await Request.get(`/diapason/admin/app-updates`);
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getAppUpdates()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async updateAppUpdates(appUpdates, appUpdatesToDelete) {
    try {
      await Request.put(`/diapason/admin/app-updates`, null, {
        appUpdates,
        appUpdatesToDelete,
      });
    } catch (e) {
      throw Error(`ERROR updateAppUpdates(…) - ${e}`);
    }
  }

  async getArticles() {
    const response = await Request.get(`/diapason/admin/articles`);
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getArticles()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async updateArticles(articles, articlesToDelete) {
    try {
      await Request.put(`/diapason/admin/articles`, null, {
        articles,
        articlesToDelete,
      });
    } catch (e) {
      throw Error(`ERROR updateArticles(…) - ${e}`);
    }
  }

  async getLostRecipes(lang) {
    const response = await Request.get(
      `/diapason/admin/lost-recipes/?lang=${lang}`
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getLostRecipes()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async createLostRecipes(recipe) {
    const response = await Request.post(`/diapason/admin/lost-recipes`, null, {
      ...recipe,
    });
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`createLostRecipes()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async createIngredient(ingredient, lang = "fr") {
    ingredient.lang = lang;
    if (!ingredient.type) {
      ingredient.type = { value: "none" };
    }
    switch (ingredient.type.value) {
      case "usg": {
        ingredient.text = `{{us}} ${ingredient.text} ({{iso}} g)`;
        break;
      }
      case "uscl": {
        ingredient.text = `{{us}} ${ingredient.text} ({{iso}} cl)`;
        break;
      }
      case "tsp": {
        ingredient.text = `{{us}} ${ingredient.text}`;
        break;
      }
      default: {
        ingredient.text = `{{count}} ${ingredient.text}`;
      }
    }
    delete ingredient.type;
    const response = await Request.post(
      `/diapason/admin/lost-recipes/ingredients`,
      null,
      {
        ...ingredient,
      }
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`createIngredient()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async editLostRecipe(recipe) {
    const response = await Request.put(
      `/diapason/admin/lost-recipes/${recipe.id}`,
      null,
      { ...recipe }
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`editLostRecipe()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }

  async getLostRecipeIngredients(lang) {
    const response = await Request.get(
      `/diapason/admin/lost-recipes/ingredients?lang=${lang}`
    );
    const result = response.json;
    if (response.status >= 400) {
      switch (result.name) {
        case "UnreachableServer": {
          throw Error(`error.timeout`);
        }
        default: {
          console.error(`getLostRecipes()`, result);
          throw Error(`error.default`);
        }
      }
    }
    return result;
  }
}
