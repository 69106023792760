/* eslint-disable array-callback-return */
import React, { Component } from "react";

import API from "../utils/API";
import Layout from "../components/Layout";
import ReactTable from "react-table";
import moment from "moment";

const newAppUpdate = {
  date: moment().format("YYYY-MM-DD"),
  version: "",
  fr: {
    android: {
      features: "",
      fixes: "",
    },
    ios: {
      features: "",
      fixes: "",
    },
  },
  en: {
    android: {
      features: "",
      fixes: "",
    },
    ios: {
      features: "",
      fixes: "",
    },
  },
};

class AppUpdatesScreen extends Component {
  constructor() {
    super();
    this.api = new API();
    this.cols = [
      {
        id: "date",
        Header: "Date",
        filterable: false,
        sortable: false,
        accessor: (u) => (u.date ? moment(u.date).format("YYYY-MM-DD") : ""),
        width: 150,
      },
      {
        id: "version",
        Header: "Version",
        filterable: false,
        sortable: false,
        accessor: (u) => u.version,
        width: 75,
      },
      {
        id: "lang1",
        Header: "Lang",
        accessor: (u) => (u.lang === "fr" ? "fr-FR" : "en-GB"),
        maxWidth: 55,
        filterable: false,
        sortable: false,
        Cell: (row) => (
          <div className={`flag ${row.value}`}>
            <span>{row.value}</span>
          </div>
        ),
      },
      {
        id: "os1",
        Header: "OS",
        filterable: false,
        sortable: false,
        accessor: (u) => u.os,
        maxWidth: 32,
        Cell: (row) => <div className={row.value.toUpperCase()} />,
      },
      {
        id: "action",
        Header: "-",
        filterable: false,
        sortable: false,
        width: 25,
        Cell: (row) => (
          <a href={`/user?id=${row.row._original.id}`}>
            <i className="fa fa-eye"></i>
          </a>
        ),
      },
      {
        id: "features",
        Header: "Fonctionnalités",
        filterable: false,
        sortable: false,
        Cell: (row) => (
          <span
            dangerouslySetInnerHTML={{
              __html: row.row._original.features.replace(/\n/g, "<br />"),
            }}
          />
        ),
      },
      {
        id: "fixes",
        Header: "Corrections",
        filterable: false,
        sortable: false,
        Cell: (row) => (
          <span
            dangerouslySetInnerHTML={{
              __html: row.row._original.fixes.replace(/\n/g, "<br />"),
            }}
          />
        ),
      },
      {
        id: "mongo",
        Header: "Mongo",
        sortable: false,
        filterable: false,
        width: 58,
        Cell: (row) => (
          <div className="exportContainer">
            <button
              onClick={() =>
                copyToClipboard(
                  `db.getCollection('appupdates').find({_id:ObjectId('${row.row._original._id}')})`
                )
              }
              title={row.row._original.id}
              className="fa fa-copy"
            />
          </div>
        ),
        filterAll: true,
      },
      {
        id: "copy",
        Header: "Store",
        sortable: false,
        filterable: false,
        width: 55,
        Cell: (row) =>
          row.row._original.os === "android" &&
          row.row._original.lang === "fr" ? null : (
            <div className="exportContainer">
              <button
                onClick={() => this.copyForStore(row.row._original)}
                title={row.row._original.id}
                className="fa fa-copy"
              />
            </div>
          ),
        filterAll: true,
      },
      {
        id: "lang",
        Header: "Lang",
        accessor: (u) => (u.lang === "fr" ? "fr-FR" : "en-GB"),
        maxWidth: 55,
        filterable: false,
        sortable: false,
        Cell: (row) => (
          <div className={`flag ${row.value}`}>
            <span>{row.value}</span>
          </div>
        ),
      },
      {
        id: "os",
        Header: "OS",
        filterable: false,
        sortable: false,
        accessor: (u) => u.os,
        maxWidth: 32,
        Cell: (row) => <div className={row.value.toUpperCase()} />,
      },
    ];
  }

  state = {
    new: { ...newAppUpdate },
    appUpdates: [],
  };

  componentDidMount() {
    this.updateData();
  }

  async updateData() {
    this.setState({
      new: { ...JSON.parse(JSON.stringify(newAppUpdate)) },
      appUpdates: await this.api.getAppUpdates(),
    });
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name.split(";");

    const newState = { ...this.state };
    if (name.length === 2) {
      newState[name[0]][name[1]] = value;
    } else {
      newState[name[0]][name[1]][name[2]][name[3]] = value;
    }

    this.setState(newState);
  };

  copy = () => {
    this.setState({
      ...this.state,
      new: {
        ...this.state.new,
        fr: {
          ...this.state.new.fr,
          ios: {
            features: this.state.new.fr.android.features,
            fixes: this.state.new.fr.android.fixes,
          },
        },
        en: {
          ...this.state.new.en,
          ios: {
            features: this.state.new.en.android.features,
            fixes: this.state.new.en.android.fixes,
          },
        },
      },
    });
  };

  copyForStore = (data) => {
    if (data.os !== "android") {
      copyToClipboard(data.features + "\n" + data.fixes);
    } else {
      const version = data.version;
      this.state.appUpdates.map((datum) => {
        if (
          datum.version === version &&
          datum.lang === "fr" &&
          datum.os === "android"
        ) {
          copyToClipboard(
            `<en-US>\n${data.features}\n${data.fixes}\n</en-US>\n\n<fr-FR>\n${datum.features}\n${datum.fixes}\n</fr-FR>\n\n`
          );
        }
      });
    }
  };

  submitForm = async (e) => {
    e.preventDefault();

    const appUpdates = this.state.appUpdates;

    if (this.state.new.version) {
      const langs = ["fr", "en"];
      const systems = ["android", "ios"];
      langs.map((lang) => {
        systems.map((os) => {
          appUpdates.push({
            date: this.state.new.date,
            version: this.state.new.version,
            os,
            lang,
            features: this.state.new[lang][os].features,
            fixes: this.state.new[lang][os].fixes,
          });
        });
      });
    }

    try {
      await this.api.updateAppUpdates(appUpdates, []);
      if (this.state.new.version) {
        setTimeout(this.updateData, 1000);
      }
    } catch (e) {
      console.warn("Erreur submitForm", e);
      alert(`Problème lors de la mise à jour… ${e}`);
    }
  };

  render() {
    return (
      <Layout>
        <nav className="breadcrumb sl-breadcrumb">
          <a className="breadcrumb-item" href="/dashboard">
            Dashboard
          </a>
          <span className="breadcrumb-item active">App Updates</span>
        </nav>
        <div className="sl-pagebody">
          <div className="row row-sm mg-b-20">
            <div className="col-md-12">
              <div className="card pd-t-15 pd-l-20 pd-b-15">
                <h6 className="card-body-title">Nouvelle mise à jour</h6>
                <div className="row row-sm">
                  <div className="col-md-8">
                    <div className="row row-sm">
                      <div className="col-md-6">
                        <div className="input-group">
                          <span className="input-group-addon" title="Date">
                            <code>Date</code>
                          </span>
                          <input
                            className="form-control"
                            name={`new;date`}
                            type="date"
                            required
                            onChange={this.handleChange}
                            value={this.state.new.date}
                            placeholder="Date (YYYY-MM-DD)"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-group">
                          <span className="input-group-addon" title="Version">
                            <code>Version</code>
                          </span>
                          <input
                            className="form-control"
                            name={`new;version`}
                            type="text"
                            onChange={this.handleChange}
                            value={this.state.new.version}
                            placeholder="Version (1.y.z)"
                          />
                        </div>
                      </div>
                    </div>

                    {/** START ANDROID */}

                    <div className="row row-sm mg-t-10">
                      <div className="col-md-6">
                        <div className="input-group">
                          <span
                            className="input-group-addon"
                            title="Français - Android - Features"
                          >
                            <code>FAN</code>
                          </span>
                          <textarea
                            className="form-control"
                            style={{ minHeight: 80 }}
                            name="new;fr;android;features"
                            onChange={this.handleChange}
                            value={this.state.new.fr.android.features || ""}
                            placeholder="Français - Android - Nouvelles fonctionnalités"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-group">
                          <span
                            className="input-group-addon"
                            title="English - Android - New features"
                          >
                            <code>EAN</code>
                          </span>
                          <textarea
                            className="form-control"
                            style={{ minHeight: 80 }}
                            name="new;en;android;features"
                            onChange={this.handleChange}
                            value={this.state.new.en.android.features || ""}
                            placeholder="English - Android - New features"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row row-sm mg-t-1">
                      <div className="col-md-6">
                        <div className="input-group">
                          <span
                            className="input-group-addon"
                            title="Français - Android - Corrections"
                          >
                            <code>FAC</code>
                          </span>
                          <textarea
                            className="form-control"
                            style={{ minHeight: 80 }}
                            name="new;fr;android;fixes"
                            onChange={this.handleChange}
                            value={this.state.new.fr.android.fixes || ""}
                            placeholder="Français - Android - Corrections"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-group">
                          <span
                            className="input-group-addon"
                            title="English - Android - Fixes"
                          >
                            <code>EAF</code>
                          </span>
                          <textarea
                            className="form-control"
                            style={{ minHeight: 80 }}
                            name="new;en;android;fixes"
                            onChange={this.handleChange}
                            value={this.state.new.en.android.fixes || ""}
                            placeholder="English - Android - Fixes"
                          />
                        </div>
                      </div>
                    </div>

                    {/** END ANDROID */}

                    <div className="row row-sm mg-t-1">
                      <div
                        className="col-md-12 pd-t-10"
                        style={{ textAlign: "center" }}
                      >
                        <button
                          className="btn btn-outline-info"
                          onClick={this.copy}
                        >
                          Copier Android vers iOS
                        </button>
                      </div>
                    </div>

                    {/** START IOS */}

                    <div className="row row-sm mg-t-10">
                      <div className="col-md-6">
                        <div className="input-group">
                          <span
                            className="input-group-addon"
                            title="Français - iOS - Features"
                          >
                            <code>FiN</code>
                          </span>
                          <textarea
                            className="form-control"
                            style={{ minHeight: 80 }}
                            name="new;fr;ios;features"
                            onChange={this.handleChange}
                            value={this.state.new.fr.ios.features || ""}
                            placeholder="Français - iOS - Nouvelles fonctionnalités"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-group">
                          <span
                            className="input-group-addon"
                            title="English - iOS - New features"
                          >
                            <code>EiN</code>
                          </span>
                          <textarea
                            className="form-control"
                            style={{ minHeight: 80 }}
                            name="new;en;ios;features"
                            onChange={this.handleChange}
                            value={this.state.new.en.ios.features || ""}
                            placeholder="English - iOS - New features"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row row-sm mg-t-1">
                      <div className="col-md-6">
                        <div className="input-group">
                          <span
                            className="input-group-addon"
                            title="Français - iOS - Corrections"
                          >
                            <code>FiC</code>
                          </span>
                          <textarea
                            className="form-control"
                            style={{ minHeight: 80 }}
                            name="new;fr;ios;fixes"
                            onChange={this.handleChange}
                            value={this.state.new.fr.ios.fixes || ""}
                            placeholder="Français - iOS - Corrections"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-group">
                          <span
                            className="input-group-addon"
                            title="English - iOS - Fixes"
                          >
                            <code>EiF</code>
                          </span>
                          <textarea
                            className="form-control"
                            style={{ minHeight: 80 }}
                            name="new;en;ios;fixes"
                            onChange={this.handleChange}
                            value={this.state.new.en.ios.fixes || ""}
                            placeholder="English - iOS - Fixes"
                          />
                        </div>
                      </div>
                    </div>

                    {/** END IOS */}
                  </div>
                  <div className="col-md-4">
                    <ul>
                      <li>
                        Android France
                        <div className="preview">
                          <strong
                            dangerouslySetInnerHTML={{
                              __html: this.state.new.fr.android.features.replace(
                                "\n",
                                "<br />"
                              ),
                            }}
                          />
                          <br />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: this.state.new.fr.android.fixes.replace(
                                "\n",
                                "<br />"
                              ),
                            }}
                          />
                        </div>
                      </li>
                      <li>
                        iOS France
                        <div className="preview">
                          <strong
                            dangerouslySetInnerHTML={{
                              __html: this.state.new.fr.ios.features.replace(
                                "\n",
                                "<br />"
                              ),
                            }}
                          />
                          <br />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: this.state.new.fr.ios.fixes.replace(
                                "\n",
                                "<br />"
                              ),
                            }}
                          />
                        </div>
                      </li>
                    </ul>
                    <ul className="mg-t-20">
                      <li>
                        Android English
                        <div className="preview">
                          <strong
                            dangerouslySetInnerHTML={{
                              __html: this.state.new.en.android.features.replace(
                                "\n",
                                "<br />"
                              ),
                            }}
                          />
                          <br />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: this.state.new.en.android.fixes.replace(
                                "\n",
                                "<br />"
                              ),
                            }}
                          />
                        </div>
                      </li>
                      <li>
                        iOS English
                        <div className="preview">
                          <strong
                            dangerouslySetInnerHTML={{
                              __html: this.state.new.en.ios.features.replace(
                                "\n",
                                "<br />"
                              ),
                            }}
                          />
                          <br />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: this.state.new.en.ios.fixes.replace(
                                "\n",
                                "<br />"
                              ),
                            }}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-sm mg-b-20">
            <div className="col-md-12">
              <div className="form-layout-footer">
                <button
                  className="btn btn-primary mg-r-5"
                  onClick={this.submitForm}
                >
                  Sauvegarder tout
                </button>
              </div>
            </div>
          </div>

          <div className="row row-sm">
            <div className="col-md-12">
              <div className="card pd-20 pd-sm-40 mg-b-20">
                <h6 className="card-body-title">Mises à jour</h6>
                <div className="table-wrapper bg-gray">
                  <ReactTable
                    style={{ width: "100%" }}
                    defaultPageSize={100}
                    data={this.state.appUpdates}
                    pages={1}
                    loading={false}
                    columns={this.cols}
                    filterable={true}
                    className="no-filters -striped -highlight"
                    defaultFilterMethod={(filter, row) =>
                      String(row[filter.id]).includes(filter.value)
                    }
                    defaultSorted={[{ id: "date", desc: true }]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function copyToClipboard(data) {
  var $body = document.getElementsByTagName("body")[0];
  var $tempInput = document.createElement("TEXTAREA");
  $body.appendChild($tempInput);
  $tempInput.innerHTML = data;
  $tempInput.select();
  document.execCommand("copy");
  $body.removeChild($tempInput);
}

export default AppUpdatesScreen;
