import "react-table/react-table.css";
import "antd/dist/antd.css";

import React, { Component } from "react";

import API from "../utils/API";
import { DatePicker } from "antd";
import Layout from "../components/Layout";
import { ResponsiveLine } from "@nivo/line";
import moment from "moment";

const { RangePicker } = DatePicker;

class Dashboard extends Component {
  state = {
    from: moment().startOf("month").subtract(13, "months"),
    to: moment(),
    step: "month",
    tickCount: "1",
  };

  constructor() {
    super();
    this.api = new API();
    this.refreshSales();
    this.refreshStats();
  }

  refreshSales = async () => {
    const { from, to } = this.state;
    this.setState(
      { rawData: await this.api.getSales(from.unix(), to.unix()) },
      this.redrawSales
    );
  };

  refreshStats = async () => {
    this.setState({
      daySubs: await this.api.getStatsSubscriptions(
        moment().startOf("day").subtract(2, "days").format("YYYY-MM-DD"),
        moment().add(1, "day").format("YYYY-MM-DD"),
        "day"
      ),
    });

    this.setState({
      sales: await this.api.getSales(
        moment().startOf("day").subtract(4, "days").unix(),
        moment().add(1, "day").unix()
      ),
    });

    /*for (var i = 1; i < 18; ++i) {
      console.warn(
        i,
        moment().startOf("month").subtract(i, "month").format("YYYY/MM"),
        await this.api.getRenewalStats(
          moment().startOf("month").subtract(i, "month").format("YYYY-MM-DD"),
          moment()
            .startOf("month")
            .subtract(i - 1, "month")
            .format("YYYY-MM-DD")
        )
      );
    }*/

    this.setState({
      encouragementsToValidate: await this.api.getEncouragementsToValidate(),
      encouragementStats: await this.api.getEncouragementStats(),
      therapy: await this.api.getTherapyStats(),
    });
  };

  redrawSales = async () => {
    const { step } = this.state;
    const salesVolumeData = {};
    const revenuesData = {};
    const netTurnoverExcludingTaxData = {};

    // eslint-disable-next-line array-callback-return
    this.state.rawData.map((sale) => {
      let period;
      if (step === "week") {
        const saleDate = moment(sale.date);
        period = moment()
          .day("Thursday")
          .isoWeekYear(saleDate.isoWeekYear())
          .isoWeek(saleDate.isoWeek())
          .format("YYYY-MM-DD");
      } else {
        period = moment(sale.date).format("YYYY-MM-01");
      }
      if (!salesVolumeData[period]) {
        salesVolumeData[period] = {
          x: period,
          y: 0,
        };
        revenuesData[period] = {
          x: period,
          y: 0,
        };
        netTurnoverExcludingTaxData[period] = {
          x: period,
          y: 0,
        };
      }
      salesVolumeData[period].y += +sale.price;
      revenuesData[period].y += +sale.income;
      netTurnoverExcludingTaxData[period].y += sale.refundedAmount
        ? 0
        : +sale.priceExcTax;
    });
    const tickCount = Math.ceil((Object.keys(salesVolumeData).length - 1) / 20);

    this.setState({
      tickCount,
      salesData: [
        {
          id: "Revenus",
          data: Object.values(revenuesData)
            .map((item) => {
              item.y = Math.round(item.y);
              return item;
            })
            .sort((a, b) => (a.x > b.x ? 1 : -1)),
        },
        {
          id: "CA Net HT",
          data: Object.values(netTurnoverExcludingTaxData)
            .map((item) => {
              item.y = Math.round(item.y);
              return item;
            })
            .sort((a, b) => (a.x > b.x ? 1 : -1)),
        },
        {
          id: "Volume ventes",
          data: Object.values(salesVolumeData)
            .map((item) => {
              item.y = Math.round(item.y);
              return item;
            })
            .sort((a, b) => (a.x > b.x ? 1 : -1)),
        },
        {
          id: "Rentabilité",
          data: Object.values(JSON.parse(JSON.stringify(salesVolumeData)))
            .map((item) => {
              item.y = 5500;
              return item;
            })
            .sort((a, b) => (a.x > b.x ? 1 : -1)),
        },
      ],
    });
  };

  render() {
    return (
      <Layout>
        <div className="sl-pagebody">
          <div className="row row-sm">
            <div className="col-xl-6">{this.renderSales()}</div>
            <div className="col-xl-6">
              {this.renderSubs()}
              {this.renderLastSales()}
              {this.renderEncouragements()}
              {this.renderTherapy()}

              <ul className="show-sm-only">
                <li>
                  <a href="diapason://login?email=loic.dir+ice1@gmail.com">
                    login+email
                  </a>
                </li>
                <li>
                  <a href="diapason://login">login</a>
                </li>
                <li>
                  <a href="diapason://lullaby?time=1">lullaby?time=1</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  renderLastSales() {
    if (this.state.sales) {
      const [sale] = this.state.sales.slice(-1);
      if (sale) {
        const date = moment(sale.date);
        return (
          <div className="row row-sm mg-t-15">
            <div className="col-xl-12">
              <div className="card pd-20 bg-info tx-center">
                <span className="tx-white">
                  Dernière vente <strong>{date.fromNow()}</strong> à{" "}
                  <strong>{date.format("HH\\h\\\\mm")}</strong> sur{" "}
                  {sale.source} par {sale.email}
                  <button
                    title="Voir utilisateur"
                    className="transparent cursor fa fa-arrow-circle-right"
                    onClick={() =>
                      (window.location.href = "/user?id=" + sale.email)
                    }
                  />
                </span>
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <div className="row row-sm mg-t-15">
        <div className="col-xl-12">
          <div className="card pd-20 bg-info tx-center">
            <span className="tx-white">-</span>
          </div>
        </div>
      </div>
    );
  }

  renderSubs() {
    return (
      <div className="row row-sm">
        <div className="col-sm-4">
          <div
            className="card pd-20 bg-primary tx-center cursor"
            onClick={() => (window.location.href = "/stats")}
          >
            <h3 className="mg-b-0 tx-white tx-lato tx-bold">
              {(this.state.daySubs &&
                this.state.daySubs[2] &&
                this.state.daySubs[2].total) ||
                "-"}
            </h3>
            <span className="tx-white">
              inscriptions
              <br />
              aujourd'hui
            </span>
          </div>
        </div>
        <div className="col-sm-4">
          <div
            className="card pd-20 bg-primary tx-center cursor"
            onClick={() => (window.location.href = "/stats")}
          >
            <h3 className="mg-b-0 tx-white tx-lato tx-bold">
              {(this.state.daySubs &&
                this.state.daySubs[1] &&
                this.state.daySubs[1].total) ||
                "-"}
            </h3>
            <span className="tx-white">
              inscriptions
              <br />
              hier
            </span>
          </div>
        </div>
        <div className="col-sm-4">
          <div
            className="card pd-20 bg-primary tx-center cursor"
            onClick={() => (window.location.href = "/stats")}
          >
            <h3 className="mg-b-0 tx-white tx-lato tx-bold">
              {(this.state.daySubs &&
                this.state.daySubs[0] &&
                this.state.daySubs[0].total) ||
                "-"}
            </h3>
            <span className="tx-white">
              inscriptions
              <br />
              avant-hier
            </span>
          </div>
        </div>
      </div>
    );
  }

  renderEncouragements() {
    const toModerateCount =
      this.state.encouragementsToValidate &&
      this.state.encouragementsToValidate.length;
    const totalCount =
      this.state.encouragementStats && this.state.encouragementStats.total;
    const recentCount =
      this.state.encouragementStats && this.state.encouragementStats.total7days;
    return (
      <div className="row row-sm mg-t-15">
        <div className="col-md-3">
          <div
            className="card pd-20 bg-purple tx-center cursor"
            onClick={() => (window.location.href = "/encouragements")}
          >
            <h3 className="mg-b-0 tx-white tx-lato tx-bold mg-b-5">
              {toModerateCount ? toModerateCount : "-"}
            </h3>
            <span className="tx-white">
              {toModerateCount > 1 ? "encouragements" : "encouragement"}
              <br />à modérer
            </span>
          </div>
        </div>
        <div className="col-md-6">
          <div
            className="card pd-20 bg-purple tx-center cursor"
            onClick={() => (window.location.href = "/encouragements")}
          >
            <h3 className="mg-b-0 tx-white tx-lato tx-bold mg-b-5">
              {recentCount ? recentCount : "-"}
            </h3>
            <span className="tx-white">
              {recentCount > 1 ? "encouragements" : "encouragement"}
              <br />
              validés sur 7 jours
            </span>
          </div>
        </div>
        <div className="col-md-3">
          <div
            className="card pd-20 bg-purple tx-center cursor"
            onClick={() => (window.location.href = "/encouragements")}
          >
            <h3 className="mg-b-0 tx-white tx-lato tx-bold mg-b-5">
              {totalCount ? totalCount : "-"}
            </h3>
            <span className="tx-white">
              {totalCount > 1 ? "encouragements" : "encouragement"}
              <br />
              validés
            </span>
          </div>
        </div>
      </div>
    );
  }

  renderTherapy() {
    const therapyDuration =
      this.state.therapy &&
      Math.round(this.state.therapy.therapyDuration / 3600);
    const activityCount =
      this.state.therapy && this.state.therapy.activityCount;
    const onlineUsers = this.state.therapy && this.state.therapy.onlineUsers;
    return (
      <div className="row row-sm mg-t-15">
        <div className="col-md-5">
          <div
            className="card pd-20 tx-center"
            style={{ backgroundColor: "#313a47" }}
          >
            <h3 className="mg-b-0 tx-white tx-lato tx-bold mg-b-5">
              {typeof onlineUsers !== "undefined" ? onlineUsers : "-"}
            </h3>
            <span className="tx-white">
              utilisateurs online
              <br />
              en ce moment
            </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card pd-20 bg-info tx-center">
            <h3 className="mg-b-0 tx-white tx-lato tx-bold mg-b-5">
              {therapyDuration}h
            </h3>
            <span className="tx-white">
              de thérapie
              <br />
              depuis 30 jours
            </span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card pd-20 bg-info tx-center">
            <h3 className="mg-b-0 tx-white tx-lato tx-bold mg-b-5">
              {activityCount ? activityCount : "-"}
            </h3>
            <span className="tx-white">
              activités
              <br />
              depuis 30 jours
            </span>
          </div>
        </div>
      </div>
    );
  }

  renderSales() {
    return (
      <div className="card mg-b-20 pd-t-20 pd-l-20 pd-r-20">
        <div className="card-head">
          <h6 className="card-body-title">VENTES EN EUROS</h6>
          <RangePicker
            defaultValue={[this.state.from, this.state.to]}
            allowClear={false}
            format="D MMM YYYY"
            ranges={{
              "Toute la période": [moment("2018-10-10"), moment()],
              "Les 14 derniers mois": [
                moment().startOf("month").subtract(13, "months"),
                moment(),
              ],
              "Les 12 derniers mois complets": [
                moment().startOf("month").subtract(12, "months"),
                moment().endOf("month").subtract(1, "month"),
              ],
              "Les 6 derniers mois": [
                moment().startOf("month").subtract(6, "months"),
                moment(),
              ],
              "Les 6 derniers mois complets": [
                moment().startOf("month").subtract(6, "months"),
                moment().endOf("month").subtract(1, "month"),
              ],
              "Les 3 derniers mois": [
                moment().startOf("month").subtract(3, "months"),
                moment(),
              ],
              "Les 3 derniers mois complets": [
                moment().startOf("month").subtract(3, "months"),
                moment().endOf("month").subtract(1, "month"),
              ],
            }}
            onChange={(dates) => {
              this.setState(
                { from: dates[0], to: dates[1] },
                this.refreshSales
              );
            }}
          />
          <select
            className="step-select"
            onChange={(event) => {
              this.setState({ step: event.target.value }, this.redrawSales);
            }}
          >
            <option value="month">par mois</option>
            <option value="week">par semaine</option>
          </select>
        </div>
        <div className="table-wrapper bg-gray" style={{ height: "398px" }}>
          <ResponsiveLine
            data={this.state.salesData || []}
            margin={{ top: 10, right: 40, bottom: 58, left: 10 }}
            padding={0.3}
            colors={{ scheme: "spectral" }}
            axisRight={{
              enable: true,
            }}
            curve="monotoneX"
            axisLeft={null}
            xScale={{
              type: "time",
              format: "%Y-%m-%d",
              precision: this.state.step === "week" ? "day" : "day",
            }}
            xFormat="time:%d %B %Y"
            axisBottom={{
              format: this.state.step === "week" ? "%d/%m" : "%b",
              tickValues:
                this.state.step === "week"
                  ? `every ${this.state.tickCount} monday`
                  : `every ${this.state.tickCount} month`,
            }}
            yScale={{
              type: "linear",
              min: 0,
            }}
            yFormat={(value) => `${value}€`}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 15,
                translateY: 50,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 8,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
            useMesh={true}
            enableArea={true}
            enableGridY={true}
            enableGridX={false}
          />
        </div>
      </div>
    );
  }
}

/*function copyToClipboard(data) {
  var $body = document.getElementsByTagName('body')[0];
  var $tempInput = document.createElement('INPUT');
  $body.appendChild($tempInput);
  $tempInput.setAttribute('value', data)
  $tempInput.select();
  document.execCommand('copy');
  $body.removeChild($tempInput);
}*/

export default Dashboard;
