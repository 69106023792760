import "moment/locale/fr";

import React, { Component } from "react";

import API from "../utils/API";
import Layout from "../components/Layout";
import ReactTable from "react-table";
import { ResponsiveBar } from "@nivo/bar";
import { line } from "d3-shape";
import moment from "moment";

moment.locale("fr");

const chartLineLayer = ({ bars, xScale, yScale }) => {
  const lineGenerator = line()
    .x((d) => xScale(d.data.indexValue) + d.width / 2)
    .y(() => yScale(14));

  return (
    <path d={lineGenerator(bars)} fill="none" stroke="rgba(200, 30, 15, 0.3)" />
  );
};

class Sales extends Component {
  state = {
    charges: [],
    weekCount: 6,
    salesData: [],
  };

  constructor() {
    super();
    this.api = new API();

    this.chargeTableColumns = [
      {
        id: "action1",
        Header: "",
        filterable: false,
        sortable: false,
        width: 30,
        Cell: (row) => {
          switch (row.row._original.source) {
            case "STRIPE": {
              return (
                <a
                  href={`https://dashboard.stripe.com/search?query=${
                    row.row._original.customer || row.row._original.charge
                  }`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="fa fa-cc-stripe"></i>
                </a>
              );
            }
            case "ALMA": {
              return (
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 36">
                    <path fill="#f0c234" d="M1 31h87v5H1z" />
                    <path
                      fill="#262626"
                      d="M13.008 11.85v-1.71h4.922v15.247h-4.922v-1.846C11.674 25.592 9.76 25.9 8.495 25.9c-2.188 0-4.034-.547-5.709-2.325C1.18 21.866.564 19.883.564 17.866c0-2.563.957-4.751 2.359-6.153 1.333-1.333 3.18-2.085 5.299-2.085 1.299 0 3.384.307 4.786 2.222zm-3.556 2.017c-1.06 0-2.05.444-2.7 1.094-.513.512-1.129 1.401-1.129 2.769 0 1.367.582 2.256 1.026 2.735.684.717 1.743 1.196 2.906 1.196.991 0 1.914-.41 2.564-1.06.65-.615 1.196-1.572 1.196-2.871 0-1.094-.41-2.12-1.128-2.804-.752-.717-1.846-1.06-2.735-1.06zM25.612.466h4.922v24.921h-4.922V.466zm12.604 24.921V10.141h4.922v1.777c1.265-1.914 3.111-2.154 4.274-2.154.65 0 1.846.069 2.871.684.786.445 1.436 1.197 1.846 2.12a5.82 5.82 0 011.949-1.949c1.06-.65 2.05-.855 3.247-.855 1.846 0 3.35.547 4.274 1.436 1.47 1.402 1.572 3.453 1.572 4.547v9.64h-4.923v-7.726c0-.786 0-2.153-.65-2.974-.41-.513-1.059-.82-1.777-.82-.82 0-1.504.376-1.914.923-.65.82-.752 2.017-.752 3.008v7.59h-4.923v-7.932c0-1.025-.034-2.222-.786-2.974-.513-.513-1.128-.615-1.641-.615-.65 0-1.23.17-1.71.615-.922.889-.957 2.598-.957 3.11v7.795h-4.922zM82.44 11.85v-1.71h4.923v15.247H82.44v-1.846c-1.333 2.051-3.247 2.359-4.512 2.359-2.188 0-4.034-.547-5.71-2.325-1.606-1.709-2.221-3.692-2.221-5.709 0-2.563.957-4.751 2.359-6.153 1.333-1.333 3.179-2.085 5.298-2.085 1.3 0 3.385.307 4.786 2.222zm-3.555 2.017c-1.06 0-2.051.444-2.7 1.094-.513.512-1.129 1.401-1.129 2.769 0 1.367.581 2.256 1.026 2.735.683.717 1.743 1.196 2.906 1.196.99 0 1.914-.41 2.563-1.06.65-.615 1.197-1.572 1.197-2.871 0-1.094-.41-2.12-1.128-2.804-.752-.717-1.846-1.06-2.735-1.06z"
                    />
                  </svg>
                </div>
              );
            }
            case "GOOGLE": {
              return (
                <div style={{ textAlign: "center", color: "#4285F4" }}>
                  <i className="fa fa-google"></i>
                </div>
              );
            }
            case "APPLE": {
              return (
                <div style={{ textAlign: "center", color: "rgb(51, 160, 44)" }}>
                  <i className="fa fa-apple"></i>
                </div>
              );
            }
            case "IMMERSIVE_THERAPY": {
              return (
                <div style={{ textAlign: "center", color: "#5b2eec" }}>
                  <i className="fa ion-card"></i>
                </div>
              );
            }
            default: {
              return "?";
            }
          }
        },
      },
      {
        id: "date",
        Header: "Date",
        filterable: false,
        sortable: false,
        width: 97,
        Cell: (row) => {
          let content = moment(row.row._original.date).format("DD/MM (ddd)");
          if (row.row._original.product !== "THERAPY") {
            content = (
              <span
                style={{ color: "#bbb" }}
                dangerouslySetInnerHTML={{ __html: content }}
              ></span>
            );
          }
          return content;
        },
      },
      {
        id: "product",
        Header: "Produit",
        filterable: false,
        sortable: false,
        width: 75,
        Cell: (row) => {
          let content = `${row.row._original.product}`;
          if (row.row._original.product !== "THERAPY") {
            content = `<span style="color: #bbb">${content}</span>`;
          }
          return <div dangerouslySetInnerHTML={{ __html: content }} />;
        },
      },
      {
        id: "code",
        Header: "Code",
        filterable: false,
        sortable: false,
        width: 85,
        Cell: (row) => {
          let content = row.row._original.special || "-";
          if (row.row._original.product !== "THERAPY") {
            content = `<span style="font-size:80%; color:#bbb">${content}</span>`;
          } else {
            content = `<span style="font-size:80%">${content}</span>`;
          }
          return <div dangerouslySetInnerHTML={{ __html: content }} />;
        },
      },
      {
        id: "region",
        Header: "R",
        filterable: false,
        sortable: false,
        width: 28,
        Cell: (row) => {
          let content = `${(row.row._original.country || "--")
            .toUpperCase()
            .substring(0, 2)
            .trim()}`;
          return <div dangerouslySetInnerHTML={{ __html: content }} />;
        },
      },
      {
        id: "rawPrice",
        Header: "Prix TTC",
        filterable: false,
        sortable: false,
        width: 75,
        Cell: (row) => {
          let content = `${(row.row._original.price + "").replace(".", ",")}`;
          if (row.row._original.product !== "THERAPY") {
            content = `<span style="color: #bbb">${content}</span>`;
          }
          return <div dangerouslySetInnerHTML={{ __html: content }} />;
        },
      },
      {
        id: "netPrice",
        Header: "Revenus",
        filterable: false,
        sortable: false,
        width: 75,
        Cell: (row) => {
          let content = `${(row.row._original.income + "").replace(".", ",")}`;
          if (row.row._original.product !== "THERAPY") {
            content = `<span style="color: #bbb">${content}</span>`;
          }
          return <div dangerouslySetInnerHTML={{ __html: content }} />;
        },
      },
      {
        id: "action2",
        Header: "",
        filterable: false,
        sortable: false,
        width: 25,
        Cell: (row) => (
          <a href={`/user?id=${row.row._original.email}`}>
            <i className="fa fa-eye"></i>
          </a>
        ),
      },
      {
        id: "client",
        Header: "Prénom NOM",
        filterable: false,
        sortable: false,
        width: 200,
        Cell: (row) => {
          let content = `${
            row.row._original.firstName
              ? row.row._original.firstName.substring(0, 1).toUpperCase() +
                row.row._original.firstName.substring(1)
              : ""
          } ${row.row._original.lastName.toUpperCase()}`;
          if (row.row._original.product !== "THERAPY") {
            content = `<span style="color: #bbb" title="${content}">${content}</span>`;
          }
          return <div dangerouslySetInnerHTML={{ __html: content }} />;
        },
      },
      {
        id: "email",
        Header: "Email",
        filterable: false,
        sortable: false,
        Cell: (row) => {
          let content = row.row._original.email;
          if (row.row._original.product !== "THERAPY") {
            content = `<span style="color: #bbb">${content}</span>`;
          }
          return <div dangerouslySetInnerHTML={{ __html: content }} />;
        },
      },
      {
        id: "refund",
        Header: "Remboursement",
        filterable: false,
        sortable: false,
        width: 120,
        accessor: (u) =>
          u.refundDate
            ? moment(u.refundDate, "X").format("DD/MM/YYYY")
            : u.refundAmount > 0
            ? `Oui (${u.refundAmount})`
            : "",
      },
    ];
  }

  componentDidMount() {
    this.refreshSales();
  }

  refreshSales = async () => {
    const from = moment().startOf("isoWeek");
    const to = moment(from).endOf("isoWeek");
    const weekCount = this.state.weekCount;
    const weekData = [];
    const weekPages = [];
    const weekChartData = [];
    for (let i = 0; i < weekCount; ++i) {
      if (this.state.charges.length > i) {
        weekData[i] = this.state.charges[i];
        weekChartData[i] = this.state.salesData[i];
      } else {
        weekData[i] = {
          from: moment(from),
          to: moment(to),
          data: await this.api.getSales(from.unix(), to.unix()),
        };
        weekPages[i] = 1;
        weekChartData[i] = this.processChartData(weekData[i]);
      }
      this.setState({
        charges: weekData,
        pages: weekPages,
        salesData: weekChartData,
      });
      from.subtract(1, "weeks");
      to.subtract(1, "weeks");
    }
  };

  processChartData = (weekData) => {
    const sales = {};
    weekData.data.map((sale) => {
      if (sale.product !== "LIBERTY") {
        const source =
          sale.source === "IMMERSIVE_THERAPY" ? sale.product : sale.source;
        sales[source] = (sales[source] || 0) + 1;
      }
      return sale;
    });
    return {
      weekNumber: weekData.from.week(),
      ...sales,
    };
  };

  renderSales() {
    const weekSales = [];
    let i = 0;
    this.state.charges.map((week) => {
      if (week.data.length) {
        let totalCount = 0;
        let turnover = 0;
        let income = 0;
        const coupons = {};
        week.data.map((sale) => {
          if (sale.product === "THERAPY") {
            totalCount++;
          }
          turnover += +sale.price;
          income += +sale.income;
          if (["ALMA", "STRIPE"].includes(sale.source) && sale.special) {
            coupons[sale.special] = coupons[sale.special] || 0;
            coupons[sale.special]++;
          }
          return sale;
        });

        let couponsLabel = "";
        const couponsKeys = Object.keys(coupons);
        if (couponsKeys.length > 0) {
          couponsLabel = " - ";
          // eslint-disable-next-line array-callback-return
          couponsKeys.map((coupon) => {
            couponsLabel += `<span style="font-weight:bold">${coupons[coupon]}</span><span style="color:gray">(${coupon})</span>, `;
          });
          couponsLabel = couponsLabel.substring(0, couponsLabel.length - 2);
        }

        const title = (
          <h6 className="card-body-title">
            SEMAINE {week.from.week()} : LUNDI {week.from.format("D MMMM YYYY")}{" "}
            - DIMANCHE {week.to.format("D MMMM YYYY")}{" "}
            <small style={{ textTransform: "none" }}>
              - {totalCount} ventes
            </small>
            <small
              style={{ textTransform: "none" }}
              dangerouslySetInnerHTML={{ __html: couponsLabel }}
            />
          </h6>
        );
        weekSales.push(
          <div key={i}>
            {title}
            <div className="table-wrapper bg-gray">
              <ReactTable
                style={{ width: "100%" }}
                defaultPageSize={week.data.length}
                data={week.data}
                loading={false}
                columns={this.chargeTableColumns}
                filterable={true}
                className="-striped -highlight no-footer"
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]).includes(filter.value)
                }
                defaultSorted={[{ id: "date", desc: true }]}
              />
            </div>
            <div className="mg-t-5 mg-b-20">
              Total de la semaine&nbsp;: {totalCount} ventes - CA&nbsp;:{" "}
              {((Math.round(turnover * 100) / 100).toFixed(2) + "").replace(
                ".",
                ","
              )}
              &nbsp;€ - Revenus&nbsp;:{" "}
              {((Math.round(income * 100) / 100).toFixed(2) + "").replace(
                ".",
                ","
              )}
              &nbsp;€
            </div>
          </div>
        );
      } else {
        weekSales.push(
          <div key={i}>
            <h6 className="card-body-title">
              SEMAINE {week.from.week()} : LUNDI{" "}
              {week.from.format("D MMMM YYYY")} - DIMANCHE{" "}
              {week.to.format("D MMMM YYYY")} <small>- 0 ventes</small>
            </h6>
            <div className="mg-b-20">Aucune vente pour cette semaine</div>
          </div>
        );
      }
      return ++i;
    });
    return weekSales;
  }

  renderChart() {
    return (
      <ResponsiveBar
        data={this.state.salesData.slice().reverse()}
        keys={["STRIPE", "ALMA", "GOOGLE", "APPLE", "INVITE", "RETAIL_CODE"]}
        indexBy="weekNumber"
        margin={{ top: 10, right: 30, bottom: 40, left: 50 }}
        padding={0.3}
        colors={[
          "rgb(166, 206, 227)",
          "#f0c234",
          "rgb(55, 126, 184)",
          "rgb(178, 223, 138)",
          "#F60",
          "rgb(172, 118, 183)",
        ]}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Numéro de semaine",
          legendPosition: "middle",
          legendOffset: 32,
        }}
        axisRight={{
          enable: true,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Nombre de licences vendues",
          legendPosition: "middle",
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        animate={true}
        motionStiffness={90}
        layers={["grid", "axes", "bars", "markers", chartLineLayer]}
      />
    );
  }

  renderNav(position) {
    const margin =
      position === "top"
        ? "row-sm mg-l-0 pull-right mg-b-20"
        : "row-sm mg-l-0 pull-right";
    return (
      <div className={margin}>
        <span className="mg-r-5">Semaines :</span>
        <button
          className="mg-r-10"
          onClick={() =>
            this.setState({ weekCount: this.state.weekCount + 1 }, () =>
              this.refreshSales()
            )
          }
        >
          +1
        </button>
        <button
          className="mg-r-10"
          onClick={() =>
            this.setState({ weekCount: this.state.weekCount + 2 }, () =>
              this.refreshSales()
            )
          }
        >
          +2
        </button>
        <button
          className="mg-r-10"
          onClick={() =>
            this.setState({ weekCount: this.state.weekCount + 5 }, () =>
              this.refreshSales()
            )
          }
        >
          +5
        </button>
        <button
          className="mg-r-10"
          onClick={() =>
            this.setState({ weekCount: this.state.weekCount + 10 }, () =>
              this.refreshSales()
            )
          }
        >
          +10
        </button>
      </div>
    );
  }

  render() {
    return (
      <Layout>
        <nav className="breadcrumb sl-breadcrumb">
          <a className="breadcrumb-item" href="/dashboard">
            Dashboard
          </a>
          <span className="breadcrumb-item active">Ventes par semaines</span>
        </nav>
        <div className="sl-pagebody">
          <div className="row row-sm">
            <div className="col-xl-3">
              <div className="card pd-10 pd-sm-20 mg-b-20">
                <h6 className="card-body-title">SERVICES DE VENTES EXTERNES</h6>
                <div className="table-wrapper bg-gray">
                  <button
                    className="mg-r-10"
                    onClick={() =>
                      window.open(`https://dashboard.stripe.com/`, "_blank")
                    }
                  >
                    Stripe
                  </button>
                  <button
                    className="mg-r-10"
                    onClick={() =>
                      window.open(`https://dashboard.getalma.eu/`, "_blank")
                    }
                  >
                    Alma
                  </button>
                  <button
                    className="mg-r-10"
                    onClick={() =>
                      window.open(
                        `https://play.google.com/apps/publish/?account=8289039352146858351#OrderManagementPlace`,
                        "_blank"
                      )
                    }
                  >
                    Google
                  </button>
                  <button
                    className="mg-r-10"
                    onClick={() =>
                      window.open(
                        `https://reportingitc2.apple.com/sales.html?measure=total_tax_usd_utc`,
                        "_blank"
                      )
                    }
                  >
                    Apple
                  </button>
                </div>
              </div>
            </div>
            <div className="col-xl-9">
              <div className="card pd-10 pd-sm-20 mg-b-20">
                <div
                  className="table-wrapper bg-gray"
                  style={{ height: "200px" }}
                >
                  {this.renderChart()}
                </div>
              </div>
            </div>
          </div>
          <div className="row row-sm">
            <div className="col-xl-12">
              <div className="card pd-10 pd-sm-20">
                {this.renderNav("top")}
                {this.renderSales()}
                {this.renderNav("bottom")}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Sales;
