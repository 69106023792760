import "react-table/react-table.css";

import React, { Component } from "react";

import API from "../utils/API";
import Layout from "../components/Layout";
import ReactTable from "react-table";
import moment from "moment";

class Dashboard extends Component {
  state = {
    licensedUsers: [],
    licensedUsersPages: -1,
    licensedUsersLoading: false,
  };

  constructor() {
    super();
    this.api = new API();
    this.buildLicensedUsersTableColumns();
  }

  fetchLicensedUsers = async (state, instance) => {
    this.setState(
      {
        licensedUsersLoading: true,
        licensedUsers: await this.api.getLicensedUsers(
          state.pageSize,
          state.page + 1,
          "IMMERSIVE_THERAPY"
        ),
      },
      () => {
        console.warn("licensedUsers", this.state.users);
        this.setState({
          licensedUsersLoading: false,
          licensedUsersPages: 100,
        });
      }
    );
  };

  buildLicensedUsersTableColumns() {
    this.licensedUsersTableColumns = [
      {
        id: "subscriptionDate",
        Header: "Création",
        filterable: false,
        sortable: false,
        accessor: (u) =>
          u.subscriptionDate
            ? moment(u.subscriptionDate).format("ddd DD MMM YYYY")
            : "",
        width: 135,
      },
      {
        id: "events",
        Header: "Evt",
        accessor: (u) => u.eventCount,
        maxWidth: 45,
        filterable: false,
        sortable: false,
        Cell: (row) => {
          if (row.value > 0) {
            return <div className="tx-center">{row.value}</div>;
          }
          return <div />;
        },
      },
      {
        id: "action",
        Header: "-",
        filterable: false,
        sortable: false,
        width: 25,
        Cell: (row) => (
          <a href={`/user?id=${row.row._original.id}`}>
            <i className="fa fa-eye"></i>
          </a>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: false,
        sortable: false,
      },
      {
        id: "os",
        Header: "OS",
        accessor: (u) => u.eventOS,
        maxWidth: 32,
        filterable: false,
        sortable: false,
        Cell: (row) => <div className={row.value} />,
      },
      {
        id: "lang",
        Header: "Lang",
        accessor: (u) => u.eventLang,
        maxWidth: 55,
        filterable: false,
        sortable: false,
        Cell: (row) => (
          <div className={`flag ${row.value}`}>
            <span>{row.value}</span>
          </div>
        ),
      },
      {
        id: "version",
        Header: "Version",
        accessor: (u) => u.eventVersion,
        maxWidth: 70,
        filterable: false,
        sortable: false,
      },
      {
        id: "offer",
        Header: "Offre",
        accessor: (u) =>
          u.license
            ? u.license.product
              ? u.license.product.offer.substring(0, 10).toLowerCase()
              : "error"
            : "-",
        maxWidth: 70,
        filterable: false,
        sortable: false,
      },
      {
        id: "activationDate",
        Header: "Activation",
        accessor: (u) =>
          u.license && u.license.activationDate
            ? moment(u.license.activationDate, "X").format("YYYY-MM-DD")
            : "",
        width: 105,
        sortable: false,
        filterable: false,
      },
      {
        id: "expirationDate",
        Header: "Expiration",
        accessor: (u) =>
          u.license && u.license.expirationDate
            ? moment(u.license.expirationDate, "X").format("YYYY-MM-DD")
            : "",
        width: 105,
        sortable: false,
        filterable: false,
      },
      {
        id: "action",
        Header: "Id",
        sortable: false,
        filterable: false,
        width: 25,
        Cell: (row) => (
          <div className="exportContainer">
            <button
              onClick={() => copyToClipboard(row.row._original.id)}
              title={row.row._original.id}
              className="fa fa-copy"
            />
          </div>
        ),
        filterAll: true,
      },
    ];
  }

  renderLicensedUsersCard = () => {
    return (
      <div className="card pd-20 pd-sm-40 mg-b-20">
        <h6 className="card-body-title">Utilisateurs avec licence offerte</h6>
        <div className="table-wrapper bg-gray">
          <ReactTable
            style={{ width: "100%" }}
            defaultPageSize={50}
            data={this.state.licensedUsers}
            pages={this.state.licensedUsersPages}
            loading={this.state.licensedUsersLoading}
            manual
            onFetchData={this.fetchLicensedUsers}
            columns={this.licensedUsersTableColumns}
            filterable={true}
            className="no-filters -striped -highlight"
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]).includes(filter.value)
            }
            defaultSorted={[{ id: "activationDate", desc: true }]}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <Layout>
        <div className="sl-pagebody">
          <div className="row row-sm">
            <div className="col-xl-12">{this.renderLicensedUsersCard()}</div>
          </div>
        </div>
      </Layout>
    );
  }
}

function copyToClipboard(data) {
  var $body = document.getElementsByTagName("body")[0];
  var $tempInput = document.createElement("INPUT");
  $body.appendChild($tempInput);
  $tempInput.setAttribute("value", data);
  $tempInput.select();
  document.execCommand("copy");
  $body.removeChild($tempInput);
}

export default Dashboard;
