import "react-table/react-table.css";

import React, { Component } from "react";

import API from "../utils/API";
import Layout from "../components/Layout";
import ReactTable from "react-table";
import moment from "moment";

const now = moment();
class Dashboard extends Component {
  state = {
    users: [],
    usersPages: -1,
    usersLoading: false,
  };

  constructor() {
    super();
    this.api = new API();
    this.buildUsersTableColumns();
  }

  fetchUsers = async (state, instance) => {
    let search = "";
    state.filtered.map((item) => {
      if (item.id === "email") {
        search = item.value;
      }
      return item;
    });
    this.setState(
      {
        usersLoading: true,
        users: await this.api.getUsers(state.pageSize, state.page + 1, search),
      },
      () => {
        console.warn("users", this.state.users);
        this.setState({
          usersLoading: false,
          usersPages: 100,
        });
      }
    );
  };

  buildUsersTableColumns() {
    this.usersTableColumns = [
      {
        id: "subscriptionDate",
        Header: "Création",
        filterable: false,
        sortable: false,
        accessor: (u) =>
          u.subscriptionDate
            ? u.subscriptionDate === +u.subscriptionDate
              ? moment(u.subscriptionDate, "X").format("YYYY-MM-DD")
              : moment(u.subscriptionDate).format("YYYY-MM-DD")
            : "",
        width: 105,
      },
      {
        id: "action",
        Header: "-",
        filterable: false,
        sortable: false,
        width: 25,
        Cell: (row) => (
          <a href={`/user?id=${row.row._original.id}`}>
            <i className="fa fa-eye"></i>
          </a>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        sortable: false,
        Cell: (row) => {
          if (row.row._original.birthDate) {
            const age = now.diff(moment(row.row._original.birthDate), "years");
            return (
              <div>
                {row.row._original.email}{" "}
                <span className="small">
                  - {row.row._original.firstName} {row.row._original.lastName} -{" "}
                  {age} ans
                </span>
              </div>
            );
          } else if (row.row._original.firstName) {
            return (
              <div>
                {row.row._original.email}{" "}
                <span className="small">
                  - {row.row._original.firstName} {row.row._original.lastName}
                </span>
              </div>
            );
          } else {
            return <div>{row.row._original.email}</div>;
          }
        },
      },
      {
        id: "action",
        Header: "Id",
        sortable: false,
        filterable: false,
        width: 25,
        Cell: (row) => (
          <div className="exportContainer">
            <button
              onClick={() => copyToClipboard(row.row._original.id)}
              title={row.row._original.id}
              className="fa fa-copy"
            />
          </div>
        ),
        filterAll: true,
      },
    ];
  }

  renderUsersCard = () => {
    return (
      <div className="card pd-20 pd-sm-40 mg-b-20">
        <h6 className="card-body-title">Tous les utilisateurs</h6>
        <div className="table-wrapper bg-gray">
          <ReactTable
            style={{ width: "100%" }}
            defaultPageSize={10}
            data={this.state.users}
            pages={this.state.usersPages}
            loading={this.state.usersLoading}
            manual
            onFetchData={this.fetchUsers}
            columns={this.usersTableColumns}
            filterable={true}
            className="-striped -highlight"
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]).includes(filter.value)
            }
            defaultSorted={[{ id: "subscriptionDate", desc: true }]}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <Layout>
        <nav className="breadcrumb sl-breadcrumb">
          <a className="breadcrumb-item" href="/dashboard">
            Dashboard
          </a>
          <span className="breadcrumb-item active">Tous les utilisateurs</span>
        </nav>

        <div className="sl-pagebody">
          <div className="row row-sm">
            <div className="col-xl-12">{this.renderUsersCard()}</div>
          </div>
        </div>
      </Layout>
    );
  }
}

function copyToClipboard(data) {
  var $body = document.getElementsByTagName("body")[0];
  var $tempInput = document.createElement("INPUT");
  $body.appendChild($tempInput);
  $tempInput.setAttribute("value", data);
  $tempInput.select();
  document.execCommand("copy");
  $body.removeChild($tempInput);
}

export default Dashboard;
