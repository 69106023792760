/* eslint-disable jsx-a11y/accessible-emoji */
import "react-table/react-table.css";
import "antd/dist/antd.css";

import React, { Component } from "react";

import API from "../utils/API";
import { DatePicker } from "antd";
import Layout from "../components/Layout";
import { ResponsiveBar } from "@nivo/bar";
// import { ResponsiveLine } from "@nivo/line";
import moment from "moment";

const { RangePicker } = DatePicker;

const SURVEYS = {
  ASSOCIATIONSORT_END_SURVEY: {
    code: "ASSOCIATIONSORT_END_SURVEY",
    label: "Méli-Mélo",
    review: "data.rate.review",
  },
  CONFIG_RI_DIAG: {
    code: "CONFIG_RI_DIAG",
    label: "Post Diagnostic IR",
    review: "data.rate.review",
  },
  CONSTELLATIONS_END_SURVEY: {
    code: "CONSTELLATIONS_END_SURVEY",
    label: "Constellations",
    review: "data.rate.review",
  },
  COHERENTBREATHING_END_SURVEY: {
    code: "COHERENTBREATHING_END_SURVEY",
    label: "Cohérence cardiaque",
    review: "data.rate.review",
  },
  LOSTRECIPE_END_SURVEY: {
    code: "LOSTRECIPE_END_SURVEY",
    label: "Recette perdue",
    review: "data.rate.review",
  },
  SQUAREBREATHING_END_SURVEY: {
    code: "SQUAREBREATHING_END_SURVEY",
    label: "Respiration carrée",
    review: "data.rate.review",
  },
  THEISLAND_END_SURVEY: {
    code: "THEISLAND_END_SURVEY",
    label: "L'île",
    review: "data.rate.review",
  },
  TEMPO_END_SURVEY: {
    code: "TEMPO_END_SURVEY",
    label: "Tempo",
    review: "data.rate.review",
  },
  TOYFACTORY_END_SURVEY: {
    code: "TOYFACTORY_END_SURVEY",
    label: "L'usine à jouets",
    review: "data.rate.review",
  },
  PLINKO_END_SURVEY: {
    code: "PLINKO_END_SURVEY",
    label: "Plinko",
    review: "data.rate.review",
  },
};

const colors = {
  app1: "#d36259",
  1: "#d36259",
  app2: "#ef7e14",
  2: "#ef7e14",
  app3: "#ffc105",
  3: "#ffc105",
  app4: "#bfd047",
  4: "#bfd047",
  app5: "#0e9d58",
  5: "#0e9d58",
  diag1: "#ff6259",
  diag2: "#ff7e14",
  diag3: "#fff105",
  diag4: "#bff047",
  diag5: "#0eff58",
};

class Surveys extends Component {
  state = {
    onlyReview: false,
    from: moment().startOf("month").subtract(6, "months"),
    to: moment(),
    step: "month",
    tickCount: "1",
    detailChartData: [],
  };

  constructor() {
    super();
    this.api = new API();
  }

  componentDidMount() {
    this.setState(
      {
        survey:
          (window.location.search &&
            SURVEYS[window.location.search.split("=")[1]]) ||
          SURVEYS["COHERENTBREATHING_END_SURVEY"],
      },
      () => {
        this.refreshResults();
      }
    );
  }

  refreshResults = async () => {
    const { survey, from, to, onlyReview } = this.state;
    this.setState(
      {
        rawData: await this.api.getSurveys(
          onlyReview,
          survey.code,
          survey.review,
          from.unix(),
          to.unix()
        ),
      },
      this.redrawResults
    );
  };

  redrawResults = async () => {
    const { step } = this.state;
    const timeData = {};

    // eslint-disable-next-line array-callback-return
    this.state.rawData.map((result) => {
      let period;
      if (step === "week") {
        const resultDate = moment(result.date, "X");
        period = moment()
          .day("Thursday")
          .isoWeekYear(resultDate.isoWeekYear())
          .isoWeek(resultDate.isoWeek())
          .format("YYYY-MM-DD");
      } else {
        period = moment(result.date, "X").format("YYYY-MM-01");
      }
      if (!timeData[period]) {
        timeData[period] = {
          _id: period,
        };
      }
      if (result.data.rate.rate) {
        if (!timeData[period][result.data.rate.rate]) {
          timeData[period][result.data.rate.rate] = 0;
        }
        timeData[period][result.data.rate.rate] += 1;
      } else if (result.data.rate.app) {
        if (!timeData[period]["app" + result.data.rate.app]) {
          timeData[period]["app" + result.data.rate.app] = 0;
        }
        if (!timeData[period]["diag" + result.data.rate.app]) {
          timeData[period]["diag" + result.data.rate.diag] = 0;
        }
        timeData[period]["app" + result.data.rate.app] += 1;
        timeData[period]["diag" + result.data.rate.diag] += 1;
      }
    });
    const tickCount = Math.ceil((Object.keys(timeData).length - 1) / 20);

    this.setState(
      {
        tickCount,
        detailChartData: Object.values(timeData).sort((a, b) =>
          a._id > b._id ? 1 : -1
        ),
      },
      () => console.warn("timDtae", this.state.detailChartData)
    );
  };

  render() {
    if (!this.state.survey) {
      return null;
    }
    return (
      <Layout>
        <div className="sl-pagebody">
          <div className="row row-sm">
            <div className="col-xl-12">{this.renderChart()}</div>
            <div className="col-xl-12">{this.renderReviews()}</div>
          </div>
        </div>
      </Layout>
    );
  }

  renderReviews() {
    return (
      <div className="card mg-b-20 pd-t-20 pd-l-20 pd-r-20">
        <div className="card-head">
          <h6 className="card-body-title">
            {this.state.rawData && this.state.rawData.length} Résultats
          </h6>
        </div>
        <div className="mg-b-20">
          <table className="table table-striped">
            <thead>
              {this.state.survey.code !== "CONFIG_RI_DIAG" ? (
                <tr>
                  <th>Vers.</th>
                  <th className="tx-center">Util.</th>
                  <th className="tx-center">Note</th>
                  <th>Commentaire</th>
                  <th className="tx-center">Date</th>
                </tr>
              ) : (
                <tr>
                  <th>Vers.</th>
                  <th />
                  <th>App</th>
                  <th>Diag</th>
                  <th>Commentaire</th>
                  <th>Date</th>
                </tr>
              )}
            </thead>
            <tbody>
              {this.state.rawData &&
                this.state.rawData.map((item) => {
                  if (item.data.topic === "CONFIG_RI_DIAG") {
                    return (
                      <tr>
                        <td>{item.version}</td>
                        <td>
                          <a href={`/user?id=${item.user}`}>🙋</a>
                        </td>
                        <td>{item.data.rate.app}/5</td>
                        <td>
                          {item.data.rate.diag === "skip"
                            ? "-"
                            : `${item.data.rate.diag}/5`}
                        </td>
                        <td>{item.data.rate.review}</td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {moment(item.date, "X").format("YYYY-MM-DD")}
                        </td>
                      </tr>
                    );
                  }
                  if (item.data.rate.review) {
                    return (
                      <tr>
                        <td>{item.version}</td>
                        <td className="tx-center">
                          <a href={`/user?id=${item.user}`}>
                            {item.user.substr(-4)}
                          </a>
                        </td>
                        <td className="tx-center">{item.data.rate.rate}</td>
                        <td>{item.data.rate.review}</td>
                        <td
                          className="tx-center"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {moment(item.date, "X").format("YYYY-MM-DD")}
                        </td>
                      </tr>
                    );
                  }
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderChart() {
    return (
      <div className="card mg-b-20 pd-t-20 pd-l-20 pd-r-20">
        <div className="card-head">
          <select
            className="survey-select"
            defaultValue={this.state.survey.code}
            onChange={(event) => {
              this.setState(
                { survey: SURVEYS[event.target.value] },
                () =>
                  (window.location.href =
                    "/surveys?topic=" + this.state.survey.code)
              );
            }}
          >
            {Object.values(SURVEYS).map((survey, index) => (
              <option key={index} value={survey.code}>
                {survey.label}
              </option>
            ))}
          </select>
          <RangePicker
            defaultValue={[this.state.from, this.state.to]}
            allowClear={false}
            format="D MMM YYYY"
            ranges={{
              "Toute la période": [moment("2018-10-10"), moment()],
              "Les 14 derniers mois": [
                moment().startOf("month").subtract(13, "months"),
                moment(),
              ],
              "Les 12 derniers mois complets": [
                moment().startOf("month").subtract(12, "months"),
                moment().endOf("month").subtract(1, "month"),
              ],
              "Les 6 derniers mois": [
                moment().startOf("month").subtract(6, "months"),
                moment(),
              ],
              "Les 6 derniers mois complets": [
                moment().startOf("month").subtract(6, "months"),
                moment().endOf("month").subtract(1, "month"),
              ],
              "Les 3 derniers mois": [
                moment().startOf("month").subtract(3, "months"),
                moment(),
              ],
              "Les 3 derniers mois complets": [
                moment().startOf("month").subtract(3, "months"),
                moment().endOf("month").subtract(1, "month"),
              ],
            }}
            onChange={(dates) => {
              this.setState(
                { from: dates[0], to: dates[1] },
                this.refreshResults
              );
            }}
          />
          <select
            className="step-select"
            onChange={(event) => {
              this.setState({ step: event.target.value }, this.redrawResults);
            }}
          >
            <option value="month">par mois</option>
            <option value="week">par semaine</option>
          </select>
        </div>
        <div className="table-wrapper bg-gray" style={{ height: "300px" }}>
          <ResponsiveBar
            data={this.state.detailChartData || []}
            keys={[
              "1",
              "2",
              "3",
              "4",
              "5",
              "app1",
              "app2",
              "app3",
              "app4",
              "app5",
              "diag1",
              "diag2",
              "diag3",
              "diag4",
              "diag5",
            ]}
            indexBy="_id"
            margin={{ top: 10, right: 0, bottom: 20, left: 30 }}
            padding={0.3}
            colors={(line) => colors[line.id]}
            labelSkipWidth={12}
            labelSkipHeight={12}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
          />
          {/*<ResponsiveLine
            data={this.state.chartData || []}
            margin={{ top: 10, right: 40, bottom: 58, left: 10 }}
            padding={0.3}
            colors={{ scheme: "spectral" }}
            axisRight={{
              enable: true,
            }}
            curve="monotoneX"
            axisLeft={null}
            xScale={{
              type: "time",
              format: "%Y-%m-%d",
              precision: this.state.step === "week" ? "day" : "day",
            }}
            xFormat="time:%d %B %Y"
            axisBottom={{
              format: this.state.step === "week" ? "%d/%m" : "%b",
              tickValues:
                this.state.step === "week"
                  ? `every ${this.state.tickCount} monday`
                  : `every ${this.state.tickCount} month`,
            }}
            yScale={{
              type: "linear",
              min: 0,
            }}
            yFormat={(value) => `${value} résultats`}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 15,
                translateY: 50,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 8,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
            useMesh={true}
            enableArea={true}
            enableGridY={true}
            enableGridX={false}
          />*/}
        </div>
      </div>
    );
  }
}

/*function copyToClipboard(data) {
  var $body = document.getElementsByTagName('body')[0];
  var $tempInput = document.createElement('INPUT');
  $body.appendChild($tempInput);
  $tempInput.setAttribute('value', data)
  $tempInput.select();
  document.execCommand('copy');
  $body.removeChild($tempInput);
}*/

export default Surveys;
