/* eslint-disable array-callback-return */
import React, { Component } from "react";

import Radar from "./Radar";
import moment from "moment";

class WFURadar extends Component {
  render() {
    if (this.props.wfus && this.props.selectedWfu2) {
      let lastWFUValues = [-1, -1, -1, -1, -1, -1, -1, -1];
      let firstWFUValues = [-1, -1, -1, -1, -1, -1, -1, -1];
      let lastWFU = null;
      let firstWFU = null;
      lastWFU = this.props.wfus[this.props.selectedWfu2 - 1];
      lastWFUValues = Object.values(lastWFU.subScores);
      if (this.props.wfus.length > 1 && this.props.selectedWfu1) {
        firstWFU = this.props.wfus[this.props.selectedWfu1 - 1];
        firstWFUValues = Object.values(firstWFU.subScores);
      }

      return (
        <div className="wfu">
          <h4 className="wfuIndex">
            <a href={`/user?id=${this.props.user}`}>#{this.props.index}</a>
          </h4>
          <Radar values1={lastWFUValues} values2={firstWFUValues} />
          {lastWFU ? (
            <div style={{ color: "blue" }}>
              WFU #{this.props.selectedWfu2}&nbsp;
              {moment(lastWFU.date, "X").fromNow()} (
              {moment(lastWFU.date, "X").format("ddd D/MM/YY à HH:mm")}) :{" "}
              <strong>{80 - lastWFU.score} pts confort</strong>
            </div>
          ) : null}
          {firstWFU ? (
            <div style={{ color: "#F60" }}>
              WFU #{this.props.selectedWfu1}{" "}
              {moment(firstWFU.date, "X").fromNow()} (
              {moment(firstWFU.date, "X").format("ddd D/MM/YY à HH:mm")}) :{" "}
              <strong>{80 - firstWFU.score} pts confort</strong>
            </div>
          ) : null}
        </div>
      );
    }
  }
}

export default WFURadar;
