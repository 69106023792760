import React, { Component } from "react";

import API from "../utils/API";
import Layout from "../components/Layout";

class FAQScreen extends Component {
  state = {
    faqs: [],
  };

  constructor(url) {
    super();
    this.api = new API();
  }

  componentDidMount() {
    this.updateData();
  }

  async updateData() {
    this.setState({ faqs: await this.api.getFAQ() });
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name.split(";");

    const faqs = this.state.faqs;
    if (name.length === 2) {
      faqs[name[0]][name[1]] = value;
    } else {
      faqs[name[0]][name[1]][name[2]][name[3]] = value;
    }

    this.setState({
      faqs,
    });
  };

  addQA = (cIndex) => {
    const faqs = this.state.faqs;
    if (faqs[cIndex].questions) {
      faqs[cIndex].questions[(faqs[cIndex].questions || []).length] = {
        code: "",
        label: "",
        answer: "",
        tags: "",
        scope: "",
      };
    } else {
      faqs[cIndex].questions = [
        { code: "", label: "", answer: "", tags: "", scope: "" },
      ];
    }

    this.setState({ faqs });
  };

  addFAQ = () => {
    const faqs = this.state.faqs;
    faqs.unshift({
      code: "",
      label: "",
      order: -1,
      lang: "fr",
      questions: [
        {
          code: "",
          label: "",
          answer: "",
          tags: "",
          scope: "",
        },
      ],
    });
    this.setState({ faqs });
  };

  submitForm = async (e) => {
    e.preventDefault();

    const orderedFaqs = this.state.faqs.sort((a, b) =>
      a.order > b.order ? 1 : -1
    );
    const toDeleteFaqs = [];

    let counter = {};
    let faqs = orderedFaqs.map((faq) => {
      if (faq.questions) {
        faq.questions = faq.questions.filter(
          (question) =>
            question.label.trim() !== "" || question.answer.trim() !== ""
        );
      }

      if (faq.label === "" && faq.questions.length === 0) {
        toDeleteFaqs.push(faq._id);
        return null;
      }

      if (!counter[faq.lang]) {
        counter[faq.lang] = 0;
      }
      faq.order = counter[faq.lang];
      counter[faq.lang]++;
      return faq;
    });
    faqs = faqs.filter((item) => !!item);
    try {
      this.setState({ faqs });
      await this.api.updateFAQ(faqs, toDeleteFaqs);
      await this.updateData();
    } catch (e) {
      console.warn("Erreur submitForm", e);
      alert(`Problème lors de la mise à jour… ${e}`);
    }
  };

  renderFAQMap(lang, cIndex) {
    if (lang === this.state.faqs[cIndex].lang) {
      return (
        <li>
          {this.state.faqs[cIndex].label} (
          {this.state.faqs[cIndex].questions.length})
          <ul>
            {this.state.faqs[cIndex].questions.map((question, qIndex) => {
              return <li>{this.state.faqs[cIndex].questions[qIndex].label}</li>;
            })}
          </ul>
        </li>
      );
    }
  }

  renderFAQ(cIndex) {
    if (!this.state.lang || this.state.lang === this.state.faqs[cIndex].lang)
      return (
        <div className="row row-sm mg-l-5 mg-r-5" key={`c${cIndex}`}>
          <div className="col-lg-8">
            <div className="input-group">
              <span className="input-group-addon">
                <i className="icon ion-document tx-16 lh-0 op-6"></i>
              </span>
              <input
                className="form-control"
                name={`${cIndex};label`}
                type="text"
                value={this.state.faqs[cIndex].label}
                onChange={this.handleChange}
                placeholder="Libellé de la catégorie"
              />
            </div>
          </div>
          <div className="col-lg-2">
            <div className="input-group">
              <span className="input-group-addon">
                <i className="icon ion-link tx-16 lh-0 op-6"></i>
              </span>
              <input
                className="form-control"
                name={`${cIndex};code`}
                type="text"
                value={this.state.faqs[cIndex].code}
                onChange={this.handleChange}
                placeholder="Code"
              />
            </div>
          </div>

          <div className="col-lg-1">
            <div className="input-group">
              <span className="input-group-addon">
                <i className="icon ion-planet tx-16 lh-0 op-6"></i>
              </span>
              <input
                className="form-control"
                name={`${cIndex};lang`}
                type="text"
                value={this.state.faqs[cIndex].lang}
                onChange={this.handleChange}
                placeholder="fr"
              />
            </div>
          </div>
          <div className="col-lg-1">
            <div className="input-group">
              <span className="input-group-addon">
                <i className="icon ion-arrow-up-a tx-16 lh-0 op-6"></i>
              </span>
              <input
                className="form-control"
                name={`${cIndex};order`}
                type="text"
                value={this.state.faqs[cIndex].order}
                onChange={this.handleChange}
                placeholder="0"
              />
            </div>
          </div>
          <div className="col-lg-12" key={`cb${cIndex}`}>
            <label className="mg-t-10 form-control-label">
              Questions&nbsp;: (
              {(this.state.faqs[cIndex].questions &&
                this.state.faqs[cIndex].questions.length) ||
                0}
              )
            </label>
            {this.state.faqs[cIndex].questions &&
              this.state.faqs[cIndex].questions.map((question, qIndex) => {
                return this.renderQA(cIndex, qIndex);
              })}
            <button
              className="btn btn-dark mg-r-5 mg-b-20"
              onClick={() => this.addQA(cIndex)}
            >
              Ajouter une question
            </button>
          </div>
        </div>
      );
  }

  renderQA(cIndex, qIndex) {
    return (
      <div key={`c${cIndex}q${qIndex}`} className="mg-b-10">
        <div className="row row-sm">
          <div className="col-lg-10">
            <div className="input-group mg-b-1">
              <span className="input-group-addon" title="Question">
                <code>Q</code>
              </span>
              <input
                className="form-control"
                name={`${cIndex};questions;${qIndex};label`}
                type="text"
                value={this.state.faqs[cIndex].questions[qIndex].label || ""}
                onChange={this.handleChange}
                placeholder="Question"
              />
            </div>
          </div>
          <div className="col-lg-2 mg-b-1">
            <div className="input-group">
              <span className="input-group-addon" title="Question">
                <code>C</code>
              </span>
              <input
                className="form-control"
                name={`${cIndex};questions;${qIndex};code`}
                type="text"
                value={this.state.faqs[cIndex].questions[qIndex].code || ""}
                onChange={this.handleChange}
                placeholder="Code"
              />
            </div>
          </div>

          <div className="col-lg-10">
            <div className="input-group">
              <span className="input-group-addon" title="Tags">
                <code>T</code>
              </span>
              <input
                className="form-control"
                name={`${cIndex};questions;${qIndex};tags`}
                type="text"
                onChange={this.handleChange}
                value={this.state.faqs[cIndex].questions[qIndex].tags || ""}
                placeholder="Tags"
              />
            </div>
          </div>
          <div className="col-lg-2">
            <div className="input-group">
              <span className="input-group-addon" title="OS">
                <code>S</code>
              </span>
              <input
                className="form-control"
                name={`${cIndex};questions;${qIndex};scope`}
                type="text"
                onChange={this.handleChange}
                value={this.state.faqs[cIndex].questions[qIndex].scope || ""}
                placeholder="android, ios, vide"
              />
            </div>
          </div>

          <div className="col-lg-12 mg-t-1">
            <div className="input-group">
              <span className="input-group-addon" title="Réponse">
                <code>R</code>
              </span>
              <textarea
                className="form-control"
                name={`${cIndex};questions;${qIndex};answer`}
                onChange={this.handleChange}
                value={this.state.faqs[cIndex].questions[qIndex].answer || ""}
                placeholder="Réponse"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Layout>
        <nav className="breadcrumb sl-breadcrumb">
          <a className="breadcrumb-item" href="/dashboard">
            Dashboard
          </a>
          <span className="breadcrumb-item active">FAQ</span>
        </nav>
        <div className="sl-pagebody">
          <div className="row row-sm mg-b-20">
            <div className="col-md-12">
              <div className="card pd-t-15">
                <h6 class="card-body-title  pd-l-20">
                  Plan des FAQs&nbsp;
                  <img
                    style={{ position: "relative", top: -1 }}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAGzSURBVHjaYiyeepkBBv79+Zfnx/f379+fP38CyT9//jAyMiq5GP77wvDnJ8MfoAIGBoAAYgGqC7STApL///3/9++/pCTv////Qdz/QO4/IMna0vf/z+9/v379//37bUUTQACBNDD8Z/j87fffvyAVX79+/Q8GQDbQeKA9fM+e/Pv18/+vnwzCIkBLAAKQOAY5AIAwCEv4/4PddNUm3ji0QJyxW3rgzE0iLfqDGr2oYuu0l54AYvnz5x9Q6d+/QPQfyAQqAin9B3EOyG1A1UDj//36zfjr1y8GBoAAFI9BDgAwCMIw+P8Ho3GDO6XQ0l4MN8b2kUwYaLszqgKM/KHcDXwBxAJUD3TJ779A8h9Q5D8SAHoARP36+Rfo41+/mcA2AAQQy49ff0Cu//MPpAeI/0FdA1QNYYNVA/3wmwEYVgwMAAHE8uPHH5BqoD1//gJJLADoJKDS378Z//wFhhJAALF8A3rizz8uTmYg788fJkj4QOKREQyYxSWBhjEC/fcXZANAALF8+/anbcHlHz9+ffvx58uPX9KckkCn/gby/wLd8uvHjx96k+cD1UGiGQgAAgwA7q17ZpsMdUQAAAAASUVORK5CYII="
                    alt="fr"
                  />
                  &nbsp;et&nbsp;
                  <img
                    style={{ position: "relative", top: -1 }}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAflJREFUeNpinDRzn5qN3uFDt16+YWBg+Pv339+KGN0rbVP+//2rW5tf0Hfy/2+mr99+yKpyOl3Ydt8njEWIn8f9zj639NC7j78eP//8739GVUUhNUNuhl8//ysKeZrJ/v7z10Zb2PTQTIY1XZO2Xmfad+f7XgkXxuUrVB6cjPVXef78JyMjA8PFuwyX7gAZj97+T2e9o3d4BWNp84K1NzubTjAB3fH0+fv6N3qP/ir9bW6ozNQCijB8/8zw/TuQ7r4/ndvN5mZgkpPXiis3Pv34+ZPh5t23//79Rwehof/9/NDEgMrOXHvJcrllgpoRN8PFOwy/fzP8+gUlgZI/f/5xcPj/69e/37//AUX+/mXRkN555gsOG2xt/5hZQMwF4r9///75++f3nz8nr75gSms82jfvQnT6zqvXPjC8e/srJQHo9P9fvwNtAHmG4f8zZ6dDc3bIyM2LTNlsbtfM9OPHH3FhtqUz3eXX9H+cOy9ZMB2o6t/Pn0DHMPz/b+2wXGTvPlPGFxdcD+mZyjP8+8MUE6sa7a/xo6Pykn1s4zdzIZ6///8zMGpKM2pKAB0jqy4UE7/msKat6Jw5mafrsxNtWZ6/fjvNLW29qv25pQd///n+5+/fxDDVbcc//P/zx/36m5Ub9zL8+7t66yEROcHK7q5bldMBAgwADcRBCuVLfoEAAAAASUVORK5CYII="
                    alt="en"
                  />
                  &nbsp;et&nbsp;
                  <img
                    style={{ position: "relative", top: -1 }}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAFnSURBVHjaYvzPgAD/UNlYEUAAmuTYAAAQhAEYqF/zFbe50RZ1cMmS9TLi0pJLRjZohAMTGFUN9HdnHgEE1sDw//+Tp0ClINW/f0NIKPoFJH/9//ULyGaUlQXaABBALAx/Gf4zAt31F4i+ffj3/cN/XrFfzOx//v///f//LzACM/79ZmD8/e8TA0AAMYHdDVT958vXP38nMDB0s3x94/Tj5y+YahhiAKLfQKUAAcQEdtJfoDHMF2L+vPzDmFXLelf551tGFOOhev4A/QgQQExgHwAd8IdFT/Wz6j+GhlpmXSOW/2z///8Eq/sJ18Dw/zdQA0AAMQExxJjjdy9x2/76EfLz4MXdP/i+wsyGkkA3Aw3984cBIIAYfzIwMKel/bt3jwEaLNAwgZIQxp/fDH/+MqqovL14ESCAWICeZvr9h0FSEhSgwBgAygFDEMT+wwAhgQgc4kAEVAwQQIxfUSMSTxxDAECAAQAJWke8v4u1tAAAAABJRU5ErkJggg=="
                    alt="es"
                  />
                </h6>
                <div className="row row-sm">
                  <div className="col-md-4">
                    <ul>
                      {this.state.faqs.map((_, index) =>
                        this.renderFAQMap("fr", index)
                      )}
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul>
                      {this.state.faqs.map((_, index) =>
                        this.renderFAQMap("es", index)
                      )}
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul>
                      {this.state.faqs.map((_, index) =>
                        this.renderFAQMap("en", index)
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-sm mg-b-20">
            <div className="col-md-12">
              <div className="form-layout-footer">
                <button
                  className="btn btn-primary mg-r-5"
                  onClick={this.submitForm}
                >
                  Sauvegarder tout
                </button>
                <button className="btn btn-dark mg-r-5" onClick={this.addFAQ}>
                  Ajouter une catégorie de FAQ
                </button>
                <button
                  className="btn btn-info mg-r-5"
                  onClick={() => this.setState({ lang: "" })}
                >
                  Voir tout
                </button>
                <button
                  className="btn btn-info mg-r-5"
                  onClick={() => this.setState({ lang: "fr" })}
                >
                  Voir fr
                </button>
                <button
                  className="btn btn-info mg-r-5"
                  onClick={() => this.setState({ lang: "es" })}
                >
                  Voir es
                </button>
                <button
                  className="btn btn-info mg-r-5"
                  onClick={() => this.setState({ lang: "en" })}
                >
                  Voir en
                </button>
              </div>
            </div>
          </div>

          <div className="row row-sm">
            <div className="col-md-12">
              <div className="card pd-t-15">
                {this.state.faqs.map((_, index) => this.renderFAQ(index))}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default FAQScreen;
