import "./index.css";
import "moment/locale/fr";

import * as serviceWorker from "./serviceWorker";

import App from "./App";
import { ConfigProvider } from "antd";
import React from "react";
import ReactDOM from "react-dom";
import fr_FR from "antd/lib/locale-provider/fr_FR";
import moment from "moment";

moment.locale("fr");

ReactDOM.render(
  <ConfigProvider locale={fr_FR}>
    <App />
  </ConfigProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
