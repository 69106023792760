import React, { Component } from "react";

export default class buildWFURadarChart extends Component {
  constructor(props) {
    super(props);
    this.center = 130;
    this.radius = 100;
    this.length = 8;
    this.legSize = 30;
    this.part = (2 * Math.PI) / this.length;
    this.shift = -0.5 * Math.PI + Math.PI / 8;
    this.labels = [
      "Silence",
      "Contrôle",
      "Concentrat°",
      "Sommeil",
      "Audition",
      "Relaxation",
      "Humeur",
      "Sérénité",
    ];
  }

  renderScale() {
    const scale = [];
    for (let i = 0; i < 11; i++) {
      const color = i % 5 === 0 ? "#DDD" : "#EEE";
      scale[i] = (
        <circle
          cx={this.center}
          cy={this.center}
          r={(this.radius / 10) * (i + 1)}
          fill="none"
          stroke={color}
          strokeWidth={1}
        />
      );
    }
    return scale;
  }

  getPointCoordinates(angle, ratio) {
    const x = this.center + this.radius * Math.cos(angle) * ratio;
    const y = this.center + this.radius * Math.sin(angle) * ratio;
    return [x, y];
  }

  getLegCoordinates(i, linePositions) {
    const x = linePositions[0];
    const x2 =
      i < 4 ? linePositions[0] + this.legSize : linePositions[0] - this.legSize;
    const y = linePositions[1];
    return [x, y, x2];
  }

  renderGrid() {
    const linePositions = [];
    const legPositions = [];
    for (let i = 0; i < 8; i++) {
      const angle = this.part * i + this.shift;
      linePositions[i] = this.getPointCoordinates(angle, 1.1);
      legPositions[i] = this.getLegCoordinates(i, linePositions[i]);
    }
    const grid = [];
    linePositions.map((pointPosition) => {
      grid.push(
        <line
          x1={this.center}
          y1={this.center}
          x2={pointPosition[0]}
          y2={pointPosition[1]}
          strokeWidth={0.5}
          stroke="black"
        />
      );
    });
    legPositions.map((pointPosition) => {
      grid.push(
        <line
          x1={pointPosition[2]}
          y1={pointPosition[1]}
          x2={pointPosition[0]}
          y2={pointPosition[1]}
          strokeWidth={0.7}
          stroke="black"
        />
      );
    });
    legPositions.map((pointPosition, i) => {
      const labelMargin = i < 4 ? 2 : -2;
      const textAnchor = i < 4 ? "start" : "end";
      grid.push(
        <text
          fontSize="11"
          textAnchor={textAnchor}
          x={pointPosition[2] + labelMargin}
          y={pointPosition[1] - 3}
        >
          {this.labels[i]}
        </text>
      );
      grid.push(
        <text
          textAnchor={textAnchor}
          x={pointPosition[2] + labelMargin}
          y={pointPosition[1] + 11}
        >
          <tspan fill="blue" fontSize="11">
            {10 - this.props.values1[i]}
          </tspan>
          <tspan fill="#999" fontSize="9">
            /10
          </tspan>
        </text>
      );
    });
    // console.warn('grid.join()', grid.join(''))
    return grid;
  }

  renderPolygon(values, color, fillOpacity) {
    const polygon = [];
    const points = [];
    for (let i = 0; i < this.length; i++) {
      const angle = this.part * i + this.shift;
      const point = this.getPointCoordinates(angle, (10 - values[i] + 1) / 10);
      points.push(point);
    }
    polygon.push(
      <polygon
        points={points.join(" ")}
        fill={color}
        fillOpacity={fillOpacity}
        stroke={color}
        strokeWidth={2}
      />
    );

    points.map((point) => {
      polygon.push(
        <circle cx={point[0]} cy={point[1]} r={3} fill="white" stroke={color} />
      );
    });

    return polygon;
  }

  render() {
    // const output = [];
    // output.push();
    /*output.push(this.renderGrid());
    output.push(this.renderPolygon());
    output.push(this.styles);*/

    let polygons = null;
    if (this.props.values3) {
      polygons = [
        this.renderPolygon(this.props.values3, "#C008", 0),
        this.renderPolygon(this.props.values2, "#0C08", 0),
        this.renderPolygon(this.props.values1, "#00C", 0),
      ];
    } else {
      polygons = [
        this.renderPolygon(this.props.values2, "orange", 0.05),
        this.renderPolygon(this.props.values1, "blue", 0.05),
      ];
    }

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="440"
        height="280"
        viewBox="0 0 256 256"
        version="1.1"
        id="svg"
      >
        <rect width="300%" height="100%" x="-256" fill="#fff" />
        {this.renderScale()}
        {this.renderGrid()}
        {polygons.map((polygon) => polygon)}
        {this.styles}
      </svg>
    );
  }
}
