/* eslint-disable array-callback-return */
import "moment/locale/fr";

import React, { Component } from "react";

import API from "../utils/API";
import Layout from "../components/Layout";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import moment from "moment";

moment.locale("fr");
let buyersLastReferrersAbsoluteData = [];
let buyersLastReferrersRatioData = [];
let nonBuyersAllReferrersAbsoluteData = [];
let nonBuyersAllReferrersRatioData = [];
let nonBuyersLastReferrersAbsoluteData = [];
let nonBuyersLastReferrersRatioData = [];

function dynamicSort(property) {
  var sortOrder = 1;

  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a, b) {
    if (sortOrder === -1) {
      return b[property].localeCompare(a[property]);
    } else {
      return a[property].localeCompare(b[property]);
    }
  };
}

class Stats extends Component {
  state = {
    purgeCache: false,
    selectedWeek1: moment().isoWeek() - 1,
    selectedWeek2: moment().isoWeek(),
    buyersLastReferrersChartData: [],
    nonBuyersAllReferrersChartData: [],
    nonBuyersLastReferrersChartData: [],
    actionsAndProfilesData: {},
    isBuyersLastReferrersAbsolute: true,
    isNonBuyersAllReferrersAbsolute: true,
    isNonBuyersLastReferrersAbsolute: true,
    markersTwoWeeks: [
      {
        axis: "x",
        value: "2019[35-36]",
        lineStyle: { stroke: "#b0413e", strokeWidth: 1 },
        legend: "+capsules",
      },
      {
        axis: "x",
        value: "2019[37-38]",
        lineStyle: { stroke: "#b0413e", strokeWidth: 1 },
        legend: "-confirmail",
      },
      {
        axis: "x",
        value: "2019[39-40]",
        lineStyle: { stroke: "#b0413e", strokeWidth: 1 },
        legend: "+(pré)nom",
      },
      {
        axis: "x",
        value: "2019[45-46]",
        lineStyle: { stroke: "#b0413e", strokeWidth: 1 },
        legend: "offerV2",
      },
      {
        axis: "x",
        value: "2019[47-48]",
        lineStyle: { stroke: "#b0413e", strokeWidth: 1 },
        legend: "diagIRv3",
      },
    ],
    markers: [
      {
        axis: "x",
        value: "2019.36",
        lineStyle: { stroke: "#b0413e", strokeWidth: 1 },
        legend: "+caps",
      },
      {
        axis: "x",
        value: "2019.37",
        lineStyle: { stroke: "#b0413e", strokeWidth: 1 },
        legend: "-confirmail",
      },
      {
        axis: "x",
        value: "2019.40",
        lineStyle: { stroke: "#b0413e", strokeWidth: 1 },
        legend: "+(pré)nom",
      },
      {
        axis: "x",
        value: "2019.45",
        lineStyle: { stroke: "#b0413e", strokeWidth: 1 },
        legend: "offerV2",
      },
      {
        axis: "x",
        value: "2019.48",
        lineStyle: { stroke: "#b0413e", strokeWidth: 1 },
        legend: "diagIRv3",
      },
    ],
  };

  constructor() {
    super();
    this.api = new API();
  }

  componentDidMount() {
    this.refreshStats();
  }

  refreshStats = async (cache = "true") => {
    if (!cache) {
      this.setState({ purgeCache: true });
    }
    const from = moment("2019-09-02");
    const to = moment().add(1, "day");
    const to2 = moment("2019-12-01");

    /* BUYERS LAST REFERRER */
    buyersLastReferrersAbsoluteData = [
      { id: "profile", data: [] },
      { id: "program", data: [] },
      { id: "activity", data: [] },
      { id: "capsule", data: [] },
    ];
    buyersLastReferrersRatioData = JSON.parse(
      JSON.stringify(buyersLastReferrersAbsoluteData)
    );

    const buyersLastReferrers = await this.api.getLastReferrerBuyers(
      from.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      cache
    );
    Object.keys(buyersLastReferrers)
      .reverse()
      .map((weekKey) => {
        const weekData = buyersLastReferrers[weekKey];
        let weekTotal = 0;
        Object.keys(weekData).map((referrer) => {
          weekTotal += weekData[referrer];
        });
        buyersLastReferrersAbsoluteData.map((_, index) => {
          const referrer = buyersLastReferrersAbsoluteData[index].id;
          const value = weekData[referrer] ? weekData[referrer] : 0;
          buyersLastReferrersAbsoluteData[index].data.push({
            x: weekKey,
            y: value,
          });
          buyersLastReferrersRatioData[index].data.push({
            x: weekKey,
            y: Math.round((value * 100) / Math.max(1, weekTotal)),
          });
        });
      });
    this.setState({
      buyersLastReferrersChartData: buyersLastReferrersAbsoluteData,
    });

    /* NON BUYERS ALL REFERRERS */
    nonBuyersAllReferrersAbsoluteData = [
      { id: "profile", data: [] },
      { id: "program", data: [] },
      { id: "activity", data: [] },
      { id: "capsule", data: [] },
      { id: "banner", data: [] },
    ];
    nonBuyersAllReferrersRatioData = JSON.parse(
      JSON.stringify(nonBuyersAllReferrersAbsoluteData)
    );

    const nonBuyersAllReferrers = await this.api.getAllReferrersNonBuyers(
      from.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      cache
    );
    Object.keys(nonBuyersAllReferrers)
      .reverse()
      .map((weekKey) => {
        const weekData = nonBuyersAllReferrers[weekKey];
        let weekTotal = 0;
        Object.keys(weekData).map((referrer) => {
          weekTotal += weekData[referrer];
        });
        nonBuyersAllReferrersAbsoluteData.map((_, index) => {
          const referrer = nonBuyersAllReferrersAbsoluteData[index].id;
          const value = weekData[referrer] ? weekData[referrer] : 0;
          nonBuyersAllReferrersAbsoluteData[index].data.push({
            x: weekKey,
            y: value,
          });
          nonBuyersAllReferrersRatioData[index].data.push({
            x: weekKey,
            y: Math.round((value * 100) / Math.max(1, weekTotal)),
          });
        });
      });
    nonBuyersAllReferrersAbsoluteData.map((_, index) => {
      nonBuyersAllReferrersAbsoluteData[index].data.sort(dynamicSort("x"));
      nonBuyersAllReferrersRatioData[index].data.sort(dynamicSort("x"));
    });
    this.setState({
      nonBuyersAllReferrersChartData: nonBuyersAllReferrersAbsoluteData,
    });

    /* NON BUYERS LAST REFERRER */
    nonBuyersLastReferrersAbsoluteData = [
      { id: "profile", data: [] },
      { id: "program", data: [] },
      { id: "activity", data: [] },
      { id: "capsule", data: [] },
    ];
    nonBuyersLastReferrersRatioData = JSON.parse(
      JSON.stringify(nonBuyersLastReferrersAbsoluteData)
    );

    const nonBuyersLastReferrers = await this.api.getLastReferrerNonBuyers(
      from.format("YYYY-MM-DD"),
      to.format("YYYY-MM-DD"),
      cache
    );
    Object.keys(nonBuyersLastReferrers)
      .reverse()
      .map((weekKey) => {
        const weekData = nonBuyersLastReferrers[weekKey];
        let weekTotal = 0;
        Object.keys(weekData).map((referrer) => {
          weekTotal += weekData[referrer];
        });
        nonBuyersLastReferrersAbsoluteData.map((_, index) => {
          const referrer = nonBuyersLastReferrersAbsoluteData[index].id;
          const value = weekData[referrer] ? weekData[referrer] : 0;
          nonBuyersLastReferrersAbsoluteData[index].data.push({
            x: weekKey,
            y: value,
          });
          nonBuyersLastReferrersRatioData[index].data.push({
            x: weekKey,
            y: Math.round((value * 100) / Math.max(1, weekTotal)),
          });
        });
        nonBuyersLastReferrersAbsoluteData.map((_, index) => {
          nonBuyersLastReferrersAbsoluteData[index].data.sort(dynamicSort("x"));
          nonBuyersLastReferrersRatioData[index].data.sort(dynamicSort("x"));
        });
      });
    this.setState({
      nonBuyersLastReferrersChartData: nonBuyersLastReferrersAbsoluteData,
    });

    const profiles = await this.api.getRouteActionsAndProfiles(
      to2.format("YYYY-MM-DD"),
      cache
    );
    this.setState({ actionsAndProfilesData: profiles });

    this.setState({ purgeCache: false });
  };

  /* STEP 1 */
  renderLastReferrersBuyersChart() {
    return (
      <div className="card mg-b-20 pd-t-20 pd-l-20 pd-r-20">
        <h6 className="card-body-title">
          1. PROVENANCE DU DERNIER ACCÈS À L'ÉCRAN OFFRE AVANT ACHAT (OU
          TENTATIVE D'ACHAT) IN-APP PAR PÉRIODE DE 2 SEMAINES&nbsp;
          <button
            onClick={() => {
              const isBuyersLastReferrersAbsolute = !this.state
                .isBuyersLastReferrersAbsolute;
              this.setState({
                isBuyersLastReferrersAbsolute,
                buyersLastReferrersChartData: isBuyersLastReferrersAbsolute
                  ? buyersLastReferrersAbsoluteData
                  : buyersLastReferrersRatioData,
              });
            }}
          >
            {this.state.isBuyersLastReferrersAbsolute
              ? "en unité"
              : "en pourcentage"}
          </button>
        </h6>
        <div className="table-wrapper bg-gray" style={{ height: "200px" }}>
          <ResponsiveLine
            data={this.state.buyersLastReferrersChartData}
            indexBy="_id"
            margin={{ top: 10, right: 30, bottom: 60, left: 30 }}
            padding={0.3}
            colors={{ scheme: "category10" }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            axisRight={{ enable: true }}
            yScale={{ type: "linear", min: "auto" }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 55,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 8,
                effects: [{ on: "hover", style: { itemOpacity: 1 } }],
              },
            ]}
            animate={true}
            motionStiffness={90}
            useMesh={true}
            enableGridY={false}
            markers={this.state.markersTwoWeeks}
          />
        </div>
      </div>
    );
  }

  /* STEP 2 */
  renderAllReferrersNonBuyersChart() {
    return (
      <div className="card mg-b-20 pd-t-20 pd-l-20 pd-r-20">
        <h6 className="card-body-title">
          2. PROVENANCE DES ACCÈS À L'ÉCRAN OFFRE POUR LES PROSPECTS PAR
          SEMAINE&nbsp;
          <button
            onClick={() => {
              const isNonBuyersAllReferrersAbsolute = !this.state
                .isNonBuyersAllReferrersAbsolute;
              this.setState({
                isNonBuyersAllReferrersAbsolute,
                nonBuyersAllReferrersChartData: isNonBuyersAllReferrersAbsolute
                  ? nonBuyersAllReferrersAbsoluteData
                  : nonBuyersAllReferrersRatioData,
              });
            }}
          >
            {this.state.isNonBuyersAllReferrersAbsolute
              ? "en unité"
              : "en pourcentage"}
          </button>
        </h6>
        <div className="table-wrapper bg-gray" style={{ height: "200px" }}>
          <ResponsiveLine
            data={this.state.nonBuyersAllReferrersChartData}
            indexBy="_id"
            margin={{ top: 10, right: 30, bottom: 60, left: 30 }}
            padding={0.3}
            colors={{ scheme: "category10" }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            axisRight={{ enable: true }}
            yScale={{ type: "linear", min: "auto" }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 55,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 8,
                effects: [{ on: "hover", style: { itemOpacity: 1 } }],
              },
            ]}
            animate={true}
            motionStiffness={90}
            useMesh={true}
            enableGridY={false}
            markers={this.state.markers}
          />
        </div>
      </div>
    );
  }

  /* STEP 3 */
  renderLastReferrersNonBuyersChart() {
    return (
      <div className="card mg-b-20 pd-t-20 pd-l-20 pd-r-20">
        <h6 className="card-body-title">
          3. PROVENANCE DU DERNIER ACCÈS À L'ÉCRAN OFFRE POUR LES PROSPECTS PAR
          SEMAINE&nbsp;
          <button
            onClick={() => {
              const isNonBuyersLastReferrersAbsolute = !this.state
                .isNonBuyersLastReferrersAbsolute;
              this.setState({
                isNonBuyersLastReferrersAbsolute,
                nonBuyersLastReferrersChartData: isNonBuyersLastReferrersAbsolute
                  ? nonBuyersLastReferrersAbsoluteData
                  : nonBuyersLastReferrersRatioData,
              });
            }}
          >
            {this.state.isNonBuyersLastReferrersAbsolute
              ? "en unité"
              : "en pourcentage"}
          </button>
        </h6>
        <div className="table-wrapper bg-gray" style={{ height: "200px" }}>
          <ResponsiveLine
            data={this.state.nonBuyersLastReferrersChartData}
            indexBy="_id"
            margin={{ top: 10, right: 30, bottom: 60, left: 30 }}
            padding={0.3}
            colors={{ scheme: "category10" }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            axisRight={{ enable: true }}
            yScale={{ type: "linear", min: "auto" }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 55,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 8,
                effects: [{ on: "hover", style: { itemOpacity: 1 } }],
              },
            ]}
            animate={true}
            motionStiffness={90}
            useMesh={true}
            enableGridY={false}
          />
        </div>
      </div>
    );
  }

  renderActionsCharts() {
    const bd =
      (this.state.actionsAndProfilesData &&
        this.state.actionsAndProfilesData.buyers &&
        this.state.actionsAndProfilesData.buyers.actions) ||
      {};
    const pd =
      (this.state.actionsAndProfilesData &&
        this.state.actionsAndProfilesData.nonBuyers &&
        this.state.actionsAndProfilesData.nonBuyers.actions) ||
      {};

    let bdBars = [
      { _id: "capsules", acheteurs: 0, prospects: 0 },
      { _id: "acouphenometry", acheteurs: 0, prospects: 0 },
      { _id: "questionnaires", acheteurs: 0, prospects: 0 },
      { _id: "offerScreen", acheteurs: 0, prospects: 0 },
    ];

    Object.keys(bd).map((action) => {
      bdBars.map((item, i) => {
        if (item._id === action) {
          bdBars[i] = {
            ...item,
            acheteurs:
              Math.round(
                (bd[action].visits * 10) /
                  (bd[action].userOnceOrMore + bd[action].userZero)
              ) / 10,
          };
        }
      });
    });

    Object.keys(pd).map((action) => {
      bdBars.map((item, i) => {
        if (item._id === action) {
          bdBars[i] = {
            ...item,
            prospects:
              Math.round(
                (pd[action].visits * 10) /
                  (pd[action].userOnceOrMore + pd[action].userZero)
              ) / 10,
          };
        }
      });
    });

    return (
      <div className="col-xl-6">
        <div key={1} className="card mg-b-20 pd-t-20 pd-l-20 pd-r-20">
          <h6 className="card-body-title">
            4. MOYENNE PAR ACHETEUR IN-APP AVANT ACHAT ET
            <br />
            DES PROSPECTS DANS LES 2 SEMAINES APRÈS LEUR INSCRIPTION
          </h6>
          <div
            className="table-wrapper bg-gray mg-t-20 mg-b-20"
            style={{ height: "292px" }}
          >
            <ResponsiveBar
              data={bdBars}
              keys={["acheteurs", "prospects"]}
              indexBy="_id"
              margin={{ top: 10, right: 0, bottom: 25, left: 0 }}
              padding={0.3}
              groupMode="grouped"
              colors={{ scheme: "nivo" }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              axisLeft={null}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              animate={true}
              motionStiffness={90}
            />
          </div>
        </div>
      </div>
    );
  }

  renderProfilesCharts() {
    const bd =
      (this.state.actionsAndProfilesData &&
        this.state.actionsAndProfilesData.buyers &&
        this.state.actionsAndProfilesData.buyers.profiles) ||
      {};
    const pd =
      (this.state.actionsAndProfilesData &&
        this.state.actionsAndProfilesData.nonBuyers &&
        this.state.actionsAndProfilesData.nonBuyers.profiles) ||
      {};

    if (bd) {
      bd.max = 0;
      Object.keys(bd).map((value) => {
        bd.max = Math.max(bd.max, bd[value]);
      });
    }

    if (pd) {
      pd.max = 0;
      Object.keys(pd).map((value) => {
        pd.max = Math.max(pd.max, pd[value]);
      });
    }

    return (
      <div className="col-xl-6">
        <div key={1} className="card mg-b-20 pd-t-20 pd-l-20 pd-r-20">
          <h6 className="card-body-title">
            5A. PROFILS DES ACHETEURS (ACHT) IN-APP AVANT ACHAT
          </h6>
          <div className="flexrow">
            <div className="table-wrapper mg-b-20 bg-gray">
              <table className="miniTable" border={1}>
                <tbody>
                  <tr>
                    <th>ACHT</th>
                    <th>A0Q0</th>
                    <th>A0Q1</th>
                    <th>A1Q0</th>
                    <th>A1Q1</th>
                  </tr>
                  <tr>
                    <th>P0C0</th>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <th>P0C1</th>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <th>P1C0</th>
                    {cell(bd, "p1c0a0q0")}
                    {cell(bd, "p1c0a0q1")}
                    {cell(bd, "p1c0a1q0")}
                    {cell(bd, "p1c0a1q1")}
                  </tr>
                  <tr>
                    <th>P1C1</th>
                    {cell(bd, "p1c1a0q0")}
                    {cell(bd, "p1c1a0q1")}
                    {cell(bd, "p1c1a1q0")}
                    {cell(bd, "p1c1a1q1")}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mg-l-20">
              <ul>
                <li>
                  Plus c'est coloré, plus il y a d'utilisateurs dans ce cas.
                </li>
                <li>Les actions de la configuration sont ignorées.</li>
                <li>Les utilisateurs pros sont ignorés.</li>
                <li>Aucun acheteur en P0 car impossible.</li>
                <li>
                  La légende et les notes que vous êtes en train de lire
                  s'appliquent pour les 2 tableaux.
                </li>
              </ul>
            </div>
          </div>
          <h6 className="card-body-title">
            5P. PROFILS DES PROSPECTS (PRSP) DANS LES 2 SEMAINES APRÈS LEUR
            INSCRIPTION
          </h6>
          <div className="flexrow">
            <div className="table-wrapper mg-b-20 bg-gray">
              <table className="miniTable" border={1}>
                <tbody>
                  <tr>
                    <th>PRSP</th>
                    <th>A0Q0</th>
                    <th>A0Q1</th>
                    <th>A1Q0</th>
                    <th>A1Q1</th>
                  </tr>
                  <tr>
                    <th>P0C0</th>
                    {cell(pd, "p0c0a0q0")}
                    {cell(pd, "p0c0a0q1")}
                    {cell(pd, "p0c0a1q0")}
                    {cell(pd, "p0c0a1q1")}
                  </tr>
                  <tr>
                    <th>P0C1</th>
                    {cell(pd, "p0c1a0q0")}
                    {cell(pd, "p0c1a0q1")}
                    {cell(pd, "p0c1a1q0")}
                    {cell(pd, "p0c1a1q1")}
                  </tr>
                  <tr>
                    <th>P1C0</th>
                    {cell(pd, "p1c0a0q0")}
                    {cell(pd, "p1c0a0q1")}
                    {cell(pd, "p1c0a1q0")}
                    {cell(pd, "p1c0a1q1")}
                  </tr>
                  <tr>
                    <th>P1C1</th>
                    {cell(pd, "p1c1a0q0")}
                    {cell(pd, "p1c1a0q1")}
                    {cell(pd, "p1c1a1q0")}
                    {cell(pd, "p1c1a1q1")}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mg-l-20">
              <ul>
                <li>A = Acouphénométrie</li>
                <li>Q = Questionnaire (WFU, THI, ISI, HADS)</li>
                <li>P = Écran du prix (offre)</li>
                <li>C = Capsule</li>
                <li>0 = Jamais vu/commencé.</li>
                <li>1 = Vu/commencé au moins une fois.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Layout>
        <nav className="breadcrumb sl-breadcrumb">
          <a className="breadcrumb-item" href="/dashboard">
            Dashboard
          </a>
          <span className="breadcrumb-item active">
            Statistiques du parcours menant à l'achat
          </span>
          {/*<button disabled={this.state.purgeCache} onClick={() => this.refreshStats(false)}>{this.state.purgeCache?'En cours…':'Vider le cache'}</button>*/}
        </nav>
        <div className="sl-pagebody">
          <div className="row row-sm">
            <div className="col-xl-12">
              {this.renderLastReferrersBuyersChart()}
              {this.renderAllReferrersNonBuyersChart()}
              {this.renderLastReferrersNonBuyersChart()}
              <div className="row row-sm">
                {this.renderActionsCharts()}
                {this.renderProfilesCharts()}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function cell(d, key) {
  const value = d[key];
  const pct = Math.round((value * 100) / d.max) / 100;
  return <td style={{ backgroundColor: `rgba(0,255,0,${pct})` }}>{value}</td>;
}

export default Stats;
