import React, { Component } from "react";

import API from "../utils/API";
import Layout from "../components/Layout";

class ActivationCodeBind extends Component {
  state = {
    serialStart: "",
    serialStop: "",
    serialTotal: 0,
    searchedCodes: [],
    reference: "",
    corporationName: "",
    contactName: "",
    phone: "",
    address: "",
    email: "",
  };

  constructor() {
    super();
    this.api = new API();
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  fixSerial = (event) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    if (value.indexOf("-") === -1 && value.length >= 2) {
      value = `${value[0]}-${value.slice(1)}`;
    }

    if (value.indexOf("-") === 1) {
      const [prefix, number] = value.split("-");
      this.setState({
        [name]: `${prefix}-${number.padStart(4, "0")}`,
      });
      this.calculateSerialTotal();
    }
  };

  calculateSerialTotal = () => {
    const s1 = this.state.serialStart;
    const s2 = this.state.serialStop;
    if (s1.indexOf("-") === 1 && s2.indexOf("-") === 1) {
      const n1 = +s1.split("-")[1];
      const n2 = +s2.split("-")[1] + 1;
      this.setState({ serialTotal: isNaN(n2 - n1) ? "???" : n2 - n1 });
    }
  };

  selectSerial = (codeIndex) => {
    console.warn("codeIndex", codeIndex);
    const searchedCodes = this.state.searchedCodes;
    searchedCodes[codeIndex].checked = !searchedCodes[codeIndex].checked;
    this.setState({ searchedCodes });
  };

  submitSearchForm = async (e) => {
    e.preventDefault();
    try {
      this.setState({
        searchedCodes: await this.api.getActivationCodesBetween(
          this.state.serialStart,
          this.state.serialStop
        ),
      });
    } catch (e) {
      console.warn("Erreur submitSearchForm", e);
      alert(`Problème lors de la recherche de codes… ${e}`);
    }
  };

  submitBindForm = async (e) => {
    e.preventDefault();
    try {
      await this.api.setActivationCodesMerchant(
        this.state.searchedCodes
          .filter((code) => code.checked)
          .map((code) => code.serialNumber),
        this.state.reference,
        this.state.corporationName,
        this.state.contactName,
        this.state.phone,
        this.state.address,
        this.state.email
      );
      this.setState({
        searchedCodes: await this.api.getActivationCodesBetween(
          this.state.serialStart,
          this.state.serialStop
        ),
      });
    } catch (e) {
      console.warn("Erreur submitBindForm", e);
      alert(`Problème lors de l'attribution du marchand… ${e}`);
    }
  };

  render() {
    return (
      <Layout>
        <nav className="breadcrumb sl-breadcrumb">
          <a className="breadcrumb-item" href="/dashboard">
            Dashboard
          </a>
          <a className="breadcrumb-item" href="/stock-activation-codes">
            Codes d'activation
          </a>
          <span className="breadcrumb-item active">
            Assigner un marchand à des codes d'activation
          </span>
        </nav>
        <div className="sl-pagebody">
          <div className="row row-sm mg-b-20">
            <div className="col-md-6">
              <div className="card pd-20 pd-sm-20">
                <h6 className="card-body-title">
                  1. Recherchez des codes d'activation pour les attribuer
                </h6>
                <form className="form-layout" onSubmit={this.submitSearchForm}>
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-control-label">
                          Numéro de série de départ&nbsp;:
                        </label>
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="icon ion-skip-forward tx-16 lh-0 op-6"></i>
                          </span>
                          <input
                            className="form-control"
                            name="serialStart"
                            type="text"
                            value={this.state.serialStart}
                            onChange={this.handleChange}
                            onBlur={this.fixSerial}
                            placeholder="A-0001"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="form-group">
                        <label className="form-control-label">Total</label>
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="icon ion-calculator tx-16 lh-0 op-6"></i>
                          </span>
                          <input
                            className="form-control"
                            tabIndex="-1"
                            type="text"
                            readOnly={true}
                            value={this.state.serialTotal}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="form-group mg-b-10-force">
                        <label className="form-control-label">
                          Numéro de série de fin&nbsp;:
                        </label>
                        <div className="input-group">
                          <input
                            className="form-control"
                            name="serialStop"
                            type="text"
                            value={this.state.serialStop}
                            onBlur={this.fixSerial}
                            onChange={this.handleChange}
                            placeholder="A-0007"
                          />
                          <span className="input-group-addon">
                            <i className="icon ion-skip-backward tx-16 lh-0 op-6"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-layout-footer">
                    <button className="btn btn-info mg-r-5">
                      Rechercher ces codes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row row-sm mg-b-20">
            <div className="col-md-12">
              <div className="card pd-20 pd-sm-20">
                <h6 className="card-body-title">
                  2. Résultats de la recherche
                </h6>
                <p className="mg-b-20 mg-sm-b-20">
                  Cochez les codes que vous voulez attribuer via le formulaire
                  ci-dessous.
                </p>
                <table className="table table-hover table-striped table-primary mg-b-0">
                  <thead>
                    <tr>
                      <th>
                        <label className="ckbox mg-b-0">
                          <input
                            type="checkbox"
                            readOnly={true}
                            checked={
                              this.state.searchedCodes.filter(
                                (code) => !code.checked
                              ).length === 0
                            }
                          />
                          <span />
                        </label>
                      </th>
                      <th>Numéro de série</th>
                      <th>Code utilisé ?</th>
                      <th>Marchand</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.searchedCodes.map((code, codeIndex) => {
                      return (
                        <tr key={code.serialNumber}>
                          <td>
                            <label className="ckbox mg-b-0">
                              <input
                                type="checkbox"
                                checked={
                                  this.state.searchedCodes[codeIndex].checked
                                }
                                onChange={() => this.selectSerial(codeIndex)}
                              />
                              <span />
                            </label>
                          </td>
                          <td>{code.serialNumber}</td>
                          <td>
                            {code.status === "NOT_USED" ? (
                              "Non"
                            ) : (
                              <span className="tx-danger tx-bold">
                                UTILISÉ !!!
                              </span>
                            )}
                          </td>
                          <td>
                            {code.merchant ? (
                              <span className="tx-danger tx-bold">{`${code.merchant.reference} / ${code.merchant.corporationName}`}</span>
                            ) : (
                              "Non attribué"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row row-sm">
            <div className="col-md-12">
              <div className="card pd-20">
                <h6 className="card-body-title">
                  3. Assigner un marchand à ces codes d'activation
                </h6>
                <form className="form-layout" onSubmit={this.submitBindForm}>
                  <div className="row mg-b-10">
                    <div className="col-lg-2">
                      <div className="form-group">
                        <label className="form-control-label">
                          Référence interne&nbsp;:
                        </label>
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="icon ion-ios-flask tx-16 lh-0 op-6"></i>
                          </span>
                          <input
                            className="form-control"
                            name="reference"
                            type="text"
                            value={this.state.reference}
                            onChange={this.handleChange}
                            placeholder="A2K-01"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group mg-b-10-force">
                        <label className="form-control-label">
                          Raison sociale&nbsp;:
                        </label>
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="icon ion-ios-contact tx-16 lh-0 op-6"></i>
                          </span>
                          <input
                            className="form-control"
                            name="corporationName"
                            type="text"
                            value={this.state.corporationName}
                            onChange={this.handleChange}
                            placeholder="AudioCentre Perros-Guirec"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-control-label">
                          Nom du contact&nbsp;:
                        </label>
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="icon ion-person tx-16 lh-0 op-6"></i>
                          </span>
                          <input
                            className="form-control"
                            name="contactName"
                            type="text"
                            value={this.state.contactName}
                            onChange={this.handleChange}
                            placeholder="Anne HONIME"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="form-group mg-b-10-force">
                        <label className="form-control-label">
                          Téléphone&nbsp;:
                        </label>
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="icon ion-ios-telephone tx-16 lh-0 op-6"></i>
                          </span>
                          <input
                            className="form-control"
                            name="phone"
                            type="text"
                            value={this.state.phone}
                            onChange={this.handleChange}
                            placeholder="06 00 00 00 00"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mg-b-10-force">
                        <label className="form-control-label">
                          Adresse postale&nbsp;:
                        </label>
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="icon ion-ios-home tx-16 lh-0 op-6"></i>
                          </span>
                          <input
                            className="form-control"
                            name="address"
                            type="text"
                            value={this.state.address}
                            onChange={this.handleChange}
                            placeholder="12 rue du port, 22700 PERROS-GUIREC"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mg-b-10-force">
                        <label className="form-control-label">
                          Adresse email&nbsp;:
                        </label>
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="icon ion-ios-email tx-16 lh-0 op-6"></i>
                          </span>
                          <input
                            className="form-control"
                            name="email"
                            type="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            placeholder="perrosguirec@audiocentre.fr"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-layout-footer">
                    <button className="btn btn-info mg-r-5">
                      Attribuer ce marchand aux codes cochés
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ActivationCodeBind;
