import React, { Component } from "react";

import API from "../utils/API";
import Layout from "../components/Layout";
import moment from "moment";

class ActivationCodeStock extends Component {
  state = {
    serialStart: "A-0001",
    serialStop: "A-1000",
    serialTotal: 1000,
    searchedCodes: [],
    reference: "",
    corporationName: "",
    contactName: "",
    phone: "",
    address: "",
    email: "",
  };

  constructor() {
    super();
    this.api = new API();
    this.submitSearchForm();
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  fixSerial = (event) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    if (value.indexOf("-") === -1 && value.length >= 2) {
      value = `${value[0]}-${value.slice(1)}`;
    }

    if (value.indexOf("-") === 1) {
      const [prefix, number] = value.split("-");
      this.setState({
        [name]: `${prefix}-${number.padStart(4, "0")}`,
      });
    }
  };

  submitSearchForm = async (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    try {
      this.setState({
        searchedCodes: await this.api.getActivationCodesBetween(
          this.state.serialStart,
          this.state.serialStop
        ),
      });
    } catch (e) {
      console.warn("Erreur submitSearchForm", e);
      alert(`Problème lors de la recherche de codes… ${e}`);
    }
  };

  render() {
    return (
      <Layout>
        <nav className="breadcrumb sl-breadcrumb">
          <a className="breadcrumb-item" href="/dashboard">
            Dashboard
          </a>
          <a className="breadcrumb-item" href="/stock-activation-codes">
            Codes d'activation
          </a>
          <span className="breadcrumb-item active">
            Stock de codes d'activation
          </span>
        </nav>
        <div className="sl-pagebody">
          <div className="row row-sm mg-b-20">
            <div className="col-md-6">
              <div className="card pd-20 pd-sm-20">
                <h6 className="card-body-title">
                  1. Recherchez des codes d'activation par numéro de série
                </h6>
                <form className="form-layout" onSubmit={this.submitSearchForm}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label">
                          Numéro de série de départ&nbsp;:
                        </label>
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="icon ion-skip-forward tx-16 lh-0 op-6"></i>
                          </span>
                          <input
                            className="form-control"
                            name="serialStart"
                            type="text"
                            value={this.state.serialStart}
                            onChange={this.handleChange}
                            onBlur={this.fixSerial}
                            placeholder="A-0001"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mg-b-10-force">
                        <label className="form-control-label">
                          Numéro de série de fin&nbsp;:
                        </label>
                        <div className="input-group">
                          <input
                            className="form-control"
                            name="serialStop"
                            type="text"
                            value={this.state.serialStop}
                            onChange={this.handleChange}
                            onBlur={this.fixSerial}
                            placeholder="A-0007"
                          />
                          <span className="input-group-addon">
                            <i className="icon ion-skip-backward tx-16 lh-0 op-6"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-layout-footer">
                    <button className="btn btn-info mg-r-5">
                      Rechercher ces codes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row row-sm mg-b-20">
            <div className="col-md-12">
              <div className="card pd-20 pd-sm-20">
                <h6 className="card-body-title">
                  2. Résultats de la recherche{" "}
                  {this.state.searchedCodes.length
                    ? `(${this.state.searchedCodes.length} codes)`
                    : ""}
                </h6>
                <table className="table table-hover table-striped table-primary mg-b-0">
                  <thead>
                    <tr>
                      <th />
                      <th>Numéro de série</th>
                      <th>Marchand</th>
                      <th>Utilisation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.searchedCodes.map((code, codeIndex) => {
                      return (
                        <tr key={code.serialNumber}>
                          <td>
                            <label className="ckbox mg-b-0">
                              <input
                                type="checkbox"
                                readOnly={true}
                                checked={
                                  this.state.searchedCodes[codeIndex].checked
                                }
                              />
                              <span />
                            </label>
                          </td>
                          <td>
                            {code.user ? (
                              <span className="tx-primary tx-bold">
                                {code.serialNumber}
                              </span>
                            ) : (
                              code.serialNumber
                            )}
                          </td>
                          <td>
                            {code.merchant ? (
                              <span className="tx-primary tx-bold">{`${code.merchant.reference} / ${code.merchant.corporationName}`}</span>
                            ) : (
                              "Non attribué"
                            )}
                          </td>
                          <td>
                            {code.user
                              ? [
                                  <a
                                    key="email"
                                    href={`user?id=${code.user._id}`}
                                    className="tx-primary tx-bold"
                                  >
                                    {code.user.firstName} {code.user.lastName}
                                  </a>,
                                  <br key="br1" />,
                                  <small className="tx-primary">
                                    {code.user.email}
                                  </small>,
                                  <br key="br2" />,
                                  <small key="date" className="tx-primary">
                                    {moment(code.activationDate).format(
                                      "ddd D MMM YYYY"
                                    )}
                                  </small>,
                                ]
                              : "Non utilisé"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ActivationCodeStock;
