import React, { Component } from "react";

import API from "../utils/API";
import Layout from "../components/Layout";

class EncouragementScreen extends Component {
  state = {
    toValidate: [],
    moderated: [],
    top: [],
    flop: [],
  };

  constructor() {
    super();
    this.api = new API();
  }

  async componentDidMount() {
    await this.refreshEncouragements();
  }

  resetImageCache = () => {
    this.api.resetEncouragementImageCache();
  };

  refreshEncouragements = async () => {
    this.setState({ toValidate: await this.api.getEncouragementsToValidate() });
    this.setState({ moderated: await this.api.getModeratedEncouragements() });
    this.setState({
      top: await this.api.getEncouragements(-1),
      flop: await this.api.getEncouragements(1),
    });
  };

  render() {
    const baseUrl = sessionStorage.getItem("api");

    return (
      <Layout>
        <nav className="breadcrumb sl-breadcrumb">
          <a className="breadcrumb-item" href="/dashboard">
            Dashboard
          </a>
          <span className="breadcrumb-item active">Encouragements</span>
        </nav>
        <div className="sl-pagebody">
          <div className="row row-sm mg-b-20">
            <div className="col-md-6">
              <div className="card pd-20 pd-sm-20 scroll-500">
                <h6 className="card-body-title">
                  LES ENCOURAGEMENTS À MODÉRER
                </h6>
                {this.state.toValidate.length === 0 ? (
                  <div>Aucun encouragement à modérer</div>
                ) : null}
                {this.state.toValidate.map((encouragement) => {
                  const rid = [...encouragement._id].reverse().join("");
                  return (
                    <div key={encouragement._id} className="encouragementBox">
                      <span>
                        [{encouragement.lang}]&nbsp;
                        <a
                          href={`/user?id=${
                            encouragement.author && encouragement.author.user
                          }`}
                        >
                          {encouragement.author && encouragement.author.label}
                        </a>
                        &nbsp;a écrit le message&nbsp;: (
                        <a
                          href={`${baseUrl}/diapason/encouragements/${rid}/image?svg=1&unsafe=1`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          image
                        </a>
                        )
                      </span>
                      <div className="messageContainer">
                        <p>{encouragement.message}</p>
                        <div className="actions">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              const message = window.prompt(
                                "Vous pouvez modifier avant de valider ce message.",
                                encouragement.message
                              );
                              if (message) {
                                this.api.validateEncouragement(
                                  encouragement._id,
                                  message
                                );
                                window.setTimeout(
                                  () => this.refreshEncouragements(),
                                  300
                                );
                              }
                            }}
                          >
                            Publier
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={async () =>
                              window.confirm("On rejette ?")
                                ? (await this.api.rejectEncouragement(
                                    encouragement._id
                                  )) && this.refreshEncouragements()
                                : () => {}
                            }
                          >
                            Rejeter
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-md-6">
              <div className="card pd-20 pd-sm-20 scroll-500">
                <h6 className="card-body-title">
                  LES DERNIERS ENCOURAGEMENTS MODÉRÉS
                </h6>
                {this.state.moderated.map((encouragement) => {
                  const rid = [...encouragement._id].reverse().join("");
                  const unsafe = encouragement.validated ? "" : "&unsafe=1";
                  return (
                    <div
                      key={encouragement._id}
                      className={
                        encouragement.validated
                          ? "encouragementBox validated"
                          : "encouragementBox rejected"
                      }
                    >
                      <span>
                        {encouragement.validated ? "✔" : "✗"} [
                        {encouragement.lang}]&nbsp;
                        <a
                          href={`/user?id=${
                            encouragement.author && encouragement.author.user
                          }`}
                        >
                          {encouragement.author && encouragement.author.label}
                        </a>
                        &nbsp;a écrit le message&nbsp;: (
                        <a
                          href={`${baseUrl}/diapason/encouragements/${rid}/image?svg=1${unsafe}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          image
                        </a>
                        )
                      </span>
                      <div>
                        {encouragement.message}
                        <br />
                        {encouragement.validated ? (
                          <a
                            href="#"
                            onClick={async (e) => {
                              e.preventDefault();
                              return window.confirm("On rejette ?")
                                ? (await this.api.rejectEncouragement(
                                    encouragement._id
                                  )) && this.refreshEncouragements()
                                : () => {};
                            }}
                          >
                            Rejeter
                          </a>
                        ) : (
                          <a
                            href="#"
                            onClick={async (e) => {
                              e.preventDefault();
                              const message = window.prompt(
                                "Vous pouvez modifier avant de valider ce message.",
                                encouragement.message
                              );
                              if (message) {
                                this.api.validateEncouragement(
                                  encouragement._id,
                                  message
                                );
                                window.setTimeout(
                                  () => this.refreshEncouragements(),
                                  300
                                );
                              }
                            }}
                          >
                            Publier
                          </a>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="row row-sm mg-b-20">
            <div className="col-md-6">
              <div className="card pd-20 pd-sm-20 scroll-500">
                <h6 className="card-body-title">
                  TOP {this.state.top.length} PAR RATIO REMERCIEMENTS/VUES
                </h6>
                {this.state.top.map((encouragement) => {
                  const rid = [...encouragement._id].reverse().join("");
                  return (
                    <div
                      key={encouragement._id}
                      className="encouragementBox top"
                    >
                      <span>
                        [{encouragement.lang}]&nbsp;
                        <a
                          href={`/user?id=${
                            encouragement.author && encouragement.author.user
                          }`}
                        >
                          {encouragement.author && encouragement.author.label}
                        </a>
                        &nbsp;a écrit le message&nbsp;: (
                        <a
                          href={`${baseUrl}/diapason/encouragements/${rid}/image?svg=1`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          image
                        </a>
                        )
                      </span>
                      <p>{encouragement.message}</p>
                      <div className="stats">
                        Vues:&nbsp;{encouragement.views} - Remerciements:&nbsp;
                        {encouragement.thanks} - Score:&nbsp;
                        {Math.round(encouragement.ratio * 10000) / 100}
                        &nbsp;-&nbsp;
                        <a
                          href="#"
                          onClick={async (e) => {
                            e.preventDefault();
                            return window.confirm("On rejette ?")
                              ? (await this.api.rejectEncouragement(
                                  encouragement._id
                                )) && this.refreshEncouragements()
                              : () => {};
                          }}
                        >
                          Rejeter
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-md-6">
              <div className="card pd-20 pd-sm-20 scroll-500">
                <h6 className="card-body-title">
                  FLOP {this.state.flop.length} PAR RATIO REMERCIEMENTS/VUES
                </h6>
                {this.state.flop.map((encouragement) => {
                  const rid = [...encouragement._id].reverse().join("");
                  return (
                    <div
                      key={encouragement._id}
                      className="encouragementBox flop"
                    >
                      <span>
                        [{encouragement.lang}]&nbsp;
                        <a
                          href={`/user?id=${
                            encouragement.author && encouragement.author.user
                          }`}
                        >
                          {encouragement.author && encouragement.author.label}
                        </a>
                        &nbsp;a écrit le message&nbsp;: (
                        <a
                          href={`${baseUrl}/diapason/encouragements/${rid}/image?svg=1`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          image
                        </a>
                        )
                      </span>
                      <p>{encouragement.message}</p>
                      <div className="stats">
                        Vues:&nbsp;{encouragement.views} - Remerciements:&nbsp;
                        {encouragement.thanks} - Score:&nbsp;
                        {Math.round(encouragement.ratio * 10000) / 100}
                        &nbsp;-&nbsp;
                        <a
                          href="#"
                          onClick={async (e) => {
                            e.preventDefault();
                            return window.confirm("On rejette ?")
                              ? (await this.api.rejectEncouragement(
                                  encouragement._id
                                )) && this.refreshEncouragements()
                              : () => {};
                          }}
                        >
                          Rejeter
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
              <button
                style={{ display: "none" }}
                onClick={this.resetImageCache}
              >
                reset image cache
              </button>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default EncouragementScreen;
