import React, { Component } from "react";

import API from "../utils/API";

const AUTH_STATE_IDLE = "AUTH_STATE_IDLE";
const AUTH_STATE_LOAD = "AUTH_STATE_LOAD";
const AUTH_STATE_FAIL = "AUTH_STATE_FAIL";

class Login extends Component {
  state = {
    authState: AUTH_STATE_IDLE,
    authError: null,
    login: "",
    password: "",
    server:
      window.sessionStorage.getItem("api") ||
      "https://api.immersivetherapy.care",
  };

  async componentDidMount() {
    this.api = new API();
  }

  async logIn(login, password) {
    try {
      await this.api.login(login, password);
      this.setState({ email: login });
      console.info("Authentication successful for", login);
      window.location.href = "dashboard";
    } catch (e) {
      console.info("Authentication failed for", login, " - Reason:", e);
      this.setState({ authState: AUTH_STATE_FAIL, authError: e.message });
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onSubmit = (e) => {
    const login = this.state.login;
    const password = this.state.password;
    if (this.state.server) {
      window.sessionStorage.setItem("api", this.state.server);
    }
    this.submitForm(e, login, password);
  };

  submitForm = async (e, login, password) => {
    e.preventDefault();
    this.setState({ authState: AUTH_STATE_LOAD, authError: null });
    this.logIn(login.toLowerCase().trim(), password);
  };

  renderState() {
    switch (this.state.authState) {
      case AUTH_STATE_LOAD:
        return <div className="tx-center mg-b-20">Chargement...</div>;
      case AUTH_STATE_FAIL: {
        return (
          <div className="tx-center mg-b-20 tx-danger">
            {this.state.authError}
          </div>
        );
      }
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="d-flex align-items-center justify-content-center bg-sl-primary ht-100v">
        <div className="login-wrapper wd-300 wd-xs-350 pd-25 pd-xs-40 bg-white">
          <div className="signin-logo tx-center tx-24 tx-bold tx-inverse mg-b-20">
            Immersive&nbsp;Therapy
          </div>
          {this.renderState()}

          <form method="post" onSubmit={this.onSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="identifiant"
                name="login"
                value={this.state.login}
                onChange={this.handleChange}
                autoComplete="username"
                required
              />
            </div>

            <div className="form-group">
              <input
                type="password"
                name="password"
                className="form-control"
                placeholder="mot de passe"
                value={this.state.password}
                onChange={this.handleChange}
                autoComplete="current-password"
                required
              />
            </div>

            <div className="form-group">
              <select
                name="server"
                className="form-control"
                value={this.state.server}
                onChange={this.handleChange}
              >
                <option value="https://api.immersivetherapy.care">
                  production
                </option>
                <option value="http://localhost:8080">localhost</option>
              </select>
            </div>

            <button type="submit" className="btn btn-info btn-block">
              Login
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default Login;
