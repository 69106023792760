/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

export default class Layout extends Component {
  state = {
    email: "",
  };

  constructor() {
    super();
    window.sessionStorage.setItem(
      "api",
      window.sessionStorage.getItem("api") ||
        "https://api.immersivetherapy.care"
    );
  }

  logout() {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("refreshToken");
  }

  render() {
    return (
      <div>
        <div className="sl-logo">
          <a href="/dashboard">Diapason</a>
        </div>

        <div className="sl-sideleft">
          <label className="sidebar-label">Navigation</label>
          <div className="sl-sideleft-menu">
            <a href="/dashboard" className="sl-menu-link">
              <div className="sl-menu-item">
                <i className="menu-item-icon icon ion-ios-home tx-22"></i>
                <span className="menu-item-label">Dashboard</span>
              </div>
            </a>

            <a href="#" className="sl-menu-link">
              <div className="sl-menu-item">
                <i className="menu-item-icon icon ion-android-people tx-22"></i>
                <span className="menu-item-label">Utilisateurs</span>
                <i className="menu-item-arrow fa fa-angle-down"></i>
              </div>
            </a>
            <ul className="sl-menu-sub nav flex-column">
              <li className="nav-item">
                <a href="/customers" className="nav-link">
                  Clients
                </a>
              </li>
              <li className="nav-item">
                <a href="/users" className="nav-link">
                  Tous
                </a>
              </li>
              <li className="nav-item">
                <a href="/users/version" className="nav-link">
                  Par version
                </a>
              </li>
            </ul>
            <a href="#" className="sl-menu-link">
              <div className="sl-menu-item">
                <i className="menu-item-icon icon ion-social-usd tx-22"></i>
                <span className="menu-item-label">Ventes</span>
                <i className="menu-item-arrow fa fa-angle-down"></i>
              </div>
            </a>
            <ul className="sl-menu-sub nav flex-column">
              <li className="nav-item">
                <a href="/sales/weeks" className="nav-link">
                  Par semaines
                </a>
              </li>
              <li className="nav-item">
                <a href="/sales/months" className="nav-link">
                  Par mois
                </a>
              </li>
            </ul>
            <a href="#" className="sl-menu-link">
              <div className="sl-menu-item">
                <i className="menu-item-icon icon ion-ios-barcode-outline tx-22"></i>
                <span className="menu-item-label">Codes</span>
                <i className="menu-item-arrow fa fa-angle-down"></i>
              </div>
            </a>
            <ul className="sl-menu-sub nav flex-column">
              <li className="nav-item">
                <a href="/stock-activation-codes" className="nav-link">
                  Stock
                </a>
              </li>
              <li className="nav-item">
                <a href="/bind-activation-codes" className="nav-link">
                  Assigner
                </a>
              </li>
            </ul>
            <a href="#" className="sl-menu-link">
              <div className="sl-menu-item">
                <i className="menu-item-icon icon ion-stats-bars tx-22"></i>
                <span className="menu-item-label">Stats</span>
                <i className="menu-item-arrow fa fa-angle-down"></i>
              </div>
            </a>
            <ul className="sl-menu-sub nav flex-column">
              <li className="nav-item">
                <a href="/stats" className="nav-link">
                  Générales
                </a>
              </li>
              <li className="nav-item">
                <a href="/stats/events" className="nav-link">
                  Type d'évènements
                </a>
              </li>
              <li className="nav-item">
                <a href="/stats/buyers" className="nav-link">
                  Parcours Acheteurs
                </a>
              </li>
              <li className="nav-item">
                <a href="/surveys" className="nav-link">
                  Enquêtes
                </a>
              </li>
              <li className="nav-item">
                <a href="/users/wfus2" className="nav-link">
                  Radars à 2 WFU
                </a>
              </li>
              <li className="nav-item">
                <a href="/users/wfus3" className="nav-link">
                  Radars à 3 WFU
                </a>
              </li>
              <li className="nav-item">
                <a href="/stats/surveys" className="nav-link">
                  Enquête post-IR
                </a>
              </li>
              <li className="nav-item">
                <a href="/stats/ta" className="nav-link">
                  Traiter Acouphènes
                </a>
              </li>
            </ul>

            <a href="#" className="sl-menu-link">
              <div className="sl-menu-item">
                <i className="menu-item-icon icon ion-ios-medkit tx-22"></i>
                <span className="menu-item-label">Pros</span>
                <i className="menu-item-arrow fa fa-angle-down"></i>
              </div>
            </a>
            <ul className="sl-menu-sub nav flex-column">
              <li className="nav-item">
                <a href="/pros" className="nav-link">
                  Listing
                </a>
              </li>
              <li className="nav-item">
                <a href="/create-pro-user" className="nav-link">
                  Ajouter
                </a>
              </li>
            </ul>

            <a href="#" className="sl-menu-link">
              <div className="sl-menu-item">
                <i className="menu-item-icon icon ion-ios-paper-outline tx-22"></i>
                <span className="menu-item-label">Contenu</span>
                <i className="menu-item-arrow fa fa-angle-down"></i>
              </div>
            </a>
            <ul className="sl-menu-sub nav flex-column">
              <li className="nav-item">
                <a href="/lost-recipes/fr" className="nav-link">
                  Recettes perdues 🇫🇷
                </a>
              </li>
              <li className="nav-item">
                <a href="/lost-recipes/us" className="nav-link">
                  Recettes perdues 🇺🇸
                </a>
              </li>
              <li className="nav-item">
                <a href="/faqs" className="nav-link">
                  FAQs
                </a>
              </li>
              <li className="nav-item">
                <a href="/articles" className="nav-link">
                  Articles
                </a>
              </li>
              <li className="nav-item">
                <a href="/app-updates" className="nav-link">
                  Mises à jour
                </a>
              </li>
              <li className="nav-item">
                <a href="/encouragements" className="nav-link">
                  Encouragements
                </a>
              </li>
            </ul>
          </div>
          <br />
        </div>

        <div className="sl-header">
          <div className="sl-header-left">
            <div className="navicon-left hidden-md-down">
              <a id="btnLeftMenu" href="/dashboard">
                <i className="icon ion-navicon-round"></i>
              </a>
            </div>
            <div className="navicon-left hidden-lg-up">
              <a id="btnLeftMenuMobile" href="/dashboard">
                <i className="icon ion-navicon-round"></i>
              </a>
            </div>
            <form
              onSubmit={(ev) => {
                ev.preventDefault();
                let email = this.state.email;
                if (email.indexOf("+") === 0) {
                  email = "loic.dir" + email;
                }
                if (email.indexOf("@") === -1) {
                  email += "@gmail.com";
                }
                window.location.href = `/user?id=${email}`;
              }}
              className="user-search-box"
            >
              <input
                placeholder="adresse email utilisateur"
                value={this.state.email}
                onChange={(ev) => this.setState({ email: ev.target.value })}
              />
            </form>
          </div>

          <div className="sl-header-right">
            <nav className="nav">
              <div className="dropdown">
                <a
                  onClick={this.logout}
                  href="/"
                  className="nav-link nav-link-profile"
                >
                  <span className="logged-name">
                    Se déconnecter&nbsp;<i className="fa fa-sign-out"></i>
                  </span>
                </a>
              </div>
            </nav>
          </div>
        </div>

        <div className="sl-mainpanel">{this.props.children}</div>
      </div>
    );
  }
}
