/* eslint-disable array-callback-return */
import React, { Component } from "react";

import API from "../utils/API";
import Layout from "../components/Layout";
import WFURadar3 from "../components/WFURadar3";

class WFUResearchScreen extends Component {
  state = {
    wfuCount: 3,
    users: [],
  };

  constructor() {
    super();
    this.wfuCount = 3;
  }

  async componentDidMount() {
    this.api = new API();
    try {
      await this.refreshData();
    } catch (e) {
      console.error("User exception", e);
      this.setState({
        reducers: {
          user: {
            id: "0",
            email: "Utilisateur inconnu",
            status: "UNKNOWN",
            subscriptionDate: null,
          },
        },
      });
    }
  }

  refreshData = async () => {
    this.setState({ users: await this.api.getWFUUsers(this.state.wfuCount) });
  };

  onWFUCountSelect = (event) => {
    this.wfuCount = event.target.value;
    this.setState({ wfuCount: event.target.value }, this.refreshData);
  };

  renderRadars = () => {
    return this.state.users.map((data, index) => {
      return (
        <div className="col-md-4">
          <WFURadar3
            key={index}
            index={index + 1}
            user={data.user}
            wfus={data.wfus}
          />
        </div>
      );
    });
  };

  render() {
    const wfuCount = this.wfuCount;
    const wfuCountArray = [];
    for (let i = 0; i < wfuCount; ++i) {
      wfuCountArray.push(0);
    }
    return (
      <Layout>
        <nav className="breadcrumb sl-breadcrumb">
          <a className="breadcrumb-item" href="/dashboard">
            Dashboard {wfuCount}
          </a>
          <span className="breadcrumb-item active">
            Recherche sur le WFU entre trois.
          </span>
        </nav>
        <div className="sl-pagebody">
          <div className="row row-sm">
            <div className="col-md-12">
              <strong>Étape 1</strong> - Choisir un ensemble d'utilisateurs au
              hasard parmi ceux avec au moins&nbsp;
              <select onChange={this.onWFUCountSelect} value={wfuCount}>
                {new Array(98).fill(0).map((_, index) => (
                  <option key={index} value={index + 2}>
                    {index + 2}
                  </option>
                ))}
              </select>
              &nbsp;WFUs
            </div>
          </div>
          <div className="row row-sm">{this.renderRadars()}</div>
        </div>
      </Layout>
    );
  }
}

export default WFUResearchScreen;
