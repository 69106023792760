import React, { Component } from "react";

import API from "../utils/API";
import Layout from "../components/Layout";
import Select from "react-select";

const customStyles = {
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
};

const ingredientTypes = [
  { value: "count", label: "Indivisible (un sachet de levure)" },
  //  { value: "tsp", label: "Mesure en càc/càs seulement" },
  { value: "usg", label: "Mesure US + FR (gramme)" },
  { value: "uscl", label: "Mesure US + FR (cl)" },
];

class LostRecipe extends Component {
  state = {
    editMode: false,
    recipes: [],
    ingredients: [],
    formRecipe: {
      lang: "us",
      serving: { quantity: 6, unit: "people" },
      ingredients: [{ list: [] }],
    },
    servingUnitChoices: [
      { value: null, label: "units" },
      { value: "people", label: "people" },
    ],
    usUnitChoices: [
      { value: null, label: "cups" },
      { value: "oz", label: "oz" },
      { value: "tsp", label: "tsp (càc)" },
      { value: "tbsp", label: "tbsp (càs)" },
    ],
    ingredientChoices: [],
    ingredientSelectedChoices: [],
    formIngredient: {
      name: "",
      aliasFor: null,
      type: ingredientTypes[2],
      text: "",
    },
    aliasFor: null,
  };

  constructor() {
    super();
    this.api = new API();
  }

  async componentDidMount() {
    this.refreshRecipesAndIngredients(false);
  }

  refreshRecipesAndIngredients = async (ingredientOnly) => {
    const ingredients = await this.api.getLostRecipeIngredients(
      this.state.formRecipe.lang
    );
    const ingredientChoices = [];
    ingredients.map((ing) => {
      const isIsoQuantity = (ing.textPlural || ing.text).includes("{{iso}}");
      ingredientChoices.push({
        value: ing.name,
        isAlias: ing.aliasFor ? true : false,
        reference: ing.aliasFor ? ing.aliasFor : ing.name,
        isUsQuantity: (ing.textPlural || ing.text).includes("{{us}}"),
        isIsoQuantity,
        label: (ing.textPlural || ing.text)
          .replace("{{count}} ", "")
          .replace("{{us}} ", "")
          .replace("{{iso}} ", "+"),
        isoUnit:
          isIsoQuantity &&
          (ing.textPlural || ing.text).replace(/.*\({{iso}} ([a-z]+)\)/, "$1"),
      });
    });

    ingredientChoices.sort((a, b) =>
      a.label < b.label ? -1 : a.label > b.label ? 1 : 0
    );
    if (ingredientOnly) {
      this.setState({
        ingredients,
        ingredientChoices,
      });
    } else {
      this.setState(
        {
          recipes: await this.api.getLostRecipes(this.state.formRecipe.lang),
          ingredients,
          ingredientChoices,
        },
        () => {
          console.info("recipes", this.state.recipes);
        }
      );
    }
  };

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      formRecipe: {
        ...this.state.formRecipe,
        [name]: value,
      },
    });
  };

  handleFormIngredientChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      formIngredient: {
        ...this.state.formIngredient,
        [name]: value,
      },
    });
  };

  handleQuantityChange = (event, select) => {
    const target = event.target;
    let quantity;
    let property;
    let ingIndex;
    if (target) {
      quantity = target.value;
      [property, ingIndex] = target.name.split("/");
    } else {
      quantity = event.value;
      [property, ingIndex] = select.name.split("/");
    }

    const ingredients = [...this.state.formRecipe.ingredients];
    ingredients[0].list[ingIndex] = {
      ...this.state.formRecipe.ingredients[0].list[ingIndex],
      [property]: quantity,
    };

    if (property === "usUnit" && ["tbsp", "tsp"].includes(quantity)) {
      ingredients[0].list[ingIndex] = {
        ...this.state.formRecipe.ingredients[0].list[ingIndex],
        quantity: 0,
      };
    }

    this.setState({
      formRecipe: {
        ...this.state.formRecipe,
        ingredients,
      },
    });
  };

  submitEditForm = async (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    try {
      // api
    } catch (e) {
      console.warn("Erreur submitEditForm", e);
      alert(`Problème avec le formulaire… ${e}`);
    }
  };

  render() {
    return (
      <Layout>
        <nav className="breadcrumb sl-breadcrumb">
          <a className="breadcrumb-item" href="/dashboard">
            Dashboard
          </a>
          <a className="breadcrumb-item">Recettes perdues 🇺🇸</a>
        </nav>
        <div className="sl-pagebody lrecipe">
          <div className="row row-sm mg-b-20">
            <div className="col-md-3">
              <div className="card pd-20 pd-sm-20">
                <h6 className="card-body-title">
                  Recipes&nbsp;-&nbsp;
                  <button
                    onClick={() => {
                      this.setState({
                        formRecipe: {
                          lang: "us",
                          title: "",
                          code: "",
                          serving: { quantity: 6, unit: "people" },
                          source: "",
                          instructions: "",
                          ingredients: [{ list: [] }],
                        },
                        ingredientSelectedChoices: [],
                        editMode: false,
                      });
                    }}
                  >
                    New recipe
                  </button>
                </h6>
                <ul className="recipe-list">
                  {this.state.recipes
                    .sort((a, b) => {
                      return a.title < b.title ? -1 : 1;
                    })
                    .map((recipe) => {
                      return (
                        <li
                          key={`li${recipe.code}`}
                          className="recipe-item"
                          onClick={() => {
                            const ingredientSelectedChoices =
                              recipe.ingredients[0].list
                                .map((ing) => {
                                  return this.state.ingredientChoices.find(
                                    (element) => element.value === ing.name
                                  );
                                })
                                .filter((notNull) => !!notNull);
                            this.setState({
                              formRecipe: recipe,
                              editMode: true,
                              ingredientSelectedChoices,
                            });
                          }}
                        >
                          {recipe.status === "published" ? "✅ " : null}
                          {recipe.title}
                        </li>
                      );
                    })}
                </ul>
              </div>

              <div className="card pd-20 pd-sm-20 mg-t-10">
                <h6 className="card-body-title">Create an ingredient</h6>
                <div className="form-group">
                  <input
                    className="form-control"
                    name="name"
                    type="text"
                    value={this.state.formIngredient.name}
                    onChange={this.handleFormIngredientChange}
                    placeholder="Code en anglais: sugarTsp, frozenRaspberry, …"
                    title="Code technique en anglais sans espace (exemple: sugarTsp)"
                  />
                </div>
                <div className="form-group">
                  <Select
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    styles={customStyles}
                    className="select-flex-top"
                    options={this.state.ingredientChoices.filter(
                      (item) => !item.isAlias
                    )}
                    value={this.state.aliasFor}
                    onChange={(value) => {
                      this.setState({
                        aliasFor: value,
                        formIngredient: {
                          ...this.state.formIngredient,
                          aliasFor: value.value,
                        },
                      });
                    }}
                    placeholder="Référence (pour l'image en jeu)"
                  />
                </div>
                <div className="form-group">
                  <Select
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    styles={customStyles}
                    className="select-flex-top"
                    options={ingredientTypes}
                    value={this.state.formIngredient.type}
                    onChange={(value) => {
                      this.setState({
                        formIngredient: {
                          ...this.state.formIngredient,
                          type: value,
                        },
                      });
                    }}
                    placeholder="Type d'ingrédient"
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    name="text"
                    type="text"
                    value={this.state.formIngredient.text}
                    onChange={this.handleFormIngredientChange}
                    placeholder="Libellé ingrédient (water, package of vanilla sugar)"
                    title="Unité d'ingrédient (sugar, watermelon)"
                  />
                </div>

                <button
                  className="btn btn-success-dark mg-r-5"
                  onClick={async () => {
                    try {
                      await this.api.createIngredient(
                        this.state.formIngredient,
                        "us"
                      );
                      this.refreshRecipesAndIngredients(true);
                      this.setState({
                        formIngredient: {
                          name: "",
                          aliasFor: null,
                          text: "",
                        },
                      });
                      alert("Ingrédient sauvegardé");
                      this.refreshRecipesAndIngredients(false);
                    } catch (e) {
                      prompt("Problème, ingrédient non sauvegardé", e);
                    }
                  }}
                >
                  Add this ingredient
                </button>
              </div>
            </div>
            <div className="col-md-9">
              <div className="card pd-20 pd-sm-20">
                <h6 className="card-body-title">
                  {this.state.editMode
                    ? `Edit ${this.state.formRecipe.title}`
                    : `New recipe`}
                </h6>

                <div className="form-group recipe-form">
                  <div className="input-group">
                    <span className="input-group-addon">Libellé</span>
                    <input
                      className="form-control"
                      name="title"
                      type="text"
                      value={this.state.formRecipe.title}
                      onChange={this.handleChange}
                      placeholder="Nom de la recette"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-group">
                    <span className="input-group-addon">Pour combien</span>
                    <input
                      className="form-control"
                      name="servingQuantity"
                      type="text"
                      value={this.state.formRecipe.serving.quantity}
                      placeholder="Quantité"
                      onChange={(event) => {
                        const quantity = +event.target.value;
                        this.setState({
                          formRecipe: {
                            ...this.state.formRecipe,
                            serving: {
                              ...this.state.formRecipe.serving,
                              quantity,
                            },
                          },
                        });
                      }}
                    />
                    <Select
                      className="select-flex-top"
                      options={this.state.servingUnitChoices}
                      value={
                        this.state.formRecipe.serving.unit === "people"
                          ? this.state.servingUnitChoices.find(
                              (element) => element.value === "people"
                            )
                          : this.state.servingUnitChoices.find(
                              (element) => element.value === null
                            )
                      }
                      onChange={(value) => {
                        this.setState({
                          formRecipe: {
                            ...this.state.formRecipe,
                            serving: {
                              ...this.state.formRecipe.serving,
                              unit: value.value,
                            },
                          },
                        });
                      }}
                      placeholder="personnes"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-group">
                    <span className="input-group-addon">
                      Nom technique unique
                    </span>
                    <input
                      className="form-control"
                      name="code"
                      type="text"
                      value={this.state.formRecipe.code}
                      onChange={this.handleChange}
                      placeholder="Nom de la recette en anglais, sans accent (exemple: carrotCake)."
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-group">
                    <span className="input-group-addon">
                      Choix des ingrédients
                    </span>
                    <Select
                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                      styles={customStyles}
                      className="select-flex"
                      isMulti
                      options={this.state.ingredientChoices}
                      value={this.state.ingredientSelectedChoices}
                      onChange={(values) => {
                        const ingredients = [{ list: [] }];
                        values.map((value) => {
                          const quantity = (
                            this.state.formRecipe.ingredients[0].list.find(
                              (element) => element.name === value.value
                            ) || { quantity: 0 }
                          ).quantity;
                          ingredients[0].list.push({
                            name: value.value,
                            quantity,
                          });
                        });
                        this.setState({
                          ingredientSelectedChoices: values,
                          formRecipe: {
                            ...this.state.formRecipe,
                            ingredients,
                          },
                        });
                      }}
                      placeholder="Ingrédients"
                    />
                  </div>
                </div>

                <pre>
                  {JSON.stringify(
                    this.state.formRecipe.ingredients[0].list,
                    true
                  )}
                </pre>

                {this.state.formRecipe.ingredients[0].list.map((ing, index) => {
                  const ingredient = this.state.ingredientChoices.find(
                    (element) =>
                      element.value ===
                      this.state.formRecipe.ingredients[0].list[index].name
                  );
                  if (!ingredient) {
                    alert(
                      "Ingredient foireux : " +
                        this.state.formRecipe.ingredients[0].list[index].name
                    );
                  }

                  if (!ingredient.isIsoQuantity && !ingredient.isUsQuantity) {
                    return (
                      <div
                        className="form-group"
                        key={`ing-${this.state.formRecipe.code}-${index}`}
                      >
                        <div className="input-group">
                          <span className="input-group-addon">Ingrédient</span>
                          <input
                            className="form-control"
                            name={`quantity/${index}`}
                            type="number"
                            value={
                              this.state.formRecipe.ingredients[0].list[index]
                                .quantity
                            }
                            onChange={this.handleQuantityChange}
                            placeholder="Quantité en unité"
                          />
                          <span className="input-group-addon">
                            {ingredient && ingredient.label.replace("10 ", "")}
                          </span>
                        </div>
                      </div>
                    );
                  } else if (ingredient.isUsQuantity) {
                    const usUnit =
                      this.state.formRecipe.ingredients[0].list[index].usUnit ||
                      "cups";
                    const usUnitOption = this.state.usUnitChoices.find(
                      (element) => element.value === usUnit
                    );
                    if (ingredient.isIsoQuantity) {
                      return (
                        <div
                          className="form-group"
                          key={`ing-${this.state.formRecipe.code}-${index}`}
                        >
                          <div className="input-group">
                            <span className="input-group-addon">
                              Ingrédient
                            </span>
                            <input
                              className="form-control"
                              name={`usQuantity/${index}`}
                              type="text"
                              value={
                                this.state.formRecipe.ingredients[0].list[index]
                                  .usQuantity
                              }
                              onChange={this.handleQuantityChange}
                              placeholder="Quantité au format US (1 1/2)"
                            />
                            <Select
                              menuPortalTarget={document.body}
                              menuPosition="fixed"
                              styles={customStyles}
                              className="select-flex-top"
                              name={`usUnit/${index}`}
                              options={this.state.usUnitChoices}
                              value={usUnitOption}
                              onChange={this.handleQuantityChange}
                              placeholder="cups"
                            />
                            <span className="input-group-addon">
                              {ingredient &&
                                ingredient.label
                                  .replace("10 ", "")
                                  .replace(/\(\+[a-z]+\)/, "")
                                  .trim()}
                            </span>
                            <input
                              className="form-control"
                              name={`quantity/${index}`}
                              type="text"
                              value={
                                this.state.formRecipe.ingredients[0].list[index]
                                  .quantity
                              }
                              onChange={this.handleQuantityChange}
                              placeholder="Quantité au format ISO"
                            />
                            <span className="input-group-addon">
                              {ingredient && ingredient.isoUnit}
                            </span>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className="form-group"
                          key={`ing-${this.state.formRecipe.code}-${index}`}
                        >
                          <div className="input-group">
                            <span className="input-group-addon">
                              Ingrédient
                            </span>
                            <input
                              className="form-control"
                              name={`usQuantity/${index}`}
                              type="text"
                              value={
                                this.state.formRecipe.ingredients[0].list[index]
                                  .usQuantity
                              }
                              onChange={this.handleQuantityChange}
                              placeholder="Quantité au format US (1 1/2)"
                            />
                            <Select
                              menuPortalTarget={document.body}
                              menuPosition="fixed"
                              styles={customStyles}
                              className="select-flex-top"
                              name={`usUnit/${index}`}
                              options={this.state.usUnitChoices}
                              value={usUnitOption}
                              onChange={this.handleQuantityChange}
                              placeholder="cups"
                            />
                            <span className="input-group-addon">
                              {ingredient &&
                                ingredient.label.replace("10 ", "")}
                            </span>
                          </div>
                        </div>
                      );
                    }
                  }
                })}

                <div className="form-group">
                  <div className="input-group">
                    <span className="input-group-addon">Instructions</span>
                    <textarea
                      className="form-control"
                      name="instructions"
                      type="text"
                      rows="10"
                      value={this.state.formRecipe.instructions}
                      onChange={this.handleChange}
                      placeholder="- Instructions&#10;- sous forme de liste"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-group">
                    <span className="input-group-addon">Source</span>
                    <input
                      className="form-control"
                      name="source"
                      type="text"
                      value={this.state.formRecipe.source}
                      onChange={this.handleChange}
                      placeholder="URL(s) de la recette qui a servi d'inspiration"
                    />
                  </div>
                </div>

                <div className="form-layout-footer">
                  {this.state.editMode ? (
                    <div className="flex-1 d-xs-flex justify-content-between">
                      <button
                        className="btn btn-info mg-r-5"
                        onClick={async () => {
                          try {
                            await this.api.editLostRecipe(
                              this.state.formRecipe
                            );
                            alert("Modifications sauvegardées");
                          } catch (e) {
                            prompt("Problème, modification non sauvegardée", e);
                          }
                        }}
                      >
                        Save
                      </button>

                      <button
                        className="btn btn-light mg-l-50"
                        onClick={() => {
                          const recipe = this.state.formRecipe;
                          const wordings = {
                            [recipe.code]: {
                              title: recipe.title.trim(),
                              instructions: recipe.instructions.trim(),
                              dummy: 1 /** sert à avoir une virgule après les instructions */,
                            },
                          };
                          prompt(
                            "wording.js (us)",
                            (
                              JSON.stringify(wordings, null, 2)
                                .replace(/^\{/, "")
                                .replace(/\}$/, "") + ","
                            )
                              .replace(/ ([!?:]{1})/g, "\\u00A0$1")
                              .replace(`"dummy": 1\n`, "")
                              .replace(/: "/g, ": `")
                              .replace(/",/g, "`,")
                              .trim()
                          );
                          const ingredients = [];
                          const aliases = {};
                          this.state.ingredientChoices.map((ing) => {
                            aliases[ing.value] = ing.reference;
                          });
                          recipe.ingredients[0].list.map((ing) => {
                            const alias =
                              aliases[ing.name] === ing.name
                                ? null
                                : aliases[ing.name];
                            const ingredient = {};
                            if (alias) {
                              ingredient["name"] = alias;
                              ingredient["alias"] = ing.name;
                              ingredient["quantity"] = ing.quantity;
                            } else {
                              ingredient["name"] = ing.name;
                              ingredient["quantity"] = ing.quantity;
                            }
                            if (ing.usQuantity) {
                              ingredient["usQuantity"] = ing.usQuantity;
                              if (!ing.usUnit) {
                                ingredient["usUnit"] = "cups";
                              } else {
                                ingredient["usUnit"] = ing.usUnit;
                              }
                            }
                            ingredients.push(ingredient);
                          });
                          const recipeContent = {
                            [recipe.code]: {
                              howMany: recipe.serving,
                              ingredients,
                            },
                          };
                          prompt(
                            "recipes.js",
                            (
                              JSON.stringify(recipeContent, null, 2)
                                .replace(/^\{/, "")
                                .replace(/\}$/, "") + ","
                            )
                              .replace(/\n/g, "")
                              .trim()
                          );
                        }}
                      >
                        Inject into Diapason
                      </button>
                    </div>
                  ) : (
                    <button
                      className="btn btn-success mg-r-5"
                      onClick={async () => {
                        try {
                          await this.api.createLostRecipes(
                            this.state.formRecipe
                          );
                          alert("Recette sauvegardée");
                          this.refreshRecipesAndIngredients(false);
                        } catch (e) {
                          prompt("Problème, recette non sauvegardée", e);
                        }
                      }}
                    >
                      Add this recipe
                    </button>
                  )}
                </div>

                {/* Fin du form-group ci-dessous */}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default LostRecipe;
