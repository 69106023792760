/* eslint-disable array-callback-return */
import "moment/locale/fr";

import React, { Component } from "react";

import API from "../utils/API";
import Layout from "../components/Layout";
import ReactTable from "react-table";
import moment from "moment";

moment.locale("fr");

const now = moment();
const userTableCols = [
  {
    id: "creationDate",
    Header: "Formulaire TA",
    accessor: (u) => moment(u.creationDate).format("YYYY-MM-DD"),
    width: 105,
    sortable: false,
    filterable: false,
  },
  {
    id: "subscriptionDate",
    Header: "Inscription",
    accessor: (u) => moment(u.subscriptionDate).format("YYYY-MM-DD"),
    width: 105,
    sortable: false,
    filterable: false,
  },
  {
    id: "buyDate",
    Header: "Achat",
    accessor: (u) => moment(u.licenseCreationDate).format("YYYY-MM-DD"),
    width: 105,
    sortable: true,
    filterable: false,
  },
  {
    id: "action",
    Header: "-",
    filterable: false,
    sortable: false,
    width: 25,
    Cell: (row) => (
      <a href={`/user?id=${row.row._original.id}`}>
        <i className="fa fa-eye"></i>
      </a>
    ),
  },
  {
    Header: "Email",
    accessor: "email",
    filterable: false,
    sortable: false,
    Cell: (row) => {
      return <div>{row.row._original.email}</div>;
    },
  },
  {
    id: "platform",
    Header: "Origine",
    accessor: (u) =>
      u.license
        ? u.license.product && u.license.product.platform
          ? u.license.product.platform.substring(0, 9).toLowerCase()
          : "error"
        : "-",
    maxWidth: 70,
    filterable: false,
    sortable: false,
  },
  {
    id: "promo",
    Header: "Promo",
    accessor: (u) =>
      u.license
        ? u.license.product && u.license.professionalCode
          ? u.license.professionalCode.code
          : "-"
        : "-",
    maxWidth: 90,
    filterable: false,
    sortable: false,
  },
  {
    id: "amount",
    Header: "TTC",
    accessor: (u) => u.amount,
    maxWidth: 65,
    filterable: false,
    sortable: false,
  },
  {
    id: "vat",
    Header: "TVA",
    accessor: (u) => u.vat,
    maxWidth: 50,
    filterable: false,
    sortable: false,
  },
  {
    id: "fee",
    Header: "Fee",
    accessor: (u) => u.fee,
    maxWidth: 50,
    filterable: false,
    sortable: false,
  },
  {
    id: "net",
    Header: "Revenus",
    accessor: (u) => u.net,
    maxWidth: 79,
    filterable: false,
    sortable: false,
  },
  {
    id: "commission",
    Header: "Commission 33%",
    accessor: (u) => u.commission,
    maxWidth: 130,
    filterable: false,
    sortable: false,
  },
];

class Stats extends Component {
  state = {
    emailCount: 0,
    userPostEmailCount: 0,
    userCount: 0,
    emailSystems: {
      android: 0,
      ios: 0,
      unknown: 0,
    },
    total: {},
    licenseCount: 0,
    revenue: "-",
    taMargin: "-",
  };

  constructor() {
    super();
    this.api = new API();
  }

  componentDidMount() {
    this.refreshStats();
  }

  refreshStats = async () => {
    const data = await this.api.getPartnerStatsData();
    this.setState({ ...data });
  };

  renderInfos = () => {
    return (
      <div className="card mg-b-20 pd-t-20 pd-l-20 pd-r-20">
        <h6 className="card-body-title">INFORMATIONS</h6>
        <div className="table-wrapper bg-gray">
          <ul>
            <li>
              Nombre d'adresses emails enregistrées : {this.state.emailCount}
            </li>
            {/*<li>Réparition des d'adresse emails enregistrées par système : Android ({Math.round(this.state.emailSystems.android*100/this.state.emailCount)}%) - iOS ({Math.round(this.state.emailSystems.ios*100/this.state.emailCount)}%) - Autre ({Math.round(this.state.emailSystems.unknown*100/this.state.emailCount)}%)</li>*/}
            <li>
              Nombre de comptes ayant remplis le formulaire TA :{" "}
              {this.state.userCount}
            </li>
            <li>
              Nombre de comptes ayant remplis le formulaire TA ayant déjà un
              compte Diapason :{" "}
              {this.state.userCount - this.state.userPostEmailCount}
            </li>
            <li>Nombre d'abonnements achetés : {this.state.licenseCount}</li>
            <li>
              Taux de conversion de création de comptes :{" "}
              {Math.round(
                (this.state.userCount * 10000) /
                  Math.max(1, this.state.emailCount)
              ) / 100}
              %
            </li>
            <li>
              Taux de conversion d'achats par rapport au nombre d'adresses :{" "}
              {Math.round(
                (this.state.licenseCount * 10000) /
                  Math.max(1, this.state.emailCount)
              ) / 100}
              %
            </li>
            <li>
              Taux de conversion d'achats par rapport au nombre de comptes :{" "}
              {Math.round(
                (this.state.licenseCount * 10000) /
                  Math.max(1, this.state.userCount)
              ) / 100}
              %
            </li>
            <li>
              Volume ventes sur ces abonnements : {this.state.total.amount}
            </li>
            <li>Revenus sur ces abonnements : {this.state.total.net}</li>
            <li>Commission pour TA (33%) : {this.state.total.commission}</li>
          </ul>
        </div>
      </div>
    );
  };

  renderUsers = () => {
    return (
      <div className="card pd-20 pd-sm-20 mg-b-20">
        <h6 className="card-body-title">
          Utilisateurs dans le scope de Traiter-acouphènes{" "}
          <button
            onClick={async () => {
              copyToClipboard(this.state.exportData.replace(/(\|\|)/g, `\r\n`));
            }}
          >
            Copier CSV
          </button>
        </h6>
        <div className="table-wrapper bg-gray">
          <ReactTable
            style={{ width: "100%" }}
            defaultPageSize={100}
            data={this.state.usersWithLicense}
            columns={userTableCols}
            className="no-filters -striped -highlight"
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <Layout>
        <nav className="breadcrumb sl-breadcrumb">
          <a className="breadcrumb-item" href="/dashboard">
            Dashboard
          </a>
          <span className="breadcrumb-item active">
            Statistiques de Traiter Acouphènes
          </span>
        </nav>
        <div className="sl-pagebody">
          <div className="row row-sm">
            <div className="col-xl-12">
              {this.renderInfos()}
              {this.renderUsers()}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/*function copyToClipboard(data) {
  var $body = document.getElementsByTagName("body")[0];
  var $tempInput = document.createElement("INPUT");
  $body.appendChild($tempInput);
  $tempInput.setAttribute("value", data);
  $tempInput.select();
  document.execCommand("copy");
  $body.removeChild($tempInput);
}*/

function copyToClipboard(data) {
  var $temp = window.$("<textarea>");
  var brRegex = /<br\s*[\/]?>/gi;
  window.$("body").append($temp);
  $temp.val(data.replace(brRegex, "\r\n")).select();
  document.execCommand("copy");
  $temp.remove();
}

export default Stats;
