import React, { Component } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";

import ActivationCodeBind from "./screens/ActivationCodeBind";
import ActivationCodeStock from "./screens/ActivationCodeStock";
import AppUpdates from "./screens/AppUpdatesScreen";
import Articles from "./screens/ArticlesScreen";
import BuyersStats from "./screens/StatsBuyersScreen";
import Customers from "./screens/UsersCustomersScreen";
import Dashboard from "./screens/DashboardScreen";
import Encouragements from "./screens/EncouragementsScreen";
import EventTypeStats from "./screens/StatsEventType";
import FAQ from "./screens/FAQScreen";
import GlobalStats from "./screens/StatsGlobalScreen";
import Login from "./screens/LoginScreen";
import LostRecipesFr from "./screens/LostRecipeFRScreen";
import LostRecipesUs from "./screens/LostRecipeUSScreen";
import MonthSales from "./screens/SalesMonthScreen";
import ProUser from "./screens/ProUserScreen";
import Pros from "./screens/ProsScreen";
import SurveyStats from "./screens/StatsSurveyScreen";
import Surveys from "./screens/SurveysScreen";
import TAStats from "./screens/StatsTAScreen";
import User from "./screens/UserScreen";
import Users from "./screens/UsersScreen";
import UsersAppVersion from "./screens/UsersAppVersionScreen";
import WFUResearch2 from "./screens/WFUResearchScreen2";
import WFUResearch3 from "./screens/WFUResearchScreen3";
import WeekSales from "./screens/SalesWeekScreen";

class App extends Component {
  componentDidMount() {
    const api = window.sessionStorage.getItem("api") || "";
    if (api.indexOf("localhost") > -1) {
      window.jQuery(".sl-header").css("background-color", "royalblue");
    }
  }

  render() {
    return (
      <Router>
        <div>
          <Route exact path="/" component={Login} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/user" component={User} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/users/version" component={UsersAppVersion} />
          <Route exact path="/users/wfus2" component={WFUResearch2} />
          <Route exact path="/users/wfus3" component={WFUResearch3} />
          <Route exact path="/customers" component={Customers} />
          <Route exact path="/users/customers" component={Customers} />
          <Route exact path="/sales/weeks" component={WeekSales} />
          <Route exact path="/sales/months" component={MonthSales} />
          <Route exact path="/stats" component={GlobalStats} />
          <Route exact path="/stats/surveys" component={SurveyStats} />
          <Route exact path="/surveys" component={Surveys} />
          <Route exact path="/stats/buyers" component={BuyersStats} />
          <Route exact path="/stats/events" component={EventTypeStats} />
          <Route exact path="/stats/ta" component={TAStats} />
          <Route exact path="/pros" component={Pros} />
          <Route exact path="/faqs" component={FAQ} />
          <Route exact path="/app-updates" component={AppUpdates} />
          <Route exact path="/lost-recipes/fr" component={LostRecipesFr} />
          <Route exact path="/lost-recipes/us" component={LostRecipesUs} />
          <Route exact path="/encouragements" component={Encouragements} />
          <Route exact path="/articles" component={Articles} />
          <Route
            exact
            path="/stock-activation-codes"
            component={ActivationCodeStock}
          />
          <Route
            exact
            path="/bind-activation-codes"
            component={ActivationCodeBind}
          />
          <Route exact path="/create-pro-user" component={ProUser} />
        </div>
      </Router>
    );
  }
}

export default App;
